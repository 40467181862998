<div class="relative">
    <div id="content-header" class="absolute -top-24">
        <h1 class="text-uppercase font-bold">
            View Produce
        </h1>
    </div>
    <div class="absolute -top-28 right-2 cursor-pointer text-white bg-red-500 hover:bg-red-600 rounded-lg h-6 w-6 items-center text-center"
        title="Close" (click)="goBack()">x</div>


    <div class="absolute top-1/2 right-1/2 z-50" *ngIf="isLoading">
        <div class="w-72 h-72 bg-gray-100/20 flex justify-center items-center rounded-md text-gray-200">
            <!-- <i class="fa fa-spinner fa-spin text-4xl"></i> -->
            <img src="assets/loader.gif" alt="">
        </div>
    </div>
    <div class="flex flex-col-reverse md:flex-row gap-4 w-full min-h-64 lg:w-1/2">
        <div class="bg-gray-50 rounded-md p-2 w-full">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
                <div class="flex space-x-2">
                    <div class="font-bold">Farmer:</div>
                    <div class="">{{ produce.farmer.full_name }}</div>
                </div>
                <div class="flex space-x-2">
                    <div class="font-bold">Produce Type:</div>
                    <div class="">{{ produce.crop.name }}</div>
                </div>

                <div class="flex space-x-2">
                    <div class="font-bold">Amount:</div>
                    <div class="">{{ produce.amount | number }} Kg</div>
                </div>
                <div class="flex space-x-2">
                    <div class="font-bold">Unit Price:</div>
                    <div class="">Tsh. {{ produce.value | number }}</div>
                </div>
                <div class="flex space-x-2">
                    <div class="font-bold">Received By:</div>
                    <div class="">{{ produce.user?.full_name }}</div>
                </div>
                <div class="flex space-x-2">
                    <div class="font-bold">Status:</div>
                    <div class="">{{ produce.status }}</div>
                </div>
            </div>

            <!-- payments -->
            <div class="mt-6">
                <div class="font-bold mb-2 text-gray-700">Transaction History</div>
                <div class="border border-gray-200 rounded-lg space-y-4 dark:border-neutral-700">
                    <div class="table-responsive">
                        <table class="table-auto">
                            <thead class="bg-gray-200 font-bold">
                                <tr>
                                    <td>Transaction Date</td>
                                    <td>Order No.</td>
                                    <td>Quantiy (Kg)</td>
                                    <td>Paid Amount (TSh.)</td>
                                </tr>
                            </thead>
                            <tbody class="[&>*:nth-child(even)]:!bg-gray-100">
                                <tr *ngFor="let dt of produce.payments">
                                    <td>{{ dt.date | date:mediumDate }}</td>
                                    <td>{{ dt.order?.orderno }}</td>
                                    <td>{{ dt.quantity }}</td>
                                    <td class="text-right">{{ dt.paid_amount | number }}</td>
                                </tr>
                                <tr *ngIf="produce.payments.length == 0">
                                    <td colspan="3" class="text-gray-500">No transaction history</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <!-- End payments -->
        </div>
    </div>
</div>