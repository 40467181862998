<div class="relative">
    <div id="content-header" class="absolute -top-24">
        <h1 class="text-uppercase font-bold">View Aggregation Center</h1>
    </div>
    <div class="absolute -top-28 right-2 cursor-pointer text-white bg-red-500 hover:bg-red-600 rounded-lg h-6 w-6 items-center text-center"
        title="Close" (click)="goBack()">x</div>
    <div class="w-full min-h-64">
        <div class="px-4 w-full md:w-1/2 bg-gray-50 rounded-md p-2">
            <div class="grid grid-cols-1 md:grid-cols-3 gap-2">
                <div>
                    <label class="text-gray-500" for="name">Name</label>
                    <div class="text-gray-700">{{ data.name }}</div>
                </div>

                <div>
                    <label class="text-gray-500" for="region_id">Region</label>
                    <div class="text-gray-700">{{ data.region?.name }}</div>
                </div>
                <div>
                    <label class="text-gray-500" for="district_id">District</label>
                    <div class="text-gray-700">{{ data.district?.name }}</div>
                </div>
                <div>
                    <label class="text-gray-500" for="ward_id">Ward:</label>
                    <div class="text-gray-700">{{ data.ward?.name }}</div>
                </div>
                <div class="mb-4">
                    <label class="text-gray-500" for="village_id">Village/Street:</label>
                    <div class="text-gray-700">{{ data.village?.name }}</div>
                </div>

            </div>


            <div class="my-2 text-lg">Services</div>
            <div>
                <div *ngFor="let service of data.services; let i = index" class="flex items-center space-x-2 my-0.5">
                    <label class="text-gray-500" for="service-{{i}}">{{i + 1}}:</label>
                    <input id="service-{{i}}"
                        class="bg-gray-50 border-0 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-500 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 disabled"
                        value="{{ service.name }}">
                </div>
            </div>


        </div>

    </div>
    <div class="absolute top-1/2 right-1/2 z-50" *ngIf="loading">
        <div class="w-20 h-20 bg-black/50 flex justify-center items-center rounded-md text-gray-200">
            <i class="fa fa-spinner fa-spin text-4xl"></i>
        </div>
    </div>
</div>