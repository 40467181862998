import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProducesService } from '../produces.service';

@Component({
  selector: 'app-produces-view',
  templateUrl: './produces-view.component.html',
  styleUrls: ['./produces-view.component.css']
})
export class ProducesViewComponent implements OnInit {
id;
produce;

isLoading = true;

  constructor(
    public active: ActivatedRoute,
    public produceServ: ProducesService,
    // public acRegServ: RegistrationService,
    private route: Router,
  ) {
    active.url.subscribe(
      data => {
        // console.log(data[2].path)
        this.id = data[2].path;
      }
    );
  }

  ngOnInit(): void {
    this.produceServ.getProduceSingleData(this.id).subscribe(
      (data: any) => {
        console.log(data);
        this.produce = data;
        this.isLoading = false;
      }
    );
  }

  goBack() {
    this.route.navigate(['manage-ac/produces']);
  }
}
