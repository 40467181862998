<div id="content" class="relative">
    <div class="fixed top-2/4 left-2/4 z-50 {{ loading ? '' : 'hidden' }}">
        <div class=" bg-gray-900/50 text-gray-100 p-3 rounded-md">
            <i class="fa fa-spinner fa-spin fa-2x"></i>
        </div>
    </div>

    <div id="content-header" class="absolute -top-24">
        <h1 class="text-uppercase font-bold">General Orders Report</h1>
    </div>
    <div class="absolute -top-28 right-2 cursor-pointer text-white bg-red-500 hover:bg-red-600 rounded-lg h-6 w-6 items-center text-center"
        title="Close" (click)="goBack()">x</div>

    <div class="relative">
        <div class="absolute -top-2 right-10 w-6 h-20">
            <div class="bg-red-500 text-white rounded-full cursor-pointer p-1" title="Reset Filter"
                (click)="filterReport()">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-4">
                    <path fill-rule="evenodd"
                        d="M9.53 2.47a.75.75 0 0 1 0 1.06L4.81 8.25H15a6.75 6.75 0 0 1 0 13.5h-3a.75.75 0 0 1 0-1.5h3a5.25 5.25 0 1 0 0-10.5H4.81l4.72 4.72a.75.75 0 1 1-1.06 1.06l-6-6a.75.75 0 0 1 0-1.06l6-6a.75.75 0 0 1 1.06 0Z"
                        clip-rule="evenodd" />
                </svg>
            </div>
        </div>
        <div
            class="grid grid-cols-1 md:grid-cols-12 gap-2 bg-gray-100/50 px-2 py-4 rounded-md border-b-8 border-sky-100/30">
            <div class="grid grid-cols-1 md:grid-cols-3 gap-2 md:col-span-10">
                <div>
                    <label for="order_date">Start Date</label>
                    <input type="date" id="order_date" [(ngModel)]="start_date"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                </div>
                <div>
                    <label for="order_date">End Date</label>
                    <input type="date" id="order_date" [(ngModel)]="end_date"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                </div>
                <div>
                    <label for="status">Status</label>
                    <select id="status" [(ngModel)]="status" class="custom-select bg-gray-50">
                        <option value="" disabled>Select...</option>
                        <option *ngFor="let status of statuses" [value]="status">{{ status }}</option>
                    </select>
                </div>
            </div>
            <div class="flex items-center mt-4 md:col-span-2">
                <button (click)="getData()"
                    class="w-full bg-green-700 px-3 py-2 text-white rounded-md flex space-x-2 items-center justify-center">
                    <i class="fa fa-spinner fa-spin" *ngIf="filtering"></i>
                    <span>Filter</span>
                </button>
            </div>
        </div>
    </div>
    <div class="col-md-12">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-8 rounded-md mt-5">
            <div class="bg-gray-50 p-1 rounded-md">
                <highcharts-chart [constructorType]="chartConstructor" [Highcharts]="Highcharts"
                    [options]="chartOptions" class="chart" style="height: 250px;">
                </highcharts-chart>
            </div>
            <div class="bg-gray-50 p-1 rounded-md">
                <highcharts-chart [constructorType]="chartConstructor" [Highcharts]="Highcharts"
                    [options]="orderCropChartOptions" class="chart" style="height: 250px;">
                </highcharts-chart>
            </div>
        </div>
        <div class="col-md-12">
            <div class="view">
                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table p-3">
                    <thead>
                        <tr>
                            <th>Order No.</th>
                            <th>Date</th>
                            <th>Crops</th>
                            <th>Price (TSh.)</th>
                            <th>Buyer</th>
                            <th>Status</th>
                            <th class="hidden">Recorded By</th>
                        </tr>

                    </thead>
                    <tbody>
                        <tr *ngFor="let order of orders">
                            <td>{{order.orderno}}</td>
                            <td>{{order.order_date | date: mediumDate}}</td>
                            <td>{{order.crops_list}}</td>
                            <td class="text-right">{{order.total_price | number}}</td>
                            <td>{{order.trader?.full_name}}</td>
                            <td>{{order.status}}</td>
                            <td class="hidden">{{order.user?.full_name}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    </div>
</div>