import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from 'src/environments/environment';
import { ManagementService } from '../../management/management.service';
import { BuyerService } from '../buyer.service';
import { Router } from '@angular/router';
import { ProfileComponent } from '../../shared/components/profile/profile.component';

@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.css']
})
export class ApplicationComponent implements OnInit, AfterViewInit {

  updating = false;
  isLoading = true;
  data;
  imageUrl = environment.imageUrl;
  title = 'Permit Application'

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();
  dtCheck = false;
  constructor(
    private dialog: MatDialog,
    private manServ: ManagementService,
    public authService: AuthService,
    public buyerService: BuyerService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.updateStatusCheck();
    this.getTraderData();
  }
  
  ngAfterViewInit() {
    this.dtTrigger.next();

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 100,
      processing: true,
      responsive: true,
      // dom: 'Bfrtip',
      // buttons: [
      //     'copy', 'csv', 'excel', 'print'
      // ]
    };
    // console.log("localStorage.getItem('approval_status')");
    // alert(localStorage.getItem('approval_status'))

  }

  getTraderData() {
    this.buyerService.getPermitApplications().subscribe(
      data => {
        this.data = data;
        this.isLoading = false;

        this.rerender();
      }
    );
  }
  rerender(): void {
    if (this.dtCheck){
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
          //  $('#dtb').DataTable();
      });
    }
    else {
      this.dtCheck = true;
      this.dtTrigger.next();
    }

  }
  

  updateStatusCheck() {
    this.updating = true;
    this.authService.fetchTraderApprovalStatus().subscribe(
      (data: any) => {
        // console.log(data)
        localStorage.setItem('approval_status', data);
        this.updating = false;
        // window.location.reload()
      }
    );
  }

  applyPermit() {
    this.router.navigate(['buyers-portal/apply-permit']);
  }

  addCategory() {
        const dialogRef = this.dialog.open(ProfileComponent, {
          width: '550px',
          height: '290px',
          data: {action: 'trader_category'}
        });
    
        dialogRef.afterClosed().subscribe(result => {
         
        });
  }
}
