import { ManagementService } from '../../management.service';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AddWarehouseComponent } from './../add-warehouse/add-warehouse.component';
import { DeleteDataComponent } from './../../delete-data/delete-data.component';
import { AuthService } from 'src/app/auth/auth.service';
import { Router } from '@angular/router';
import { WarehousesService } from '../warehouses.service';

@Component({
  selector: 'app-warehouse',
  templateUrl: './warehouse.component.html',
  styleUrls: ['./warehouse.component.css'],
})
export class WarehouseComponent implements OnInit, AfterViewInit {
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  dtCheck = false;
  warehouses;
  received;
  isLoading = true;
  isChecked = true;

  seasons;
  selectedSeasonId;
  constructor(
    private manServ: ManagementService,
    private wareServ: WarehousesService,
    private dialog: MatDialog,
    public authService: AuthService,
    public router: Router
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.getSeasons();
    this.getWarehouses();
    this.dtTrigger.next();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 100,
      processing: true,
      responsive: true,
      dom: 'Bfrtip',
      // buttons: [
      //     'copy', 'csv', 'excel', 'print'
      // ]
    };
  }

  getWarehouses() {
    var id = localStorage.getItem('asdsUserId');
    this.manServ.getWarehouses(id).subscribe((data) => {
      this.warehouses = data;
      this.isLoading = false;

      this.rerender();
    });
  }

  rerender(): void {
    if (this.dtCheck) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
        //  $('#dtb').DataTable();
      });
    } else {
      this.dtCheck = true;
      this.dtTrigger.next();
    }
  }

  getSeasons() {
    this.manServ.getSeasons().subscribe((data) => {
      this.seasons = data;
    });
  }

  filterBySeason() {
    this.isLoading = true;
    var data = { season_id: this.selectedSeasonId };
    this.wareServ
      .filterWarehousesBySeason(data)
      .subscribe((data) => {
        this.warehouses = data;
        this.isLoading = false;

        this.rerender();
      });
  }

  item1Changed(value) {
    this.isChecked = !this.isChecked;
  }

  item2Changed(value) {
    this.isChecked = !this.isChecked;
  }

  onAddWarehouse() {
    const dialogRef = this.dialog.open(AddWarehouseComponent, {
      width: '710px',
      height: '710px',
      data: { action: 'add' },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('closed');
      // this.dtCheck = false;
      this.getWarehouses();
    });
  }

  editWarehouse(id, regionId, districtId, wardId) {
    console.log(regionId);
    const dialogRef = this.dialog.open(AddWarehouseComponent, {
      width: '710px',
      height: '710px',
      data: {
        id,
        action: 'edit',
        region: regionId,
        district: districtId,
        ward: wardId,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('closed');
      this.getWarehouses();
    });
  }

  deleteWarehouse(id) {
    const dialogRef = this.dialog.open(DeleteDataComponent, {
      width: '500px',
      height: '300px',
      data: { id, type: 'warehouse' },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getWarehouses();
    });
  }

  viewBalance(id) {
    this.router.navigate([
      'managements/warehouses/warehouse-crop-balance/' + id,
    ]);
  }
}
