import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const baseUrl = environment.baseUrl;
const url1 = baseUrl + '/warehouse';
const url2 = baseUrl + '/receive-in-warehouse';
const url3 = baseUrl + '/markets';
const url4 = baseUrl + '/crops';
const url5 = baseUrl + '/regions';
const url6 = baseUrl + '/districts/';
const url7 = baseUrl + '/wards/';
const url8 = baseUrl + '/villages/';
const url9 = baseUrl + '/countries';
const url10 = baseUrl + '/area-warehouses/';

const url11 = baseUrl + '/traders';
const url12 = baseUrl + '/receiving-markets/';
const url13 = baseUrl + '/receive-in-market';
const url14 = baseUrl + '/withdraw-goods';
const url15 = baseUrl + '/users';
const url16 = baseUrl + '/roles';
const url17 = baseUrl + '/category_traders/';
const url18 = baseUrl + '/withdraw_good/';
const url19 = baseUrl + '/area_markets/';
const url20 = baseUrl + '/permissions';
const url21 = baseUrl + '/assign_permissions/';

const url22 = baseUrl + '/app/crops/';

const url23 = baseUrl + '/receiving-warehouses/';

const url24 = baseUrl + '/update_profile/';
const url25 = baseUrl + '/update_passwords/';

const url26 = baseUrl + '/app/warehouses/'
const url27 = baseUrl + '/role_permissions/'
const url28 = baseUrl + '/update_role_permissions/'
const url29 = baseUrl + '/update_user_passwords/'
const url30 = baseUrl + '/uom'
const url31 = baseUrl + '/users_by_location/'
const url32 = baseUrl + '/regions_per_user_location/'
const url33 = baseUrl + '/warehouse_per_user_location/'
const url34 = baseUrl + '/market_per_user_location/'
const url35 = baseUrl + '/roles_per_user/'
const url36 = baseUrl + '/warehouse_balance/'
const url37 = baseUrl + '/all_markets'

@Injectable({
  providedIn: 'root'
})
export class ManagementService {

  headers = new HttpHeaders({
    'Content-Type': 'application/json',
     'X-Requested-Width': 'XMLHttpRequeest',
    'Access-Control-Allow-Origin': '*',
    'Authorization': `Bearer ${localStorage.getItem('api_token')}`
   });

  fileheaders = new HttpHeaders({
    // 'Content-Type': 'multipart/form-data',
    'Accept': 'application/json',
    //  'X-Requested-Width': 'XMLHttpRequeest',
    'Access-Control-Allow-Origin': '*'
   });
  constructor(private http: HttpClient) { }

  getWarehouses(id) {
    return this.http.get(url33 + id, {headers: this.headers});
  }

  getWarehouseData() {
    return this.http.get(url1, {headers: this.headers});
  }

  addWarehouseData(data) {
    return this.http.post(url1, data, {headers: this.headers});
  }

  getWarehouseSingleData(id) {
    return this.http.get(url1 + '/' + id, {headers: this.headers});
  }

  updateWarehouseData(data, id) {
    return this.http.put(url1 + '/' + id, data, {headers: this.headers});
  }

  deleteWarehouseData(id) {
    return this.http.delete(url1 + '/' + id, {headers: this.headers});
  }

  getReceivedWarehouseData() {
    return this.http.get(url2, {headers: this.headers});
  }
  getReceivedSingleData(id) {
    return this.http.get(url2 + '/' + id, {headers: this.headers});
  }

  addReceivedInWarehouse(data) {
    return this.http.post(url2, data, {headers: this.headers});
  }

  updateReceivedInWarehouse(data, id) {
    return this.http.put(url2 + '/' + id, data, {headers: this.headers});
  }

  deleteInWarehouseData(id) {
    return this.http.delete(url2 + '/' + id, {headers: this.headers});
  }


  getWarehouseBalance(id) {
    return this.http.get(url36 + id, {headers: this.headers});
  }


  // markets
  getMarkets(id) {
    return this.http.get(url34 + id, {headers: this.headers});
  }

  getAllMarkets() {
    return this.http.get(url37, {headers: this.headers});
  }

  getMarketData() {
    return this.http.get(url3, {headers: this.headers});
  }
  addMarket(data) {
    return this.http.post(url3, data, {headers: this.headers});
  }

  getMarketSingleData(id) {
    return this.http.get(url3 + '/' + id, {headers: this.headers});
  }

  updateMarket(data, id) {
    return this.http.put(url3 + '/' + id, data,  {headers: this.headers});
  }

  deleteMarket(id) {
    return this.http.delete(url3 + '/' + id, {headers: this.headers});
  }

  availableAreaMarkets(userId) {
    return this.http.get(url12 + userId, {headers: this.headers});
  }

  getReceivedInMarket() {
    return this.http.get(url13, {headers: this.headers});
  }

  receiveInMarket(data) {
    return this.http.post(url13, data, {headers: this.headers});
  }

  getReceivedInMarketSingleData(id) {
    return this.http.get(url13 + '/' + id, {headers: this.headers});
  }
  updateReceivedInMarket(data, id) {
    return this.http.put(url13 + '/' + id, data, {headers: this.headers});
  }

  deleteReceivedInMarket(id) {
    return this.http.delete(url13 + '/' + id, {headers: this.headers});
  }

  // Crops
  getCrops() {
    return this.http.get(url4, {headers: this.headers});
  }
  addCrop(data) {
    return this.http.post(url4, data, {headers: this.fileheaders});
  }
  getSingleCrop(id) {
    return this.http.get(url4 + '/' + id, {headers: this.headers});
  }

  updateCrop(data, id) {
    return this.http.post(url4 + '/' + id, data);
  }

  deleteCrop(id) {
    return this.http.delete(url4 + '/' + id, {headers: this.headers});
  }


  getAllRegions() {
    return this.http.get(url5, {headers: this.headers});
  }
  getRegions(id) {
    return this.http.get(url32 + id, {headers: this.headers});
  }

  getAllDistricts(regionId) {
    return this.http.get(url6 + regionId, {headers: this.headers});
  }
  getDistricts(regionId, id) {
    return this.http.get(url6 + regionId + '/' + id, {headers: this.headers});
  }
  
  getWards(districtId) {
    return this.http.get(url7 + districtId, {headers: this.headers});
  }
  getVillages(wardId) {
    return this.http.get(url8 + wardId, {headers: this.headers});
  }
  getCountries() {
    return this.http.get(url9, {headers: this.headers});
  }

  availableAreaWarehouses(districtId) {
    return this.http.get(url10 + districtId, {headers: this.headers});
  }
  allAvailableAreaMarkets(districtId) {
    return this.http.get(url19 + districtId, {headers: this.headers});
  }

  // Trader
  getTraders() {
    return this.http.get(url11, {headers: this.headers});
  }

  addTraderData(data) {
    return this.http.post(url11, data, {headers: this.fileheaders});
  }

  getTraderData(id) {
    return this.http.get(url11 + '/' + id, {headers: this.headers});
  }

  updateTraderData(data, id) {
    return this.http.put(url11 + '/' + id, data, {headers: this.headers});
  }

  deleteTrader(id) {
    return this.http.delete(url11 + '/' + id, {headers: this.headers});
  }

  getCategoryTraders(category) {
    return this.http.get(url17 + category, {headers: this.headers});
  }

  // withdraw goods
  onWithdraw() {
    return this.http.get(url14, {headers: this.headers});
  }

  addWithdrawedGood(data) {
    return this.http.post(url14, data, {headers: this.headers});
  }

  getWithdrawedSingleData(id) {
    return this.http.get(url14 + '/' + id, {headers: this.headers});
  }


  updateWithdrawedData(data, id) {
    return this.http.put(url14 + '/' + id, data, {headers: this.headers});
  }

  deleteWithdrawedGood(id) {
    return this.http.delete(url14 + '/' + id, {headers: this.headers});
  }

  // manage users
  getUsers(id) {
    return this.http.get(url31 + id, {headers: this.headers});
  }

  registerUser(data) {
    return this.http.post(url15, data, {headers: this.fileheaders});
  }
  getSingleUser(id) {
    return this.http.get(url15 + '/' + id, {headers: this.headers});
  }


  updateUser(data, id) {
    // return this.http.put(url15 + '/' + id, data, {headers: this.fileheaders});
    return this.http.post(url15 + '/' + id, data, {headers: this.fileheaders});
  }

  deleteUser(id) {
    return this.http.delete(url15 + '/' + id, {headers: this.headers});
  }

  getRoles() {
    var id = localStorage.getItem('asdsUserId')
    return this.http.get(url35 + id, {headers: this.headers});
  }

  addRole(data) {
    return this.http.post(url16, data, {headers: this.headers});
  }
  getSingleRole(id) {
    return this.http.get(url16 + '/' + id, {headers: this.headers});
  }


  updateRole(data, id) {
    return this.http.put(url16 + '/' + id, data, {headers: this.headers});
  }

  deleteRole(id) {
    return this.http.delete(url16 + '/' + id, {headers: this.headers});
  }
  getPermissions() {
    return this.http.get(url20, {headers: this.headers});
  }

  assignPermission(data, id) {
    return this.http.post(url21 + id, data, {headers: this.headers});
  }


  getYears() {
    const year = new Date().getFullYear();
    const range = [];
    range.push(year);
    for (let i = 1; i <= 10; i++) {
        range.push(year - i);
    }

    return range;
  }

  getTraderCrops(id) {
    return this.http.get(url22 + id, {headers: this.headers});
  }

  myWarehouses(userId) {
    return this.http.get(url23 + userId, {headers: this.headers});
  }

  updateProfile(data, id) {
    return this.http.post(url24 + id, data, {headers: this.headers});
  }

  updatePasswords(data, id) {
    return this.http.post(url25 + id, data, {headers: this.headers});
  }

  getMyWarehouseData(token) {
    return this.http.get(url26 + token, {headers: this.headers});
  }


  getRolePermissionData(role) {
    return this.http.get(url27 + role, {headers: this.headers});
  }

  updateRolePermissionData(role, perms) {
    return this.http.post(url28 + role, perms, {headers: this.headers});
  }

  updateUserPassword(data, id) {
    return this.http.post(url29 + id, data, {headers: this.headers});
  }

  // Uoms
  getUoms() {
    return this.http.get(url30, {headers: this.headers});
  }
  addUom(data) {
    return this.http.post(url30, data, {headers: this.headers});
  }
  getSingleUom(id) {
    return this.http.get(url30 + '/' + id, {headers: this.headers});
  }

  updateUom(data, id) {
    return this.http.put(url30 + '/' + id, data, {headers: this.headers});
  }

  deleteUom(id) {
    return this.http.delete(url30 + '/' + id, {headers: this.headers});
  }


}
