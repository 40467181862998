<div id="content" class="relative">
    <div id="content-header" class="absolute -top-32">
        <h1 class="text-uppercase font-bold">Produce Summary Reports</h1>
    </div>
    <div class="absolute -top-32 right-2 cursor-pointer text-white bg-red-500 hover:bg-red-600 rounded-lg h-6 w-6 items-center text-center"
        title="Close" (click)="goBack()">x</div>


    <div class="col-md-12">
        <div class="grid grid-cols-1 md:grid-cols-4 gap-8 rounded-md mt-5">
            <div class="bg-gray-50 p-1 rounded-md col-span-3">
                <highcharts-chart [constructorType]="chartConstructor" [Highcharts]="Highcharts"
                    [options]="produceSummaryChartOptions" class="chart" style="height: 350px;">
                </highcharts-chart>
            </div>
            <div class="">
                <div class="bg-gray-50/20 p-1 rounded-md h-full">
                    <div class="bg-green-50 p-2 rounded-md mb-4">
                        <div class="text-green-500 font-bold flex space-x-2 items-center text-lg">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="size-5">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z" />
                            </svg>
                            <span>
                                Most Available Produce
                            </span>
                        </div>
                        <div class="flex space-x-2 items-center justify-between py-2">
                            <div class="font-bold text-xl">{{ summary.available.crop }}</div>
                            <div class="px-4 py-8 bg-white rounded-full">{{ summary.available.amount }} Kg</div>
                        </div>
                    </div>
                    <div class="bg-amber-50 p-2 rounded-md">
                        <div class="text-amber-500 font-bold flex space-x-2 items-center text-lg">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="size-5">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z" />
                            </svg>
                            <span>Most Sold Produce</span>
                        </div>
                        <div class="flex space-x-2 items-center justify-between py-2">
                            <div class="font-bold text-xl">{{ summary.sold.crop }}</div>
                            <div class="px-4 py-8 bg-white rounded-full">{{ summary.sold.amount }} Kg</div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <!-- <div class="col-md-12">
            <div class="view">
                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table p-3">
                    <thead>
                        <tr>
                            <th>Order No.</th>
                            <th>Date</th>
                            <th>Crops</th>
                            <th>Price (TSh.)</th>
                            <th>Buyer</th>
                            <th>Status</th>
                            <th class="hidden">Recorded By</th>
                        </tr>

                    </thead>
                    <tbody>
                        <tr *ngFor="let order of orders">
                            <td>{{order.orderno}}</td>
                            <td>{{order.order_date | date: mediumDate}}</td>
                            <td>{{order.crops_list}}</td>
                            <td class="text-right">{{order.total_price | number}}</td>
                            <td>{{order.trader?.full_name}}</td>
                            <td>{{order.status}}</td>
                            <td class="hidden">{{order.user?.full_name}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div> -->
    </div>
</div>