import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { DeleteDataComponent } from '../../delete-data/delete-data.component';
import { AddCropComponent } from './../add-crop/add-crop.component';
import { ManagementService } from './../../management.service';
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-crops',
  templateUrl: './crops.component.html',
  styleUrls: ['./crops.component.css']
})
export class CropsComponent implements OnInit, AfterViewInit {

  crops;
  isLoading = true
  imageUrl = environment.imageUrl;

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  dtCheck = false;
  constructor(
    public dialog: MatDialog,
    private manServ: ManagementService,
    public authService: AuthService
    ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.getCrops();
    this.dtTrigger.next();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 100,
      processing: true,
      dom: 'Bfrtip',
        // buttons: [
        //     'copy', 'csv', 'excel', 'print'
        // ]
    };
  }
  onAddCrop() {
    const dialogRef = this.dialog.open(AddCropComponent, {
      width: '550px',
      height: '480px',
      data: {action: 'add' }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('closed');
      this.getCrops();
    });
  }


  editCrop(id) {
    const dialogRef = this.dialog.open(AddCropComponent, {
      width: '550px',
      height: '480px',
      // tslint:disable-next-line: object-literal-shorthand
      data: {id: id, action: 'edit' }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('closed');
      this.getCrops();
    });
  }

  deleteCrop(id) {
    const dialogRef = this.dialog.open(DeleteDataComponent, {
      width: '500px',
      height: '300px',
      // tslint:disable-next-line: object-literal-shorthand
      data: {id: id, type: 'crops' }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getCrops();
    });
  }

  getCrops() {
    this.manServ.getCrops().subscribe(
      data => {
        this.crops = data;
        this.isLoading = false
        this.rerender();
      }
    );
  }

  rerender(): void {
    if (this.dtCheck){
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
      });
    }
    else {
      this.dtCheck = true;
      this.dtTrigger.next();
    }

  }
}
