<div class="relative">
    <div id="content-header" class="absolute -top-24">
        <h1 class="text-uppercase font-bold">Manage Aggregation Centers</h1>
    </div>
    <div [ngClass]="!isLoading ? 'show' : 'hide'">
        <div class="d-flex justify-content-start mt-1 bg-white">
            <div class="p-3">
                <button class="button" mat-button (click)="onAddAggregationCenter()"><span class="material-icons notranslate">add_circle_outline</span>Register AC</button>
            </div>
        </div>
        <div class="col-md-12">
            <div class="view">
                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table p-3">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Region</th>
                            <th>District</th>
                            <th>Ward</th>
                            <th>Village</th>
                            <th>Manager</th>
                            <th width="5%">Action</th>
                        </tr>
    
                    </thead>
                    <tbody>
                        <tr *ngFor="let center of centers">
                            <td>{{center.name}}</td>
                            <td>{{center.region?.name}}</td>
                            <td>{{center.district?.name}}</td>
                            <td>{{center.ward?.name}}</td>
                            <td>{{center.village?.name}}</td>
                            <td>{{center.user?.full_name}}</td>
                            <td class="flex space-x-2">
                                <a class="text-info act" (click)="viewAggregationCenter(center.id)"><i class="fa fa-eye"></i></a>
                                <a class="text-success act" (click)="editAggregationCenter(center.id)"><i class="fa fa-edit"></i></a>
                                <!-- <a class="text-success act" (click)="editAggregationCenter(center.id)"><i class="fa fa-edit"></i></a> -->
                                <mat-icon *ngIf="authService.isAdmin" class="text-danger act" (click)="deleteAggregationCenter(center.id)">delete_forever</mat-icon>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
    
        </div>
    </div>
    
    
    <div *ngIf="isLoading" class="spinner-holder">
      <mat-spinner class="spinner"></mat-spinner>
    </div>
</div>
