<div class="relative">
    <div class="absolute -top-28 right-2 cursor-pointer text-white bg-red-500 hover:bg-red-600 rounded-lg h-6 w-6 items-center text-center"
        title="Close" (click)="goBack()">x</div>
    <div *ngIf="page == 'one'">
        <div class="flex justify-between">
            <div class="text-lg">{{ 'business_info'|translate }}</div>
            <div class="text-lg px-2">
                <span>{{ 'step'|translate }} </span> <span class="bg-blue-500 text white rounded-full h-40 px-2 w-40 py-2.5">1/2</span>
            </div>
        </div>
        <div class="sm:px-4 md:px-6 min-h-[80vh] bg-gray-50 py-2 rounded-md">
            <form [formGroup]="addForm" (ngSubmit)="onSubmit()" class="bg-white p-4 my-2 rounded-2xl shadow-sm">
                <!-- <div class="title-holder">
                        <h2 class="title">Add Buyer</h2>
                    </div> -->
                <div class="row px-2">
                    <div class="col-md-4 px-1">
                        <mat-form-field class="w-100 px-1" appearance="outline">
                            <mat-label>{{ 'market_category'|translate }}</mat-label>
                            <mat-select placeholder="{{ 'select'|translate }} {{ 'market'|translate }}" formControlName="market_category" required
                                (selectionChange)="changeTypeValidation($event.value)">
                                <mat-option value="physical_market">Physical Market</mat-option>
                                <mat-option value="commodity_exchange">Commodity Exchange</mat-option>
                            </mat-select>
                            <mat-error *ngIf="addForm.get('market_category').invalid">{{getErrorMessage()}}</mat-error>
                        </mat-form-field>
                    </div>
                    <!-- <div class="col-md-4 px-1">
                        <mat-form-field class="w-100 px-1" appearance="outline">
                            <mat-label>Type</mat-label>
                            <mat-select placeholder="{{ 'select'|translate }} Type" formControlName="type" required
                                (selectionChange)="changeTypeValidation($event.value)">
                                <mat-option value="domestic">Domestic</mat-option>
                                <mat-option value="international">International</mat-option>
                            </mat-select>
                            <mat-error *ngIf="addForm.get('type').invalid">{{getErrorMessage()}}</mat-error>
                        </mat-form-field>
                    </div> -->
                    <div class="col-md-4 px-1">
                        <mat-form-field class="w-100 px-1" appearance="outline">
                            <mat-label>{{ 'category'|translate }}</mat-label>
                            <mat-select placeholder="{{ 'select'|translate }} {{ 'category'|translate }}" formControlName="category" required
                                (selectionChange)="changeCategoryValidation($event.value)">
                                <mat-option value="individual" *ngIf="isDomestic">Individual (sole)</mat-option>
                                <mat-option value="company">Company</mat-option>
                            </mat-select>
                            <mat-error *ngIf="addForm.get('category').invalid">{{getErrorMessage()}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4 px-1">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>{{ 'full_name' }}</mat-label>
                            <input matInput placeholder="{{ 'enter'|translate }} {{ 'name'|translate }}" formControlName="full_name" required readonly
                                class="bg-gray-200">
                            <mat-error *ngIf="addForm.get('full_name').invalid">{{getErrorMessage()}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4 px-1">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>{{ 'phone'|translate }}</mat-label>
                            <input matInput type="tel" placeholder="255736452345" formControlName="phone" required
                                readonly class="bg-gray-200">
                            <mat-error *ngIf="addForm.get('phone').invalid">{{getPhoneErrorMessage()}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4 px-1">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>{{ 'email'|translate }}</mat-label>
                            <input matInput placeholder="{{ 'email'|translate }}" formControlName="email" required readonly
                                class="bg-gray-200">
                            <mat-error *ngIf="addForm.get('email').invalid">{{getEmailErrorMessage()}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4 px-1">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>{{ 'address'|translate }}</mat-label>
                            <input matInput placeholder="Adddress" formControlName="address">
                        </mat-form-field>
                    </div>
                    <div class="col-md-4 px-1" *ngIf="isIndividual">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>NIDA</mat-label>
                            <input matInput type="text" [textMask]="{mask: nidamask}"
                                placeholder="19920203-12384-0000-142" formControlName="nida" required>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4 px-1">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>{{ 'business_name'|translate }}</mat-label>
                            <input matInput type="text" placeholder="{{ 'business_name'|translate }}" formControlName="company_name"
                                required>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4 px-1">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label class="capitalize">{{ 'tin_number' }}</mat-label>
                            <input matInput type="text" [textMask]="{mask: tinmask}" placeholder="{{ 'tin_number' }}"
                                formControlName="tin_number" required>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4 px-1">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label class="capitalize">{{ 'business_license_number'|translate }}</mat-label>
                            <input matInput type="text" placeholder="{{ 'business_license_number'|translate }}"
                                formControlName="license_number" required>
                        </mat-form-field>
                    </div>
                    <!-- <div class="col-md-4 px-1">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label class="capitalize">Tax Identification number</mat-label>
                            <input matInput type="text" placeholder="Tax Identification number"
                                formControlName="tax_number" required>
                        </mat-form-field>
                    </div> -->
                    <div class="col-md-4 px-1">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label class="capitalize">{{ 'cert_of_inc_number'|translate }}</mat-label>
                            <input matInput type="text" placeholder="{{ 'cert_of_inc_number'|translate }}"
                                formControlName="cert_of_inc_number" required>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4 px-1">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label class="capitalize">{{ 'vat_number'|translate }}</mat-label>
                            <input matInput type="text" placeholder="{{ 'vat_number'|translate }}" formControlName="vat_reg_number">
                        </mat-form-field>
                    </div>
                    <div class="col-md-4 px-1" *ngIf="!isDomestic">
                        <mat-form-field class="w-100 px-1   " appearance="outline">
                            <mat-label class="capitalize">{{ 'country'|translate }}</mat-label>
                            <mat-select placeholder="{{ 'select'|translate }} {{ 'country'|translate }}" formControlName="country_id" required>
                                <mat-option *ngFor="let country of countries"
                                    [value]="country.id">{{country.country_name}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="addForm.get('country_id').invalid">{{getErrorMessage()}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4 px-1" *ngIf="!isDomestic">
                        <mat-form-field class="w-100 px-1   " appearance="outline">
                            <mat-label>{{ 'reg_agency'|translate }}</mat-label>
                            <input matInput type="text" placeholder="{{ 'enter'|translate }} {{ 'reg_agency'|translate }}" formControlName="reg_agency"
                                required>
                            <mat-error *ngIf="addForm.get('reg_agency').invalid">{{getErrorMessage()}}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row px-2">
                    <div class="col-md-4 px-1" *ngIf="isDomestic">
                        <mat-form-field class="w-100 px-1" appearance="outline">
                            <mat-label>{{ 'region'|translate }}</mat-label>
                            <mat-select placeholder="{{ 'select'|translate }} {{ 'region'|translate }}" formControlName="region_id" [required]="isRequired"
                                (selectionChange)="getDistricts($event.value)">
                                <mat-option *ngFor="let region of regions"
                                    [value]="region.id">{{region.name}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="addForm.get('region_id').invalid">{{getErrorMessage()}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4 px-1" *ngIf="isDomestic">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>{{ 'district'|translate }}</mat-label>
                            <mat-select placeholder="{{ 'select'|translate }} {{ 'district'|translate }}" formControlName="district_id"
                                [required]="isRequired" (selectionChange)="getWards($event.value)">
                                <mat-option *ngFor="let district of districts"
                                    [value]="district.id">{{district.name}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="addForm.get('district_id').invalid">{{getErrorMessage()}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4 px-2" *ngIf="isDomestic">
                        <mat-form-field class="w-100 px-1" appearance="outline">
                            <mat-label>{{ 'ward'|translate }}</mat-label>
                            <mat-select placeholder="{{ 'select'|translate }} {{ 'ward'|translate }}" formControlName="ward_id"
                                (selectionChange)="getVillages($event.value)">
                                <mat-option *ngFor="let ward of wards" [value]="ward.id">{{ward.name}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="addForm.get('ward_id').invalid">{{getErrorMessage()}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4 px-1" *ngIf="isDomestic">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>{{ 'village_street'|translate }}</mat-label>
                            <mat-select placeholder="{{ 'select'|translate }} {{ 'village_street'|translate }}" formControlName="village_id">
                                <mat-option *ngFor="let village of villages"
                                    [value]="village.id">{{village.name}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="addForm.get('village_id').invalid">{{getErrorMessage()}}</mat-error>
                        </mat-form-field>
                    </div>

                    <!-- <div class="col-md-4 px-1 hidden">
                        <mat-form-field class="w-100" appearance="fill">
                            <mat-label>Date of Registration (13/1/2001)</mat-label>
                            <input matInput [matDatepicker]="picker1" formControlName="reg_date" required>
                            <mat-datepicker-toggle matSuffix [for]="picker1">
                                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                            <mat-error *ngIf="addForm.get('date').invalid">{{getErrorMessage()}}</mat-error>
                        </mat-form-field>
                    </div> -->

                    <!-- <div class="col-md-4 px-1 hidden">
                        <mat-form-field class="w-100" appearance="fill">
                            <mat-label>Date of Operation (13/1/2001)</mat-label>
                            <input matInput [matDatepicker]="picker2" formControlName="operation_date" required>
                            <mat-datepicker-toggle matSuffix [for]="picker2">
                                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                            <mat-error *ngIf="addForm.get('date').invalid">{{getErrorMessage()}}</mat-error>
                        </mat-form-field>
                    </div> -->
                </div>
                <!-- <div class="w-100 mb-1">
                        <label>Image</label>
                        <input class="form-control" formControlName="image" accept="image/*" #fileInput type="file"
                            (change)="onFileChange($event)">
                        <span class="file-name">{{selectedImage?.name}}</span>
                        <img [src]="imageSrc" *ngIf="imageSrc" style="height: 100px; width:100px; margin-top: 10px;">
                    </div> -->

                <div class="row px-2">
                    <div class="col-md-4 px-1" *ngIf="!isDomestic">
                        <mat-form-field class="w-100 px-1" appearance="outline">
                            <mat-label>{{ 'bank_name'|translate }}</mat-label>
                            <input matInput type="text" placeholder="{{ 'enter'|translate }} {{ 'bank_name'|translate }}" formControlName="bank_name">
                            <mat-error *ngIf="addForm.get('bank_name').invalid">{{getErrorMessage()}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4 px-1" *ngIf="!isDomestic">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>{{ 'branch'|translate }}</mat-label>
                            <input matInput type="text" placeholder="{{ 'enter'|translate }} {{ 'branch'|translate }}" formControlName="branch">
                            <mat-error *ngIf="addForm.get('branch').invalid">{{getErrorMessage()}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4 px-2" *ngIf="!isDomestic">
                        <mat-form-field class="w-100 px-1" appearance="outline">
                            <mat-label>{{ 'acct_no'|translate }}</mat-label>
                            <input matInput type="text" placeholder="{{ 'enter'|translate }} {{ 'acct_no'|translate }}" formControlName="bank_acct">
                            <mat-error *ngIf="addForm.get('bank_acct').invalid">{{getErrorMessage()}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4 px-1" *ngIf="!isDomestic">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>{{ 'swift_code'|translate }}</mat-label>
                            <input matInput type="text" placeholder="{{ 'enter'|translate }} " formControlName="swift_code">
                            <mat-error *ngIf="addForm.get('swift_code').invalid">{{getErrorMessage()}}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="mt-3">
                    <div class="py-2">{{ 'attachments'|translate }}</div>
                    <div class="grid grid-cols-3 gap-4 py-2">
                        <div class="">{{ 'business_license'|translate }} <span *ngIf="!isIndividual" class="text-red-500 px-1">*</span>
                        </div>
                        <div class="">
                            <div class="w-100 mb-1">
                                <input class="" formControlName="business_license" accept="application/pdf" #fileInput
                                    type="file" (change)="onBusinessLicenseChange($event)">
                            </div>
                        </div>
                    </div>
                    <div class="grid grid-cols-3 gap-4 py-2">
                        <div class="">{{ 'cert_of_inc'|translate }} <span *ngIf="!isIndividual"
                                class="text-red-500 px-1">*</span></div>
                        <div class="">
                            <div class="w-100 mb-1">
                                <input class="" formControlName="certificate_of_inc" accept="application/pdf" #fileInput
                                    type="file" (change)="onCertificateOfIncChange($event)">
                            </div>
                        </div>
                    </div>
                    <div class="grid grid-cols-3 gap-4 py-2">
                        <div class="">{{ 'tax_clearance'|translate }} <span *ngIf="!isIndividual" class="text-red-500 px-1">*</span></div>
                        <div class="">
                            <div class="w-100 mb-1">
                                <input class="" formControlName="tax_clearance" accept="application/pdf" #fileInput
                                    type="file" (change)="onTaxClearanceChange($event)">
                            </div>
                        </div>
                    </div>
                    <div class="grid grid-cols-3 gap-4 py-2">
                        <div class="">{{ 'tin_certificate'|translate }} <span class="text-red-500 px-1">*</span>
                        </div>
                        <div class="">
                            <div class="w-100 mb-1">
                                <input class="" formControlName="tin_certificate" accept="application/pdf" #fileInput
                                    type="file" (change)="onTINCertificateChange($event)">
                            </div>
                        </div>
                    </div>
                    <div class="grid grid-cols-3 gap-4 py-2" *ngIf="isIndividual">
                        <div class="">{{ 'nida_certificate'|translate }}
                        </div>
                        <div class="">
                            <div class="w-100 mb-1">
                                <input class="" formControlName="nida_certificate" accept="application/pdf" #fileInput
                                    type="file" (change)="onNidaCertificateChange($event)">
                            </div>
                        </div>
                    </div>
                    <div class="grid grid-cols-3 gap-4 py-2" *ngIf="isIndividual">
                        <div class="">{{ 'lga_license'|translate }} <span class="text-red-500 px-1">*</span>
                        </div>
                        <div class="">
                            <div class="w-100 mb-1">
                                <input class="" formControlName="lga_permit" accept="application/pdf" #fileInput
                                    type="file" (change)="onLGAPermitChange($event)">
                            </div>
                        </div>
                    </div>
                    <div class="grid grid-cols-3 gap-4 py-2">
                        <div class="">{{ 'vat_certificate'|translate }}
                        </div>
                        <div class="">
                            <div class="w-100 mb-1">
                                <input class="" formControlName="vat_certificate" accept="application/pdf" #fileInput
                                    type="file" (change)="onVATCertificateChange($event)">
                            </div>
                        </div>
                    </div>

                    <div class="grid grid-cols-3 gap-4 py-2">
                        <div class="">{{ 'memorandum'|translate }} <span *ngIf="!isIndividual"
                                class="text-red-500 px-1">*</span></div>
                        <div class="">
                            <div class="w-100 mb-1">
                                <input class="" formControlName="memorandum" accept="application/pdf" #fileInput
                                    type="file" (change)="onMemorandumChange($event)">
                            </div>
                        </div>
                    </div>

                    <div class="grid grid-cols-3 gap-4 py-2">
                        <div class="">{{ 'approval_certificate'|translate }} (Mandatory for Aggregators)</div>
                        <div class="">
                            <div class="w-100 mb-1">
                                <input class="" formControlName="approval_certificate" accept="application/pdf" #fileInput
                                    type="file" (change)="onApprovalCertificateChange($event)">
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div class="col-md-12 column hidden">
                    <mat-form-field class="w-100" appearance="fill">
                        <mat-label>Date (13/1/2001)</mat-label>
                        <input matInput [matDatepicker]="picker" formControlName="date" required>
                        <mat-datepicker-toggle matSuffix [for]="picker">
                            <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error *ngIf="addForm.get('date').invalid">{{getErrorMessage()}}</mat-error>
                    </mat-form-field>
                </div> -->
                <!--<div class="col-md-12 column">
                        <mat-form-field class="w-100" appearance="fill">
                            <mat-label>Crops</mat-label>
                            <mat-select formControlName="crops" multiple>
                                <mat-option *ngFor="let crop of crops" [value]="crop.id">{{crop.name}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="addForm.get('crops').invalid">{{getErrorMessage()}}</mat-error>
                        </mat-form-field>
                    </div> -->
                <div style="margin-top: 30px; display: flex; flex-direction: row; justify-content: flex-end;">
                    <button (click)="toNext()" class="px-5" mat-button>{{ 'next'|translate }}</button>
                </div>
                <!-- <div style="margin-top: 30px; display: flex; flex-direction: row; justify-content: flex-end;">
                        <a href="/login"
                            class="text-center px-5 py-2 border rounded-lg mr-2 bg-red-500 text-white hover:bg-red-600 hover:no-underline">Cancel</a>
        
                        <button type="submit" class="createbtn" mat-button><i class="fa fa-spinner fa-spin"
                                *ngIf="submitting"></i>Submit</button>
                    </div> -->
            </form>
        </div>
    </div>
    <div *ngIf="page == 'two'">
        <div class="flex justify-between">
            <div class="text-lg">{{ 'other_details'|translate }}</div>

            <div class="text-lg px-2">
                <span>{{ 'step'|translate }} </span> <span class="bg-blue-500 text white rounded-full h-40 px-2 w-40 py-2.5">2/2</span>
            </div>
        </div>
        <div class="sm:px-4 md:px-6 min-h-[80vh] bg-gray-50 py-2 rounded-md">
            <form [formGroup]="addForm" (ngSubmit)="onSubmit()" class="bg-white p-4 my-2 rounded-2xl shadow-sm">
                <!-- <div class="title-holder">
                        <h2 class="title">Add Buyer</h2>
                    </div> -->
                <div class="row px-2">
                    <div class="col-md-4 px-1">
                        <mat-form-field class="w-100 px-1" appearance="outline">
                            <mat-label>{{ 'market'|translate }}</mat-label>
                            <mat-select placeholder="{{ 'select'|translate }} {{ 'market'|translate }}" formControlName="markets" multiple required (selectionChange)="getCouncils()">
                                <mat-option *ngFor="let market of markets"
                                    [value]="market.id">{{market.name}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="addForm.get('markets').invalid">{{getErrorMessage()}}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row px-2">
                    <div class="col-md-4 px-1">
                        <!-- <ng-select [items]="markets" [multiple]="true" bindLabel="council" bindValue="id"
                            placeholder="{{ 'select'|translate }} council" [(ngModel)]="councils">
                        </ng-select> -->
                        <!-- <select2 [data]="markets" [value]="value" (update)="update($event)"
                            [templates]="{option : option, group: group}">
                            <ng-template #option let-data="data">{{data?.name}}</ng-template>
                        </select2> -->

                        <!-- <select class="select2" placeholder="{{ 'select'|translate }} Market" formControlName="councils" multiple
                            required>
                            <option *ngFor="let market of markets" [value]="market.id">{{market.name}}</option>
                        </select> -->
                        <mat-form-field class="w-100 px-1" appearance="outline">
                            <mat-label>{{ 'councils'|translate }}</mat-label>
                            <mat-select placeholder="{{ 'select'|translate }} {{ 'councils'|translate }}" formControlName="councils" multiple required>
                                <mat-option *ngFor="let council of marketCouncils"
                                    [value]="council.id">{{council.name}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="addForm.get('councils').invalid">{{getErrorMessage()}}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row px-2">
                    <div class="col-md-4 px-1">
                        <mat-form-field class="w-100 px-1" appearance="outline">
                            <mat-label>{{ 'crops'|translate }}</mat-label>
                            <mat-select placeholder="{{ 'select'|translate }} {{ 'crops'|translate }}" formControlName="crops" multiple required>
                                <mat-option *ngFor="let crop of crops" [value]="crop.id">{{crop.name}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="addForm.get('crops').invalid">{{getErrorMessage()}}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-md-4 px-1">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>{{ 'buying_capacity'|translate }} (Kg)</mat-label>
                        <input matInput type="number" step="0.01" placeholder="{{ 'buying_capacity'|translate }} (Kg)"
                            formControlName="buying_capacity" required>
                    </mat-form-field>
                </div>
                <div style="margin-top: 30px; display: flex; flex-direction: row; justify-content: flex-end;">

                    <div style="margin-top: 30px; display: flex; flex-direction: row; justify-content: flex-end;"
                        class="space-x-4">
                        <a (click)="toPrev()"
                            class="text-center cursor-pointer px-5 py-2 border rounded-lg mr-2 bg-red-500 text-white hover:bg-red-600 hover:no-underline">{{ 'back'|translate }}</a>

                        <!-- <button class="bg-red-500" mat-button>Back</button> -->
                        <button type="submit" class="px-5" mat-button><i class="fa fa-spinner fa-spin"
                                *ngIf="submitting"></i>{{ 'submit'|translate }}</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>