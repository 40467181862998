import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const baseUrl = environment.baseUrl;
const url1 = baseUrl + '/produces';
const url2 = baseUrl + '/produces-aggregated';
const url3 = baseUrl + '/farmers';
const url4 = baseUrl + '/farmers/search_farmers';
const url5 = baseUrl + '/sell-produce';

@Injectable({
  providedIn: 'root'
})
export class ProducesService {

  headers = new HttpHeaders({
    'Content-Type': 'application/json',
     'X-Requested-Width': 'XMLHttpRequeest',
    'Access-Control-Allow-Origin': '*',
    'Authorization': `Bearer ${localStorage.getItem('api_token')}`
   });

  fileheaders = new HttpHeaders({
    // 'Content-Type': 'multipart/form-data',
    'Accept': 'application/json',
    //  'X-Requested-Width': 'XMLHttpRequeest',
    'Access-Control-Allow-Origin': '*',
    'Authorization': `Bearer ${localStorage.getItem('api_token')}`
   });
  constructor(private http: HttpClient) { }

  getProduces() {
    return this.http.get(url1, {headers: this.headers});
  }

  getProduceData() {
    return this.http.get(url1, {headers: this.headers});
  }

  addProduce(data) {
    return this.http.post(url1, data, {headers: this.headers});
  }

  getProduceSingleData(id) {
    return this.http.get(url1 + '/' + id, {headers: this.headers});
  }

  updateProduceData(data, id) {
    return this.http.put(url1 + '/' + id, data, {headers: this.headers});
  }

  deleteProduceData(id) {
    return this.http.delete(url1 + '/' + id, {headers: this.headers});
  }


  getAggregatedProduces() {
    return this.http.get(url2, {headers: this.headers});
  }

  getFarmers() {
    return this.http.get(url3, {headers: this.headers});
  }

  searchFarmers(firstName, lastName) {
    return this.http.get<any>(`${url4}?first_name=${firstName}&last_name=${lastName}`);
  }


  sellProduce(data) {
    return this.http.post(url5, data, {headers: this.headers});
  }

}
