import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const baseUrl = environment.baseUrl;
const url1 = baseUrl + '/aggregation-center';

@Injectable({
  providedIn: 'root',
})
export class RegistrationService {
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'X-Requested-Width': 'XMLHttpRequeest',
    'Access-Control-Allow-Origin': '*',
    Authorization: `Bearer ${localStorage.getItem('api_token')}`,
  });

  fileheaders = new HttpHeaders({
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
    Authorization: `Bearer ${localStorage.getItem('api_token')}`,
  });
  constructor(private http: HttpClient) {}

  getAggregationCenters() {
    return this.http.get(url1, { headers: this.headers });
  }

  getAggregationCenterData() {
    return this.http.get(url1, { headers: this.headers });
  }

  addAggregationCenter(data) {
    return this.http.post(url1, data, { headers: this.fileheaders });
  }

  getAggregationCenterSingleData(id) {
    return this.http.get(url1 + '/' + id, { headers: this.headers });
  }

  updateAggregationCenterData(data, id) {
    return this.http.post(url1 + '/' + id, data, {
      headers: this.fileheaders,
      reportProgress: true,
      observe: 'events',
    });
  }

  deleteAggregationCenterData(id) {
    return this.http.delete(url1 + '/' + id, { headers: this.headers });
  }
}
