import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ManagementService } from './../../management.service';

export interface DialogData {
  id;
  action;
}

@Component({
  selector: 'app-add-crop',
  templateUrl: './add-crop.component.html',
  styleUrls: ['./add-crop.component.css']
})
export class AddCropComponent implements OnInit {

  edit;
  imageSrc: string;
  selectedImage: any;

  cropsForm = this.fb.group({
    name: ['', Validators.required],
    code: ['', Validators.required],
    crop_type: ['',],
    image: [''],
  });

  cropsFormEdit = this.fb.group({
    name: ['', Validators.required],
    code: ['', Validators.required],
    crop_type: ['',],
    image: FormControl[''],
  });

  constructor(
    public dialogRef: MatDialogRef<AddCropComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private manServ: ManagementService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    if (this.data.action === 'edit') {
      this.manServ.getSingleCrop(this.data.id).subscribe(
        res => {
          this.edit = res;
          this.cropsFormEdit.get('name').setValue(this.edit.name);
          this.cropsFormEdit.get('code').setValue(this.edit.code);
          this.cropsFormEdit.get('crop_type').setValue(this.edit.crop_type);
        }
      );
    }
  }

  getErrorMessage() {
    return 'This field is required';
  }

  onNoClick() {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.cropsForm.valid) {

      var formData: any = new FormData();
      formData.append('name', this.cropsForm.controls['name'].value)
      formData.append('code', this.cropsForm.controls['code'].value)
      formData.append('crop_type', this.cropsForm.controls['crop_type'].value)
      formData.append('image', this.selectedImage)

      this.manServ.addCrop(formData).subscribe(
        (resp: string) => {
          this.snackBar.open('', resp, {
            duration: 5000
          });
          if (resp !== 'Crop exists') {
            this.onNoClick();
          }
        }
      );
    }
  }

  onUpdate() {
    if (this.cropsFormEdit.valid) {
      var formData: any = new FormData();
      formData.append('name', this.cropsFormEdit.controls['name'].value)
      formData.append('code', this.cropsFormEdit.controls['code'].value)
      formData.append('crop_type', this.cropsFormEdit.controls['crop_type'].value)
      formData.append('image', this.selectedImage)
      formData.append('_method', 'PUT')
      
      // // Debugging
      // formData.forEach((value, key) => {
      //   console.log(key, value); // This should show all appended form data
      // });
      this.manServ.updateCrop(formData, this.data.id).subscribe(
        resp => {
          this.onNoClick();
        }
      );
    }
  }


  onFileChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {

        this.imageSrc = reader.result as string;
        this.selectedImage = event.srcElement.files[0];
      };

    }
  }
}
