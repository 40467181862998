import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { DeleteDataComponent } from '../../delete-data/delete-data.component';
import { ManagementService } from './../../management.service';
import { AddMarketComponent } from './../add-market/add-market.component';
import { MarketService } from '../market.service';

@Component({
  selector: 'app-market',
  templateUrl: './market.component.html',
  styleUrls: ['./market.component.css']
})
export class MarketComponent implements OnInit, AfterViewInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  dtCheck = false;
  markets;
  received;
  isLoading = true;
  isChecked = true;

  seasons;
  selectedSeasonId;
  constructor(
    private manServ: ManagementService,
    private markServ: MarketService,
    private dialog: MatDialog,
    public authService: AuthService
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.getSeasons();
    this.getMarkets();
    this.dtTrigger.next();

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 100,
      processing: true,
      responsive: true,
      dom: 'Bfrtip',
        // buttons: [
        //     'copy', 'csv', 'excel', 'print'
        // ]
    };
  }

  getMarkets() {
    var id = localStorage.getItem('asdsUserId');
    this.manServ.getMarkets(id).subscribe(
      data => {
        this.markets = data;

        this.isLoading = false;
        this.rerender();
      }
    );
  }

  rerender(): void {
    if (this.dtCheck){
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
          //  $('#dtb').DataTable();
      });
    }
    else {
      this.dtCheck = true;
      this.dtTrigger.next();
    }

  }

  getSeasons() {
    this.manServ.getSeasons().subscribe((data) => {
      this.seasons = data;
    });
  }

  filterBySeason() {
    this.isLoading = true;
    var data = { season_id: this.selectedSeasonId };
    this.markServ
      .filterMarketsBySeason(data)
      .subscribe((data) => {
        this.markets = data;
        this.isLoading = false;

        this.rerender();
      });
  }

  itemChanged(value) {
    this.isChecked = !this.isChecked;
  }

  onAddMarket() {
    const dialogRef = this.dialog.open(AddMarketComponent, {
      width: '600px',
      height: '550px',
      data: {action: 'add' }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('closed');
      // this.dtCheck = false;
      this.getMarkets();
    });
  }


  editMarket(id, regionId, districtId, wardId) {
    console.log(regionId);
    const dialogRef = this.dialog.open(AddMarketComponent, {
      width: '600px',
      height: '550px',
      data: {id, action: 'edit', region: regionId, district: districtId, ward: wardId }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('closed');
      this.getMarkets();
    });
  }


  deleteMarket(id) {
    const dialogRef = this.dialog.open(DeleteDataComponent, {
      width: '500px',
      height: '300px',
      data: {id, type: 'market' }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getMarkets();
    });
  }
}
