<div class="relative">
    <div id="content-header" class="absolute -top-24">
        <h1 class="text-uppercase font-bold">
            {{ action != 'update' ? 'Add Aggregation Center' : 'Update Aggregation Center' }}
        </h1>
    </div>
    <div class="absolute -top-28 right-2 cursor-pointer text-white bg-red-500 hover:bg-red-600 rounded-lg h-6 w-6 items-center text-center"
        title="Close" (click)="goBack()">x</div>
    <div class="flex flex-col-reverse md:flex-row gap-4 w-full min-h-64">
        <div class="px-4 bg-gray-50 rounded-md p-2 relative w-full">

            <div class="grid grid-cols-1 md:grid-cols-5 gap-3 px-1 py-2 mb-3 border-b-8 border-sky-100/50 hidden">
                <div class="md:col-span-2">
                    <input type="text" id="fname" [(ngModel)]="firstName" placeholder="Farmer first name"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                </div>
                <div class="md:col-span-2">
                    <input type="text" id="lname" [(ngModel)]="lastName" placeholder="Farmer last name"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                </div>
                <button (click)="searchFarmers()"
                    class="bg-green-700 px-3 py-1 text-white rounded-md flex space-x-2 items-center justify-center">
                    <i class="fa fa-spinner fa-spin" *ngIf="searching"></i>
                    <span>Search</span>
                </button>
            </div>

            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-2">
                    <div class="col-span-2">
                        <label for="aggregation_center_id">Aggregation Center <span class="text-red-500">*</span></label>
                        <select id="aggregation_center_id" formControlName="aggregation_center_id" class="custom-select bg-gray-50">
                            <option value="" disabled>Select..</option>
                            <option *ngFor="let center of centers" [value]="center.id">{{ center.name }}</option>
                        </select>
                        <div class="text-red" *ngIf="form.get('aggregation_center_id').invalid && form.get('aggregation_center_id').touched">Aggregation center is
                            required.
                        </div>
                    </div>
                    <div>
                        <label for="farmer_id">Farmer <span class="text-red-500">*</span></label>
                        <app-custom-select [options]="farmers" [(value)]="selectedFarmerId" labelField="full_name" valueField="id"
                            placeholder="Select...">
                        </app-custom-select>
                        <select id="farmer_id" formControlName="farmer_id" class="custom-select bg-gray-50 hidden">
                            <option value="" disabled>Select a farmer</option>
                            <option *ngFor="let farmer of farmers" [value]="farmer.id">{{ farmer.full_name }}</option>
                        </select>
                        <div class="text-red" *ngIf="form.get('farmer_id').invalid && form.get('farmer_id').touched">Farmer is required.
                        </div>
                    </div>
                    <div>
                        <label for="crop_id">Produce Type <span class="text-red-500">*</span></label>
                        <select id="crop_id" formControlName="crop_id" class="custom-select bg-gray-50">
                            <option value="" disabled>Select a type</option>
                            <option *ngFor="let crop of crops" [value]="crop.id">{{ crop.name }}</option>
                        </select>
                        <div class="text-red" *ngIf="form.get('crop_id').invalid && form.get('crop_id').touched">Produce type is
                            required.
                        </div>
                    </div>

                    <div>
                        <label for="amount">Amount (Kg)<span class="text-red-500">*</span></label>
                        <input type="number" min="1" id="amount" formControlName="amount"
                            placeholder="Enter center amount"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                        <div class="text-red" *ngIf="form.get('amount').invalid && form.get('amount').touched">Amount is required.</div>
                    </div>
                    <div>
                        <label for="value">Unit Price (TSh.) <span class="text-red-500">*</span></label>
                        <input type="number" min="1" id="value" formControlName="value" placeholder="Enter value"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                        <div class="text-red" *ngIf="form.get('value').invalid && form.get('value').touched">Value is required.</div>
                    </div>

                    <div *ngIf="action == 'update'">
                        <label for="value">Status <span class="text-red-500">*</span></label>
                        <select id="status" formControlName="status" class="custom-select bg-gray-50">
                            <option value="" disabled>Select..</option>
                            <option value="active">Active</option>
                            <option value="in active">In Active</option>
                        </select>
                        <div class="text-red" *ngIf="form.get('status').invalid && form.get('status').touched">Status is required.</div>
                    </div>
                </div>

                <button type="submit" [disabled]="form.invalid"
                    class="px-2.5 py-1.5 rounded-md flex space-x-2 items-center">
                    <i class="fa fa-spinner fa-spin" *ngIf="submitting"></i>
                    <span *ngIf="action !== 'update'">Submit</span>
                    <span *ngIf="action === 'update'">Update</span>
                </button>
            </form>

            <div class="absolute top-1/2 right-1/2 z-50" *ngIf="loading">
                <div class="w-20 h-20 bg-black/50 flex justify-center items-center rounded-md text-gray-200">
                    <i class="fa fa-spinner fa-spin text-4xl"></i>
                </div>
            </div>
        </div>
        <div class="w-full">
            <app-farmer-modal [farmers]="farmers" [isVisible]="showModal" (eventEmitter)="handleEvent($event)"></app-farmer-modal>
        </div>
    </div>
    