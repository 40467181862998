<div id="content" class="relative">
    <div class="fixed top-2/4 left-2/4 z-50 {{ loading ? '' : 'hidden' }}">
        <div class=" bg-gray-900/50 text-gray-100 p-3 rounded-md">
            <i class="fa fa-spinner fa-spin fa-2x"></i>
        </div>
    </div>

    <div id="content-header" class="absolute -top-24">
        <h1 class="text-uppercase font-bold">Buyers Order Report</h1>
    </div>
    <div class="absolute -top-28 right-2 cursor-pointer text-white bg-red-500 hover:bg-red-600 rounded-lg h-6 w-6 items-center text-center"
        title="Close" (click)="goBack()">x</div>
    <div class="">

        <div class="col-md-12">
            <div class="grid grid-cols-1 md:grid-cols-4 gap-2 px-4">
                <div class="bg-gradient-to-r from-green-200/30 to-green-200/30 rounded-md px-2 py-2.5">
                    <div class="flex space-x-2 items-center">
                        <div class="">
                            <div class="bg-white rounded-full p-2 text-lg font-bold shadow-xl">Tsh.</div>
                        </div>
                        <div class="">
                            <div class="text-sky-900 font-bold text-center">Owed Amount</div>
                            <div class="font-bold text-green-600 text-center text-lg lg:text-xl">{{ owed_amount | number }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="view">
                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table p-3">
                    <thead>
                        <tr>
                            <th class="hidden"></th>
                            <th width="20%">Buyer</th>
                            <th>Total Orders</th>
                            <th>Crops</th>
                            <th>Quantity (Kg)</th>
                            <th>Sold Quantity (Kg)</th>
                            <th>Ordered Amount (Tsh)</th>
                            <th>Sold Amount (Tsh)</th>
                        </tr>

                    </thead>
                    <tbody>
                        <tr *ngFor="let order of buyers">
                            <td class="hidden">{{  order.trader_id   }}</td>
                            <td>{{  order.buyer }}</td>
                            <td class="text-center">{{  order.orders    }}</td>
                            <td>{{  order.crops }}</td>
                            <td class="text-center">{{  order.quantity }}</td>
                            <td class="text-center">{{  order.sold_quantity }}</td>
                            <td class="text-right">{{order.ordered_amount | number}}</td>
                            <td class="text-right">{{order.sold_amount | number}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    </div>
</div>