<div class="relative">
    <div id="content-header" class="absolute -top-24">
        <h1 class="text-uppercase font-bold">Sell Produces</h1>
    </div>
    <div class="absolute -top-28 right-2 cursor-pointer text-white bg-red-500 hover:bg-red-600 rounded-lg h-6 w-6 items-center text-center"
        title="Close" (click)="goBack()">x</div>
    <div class="flex flex-col-reverse md:flex-row gap-4 w-full min-h-64">
        <div class="w-full">
            <div class="px-4 bg-gray-50 rounded-md p-2 relative">
                <form [formGroup]="form" (ngSubmit)="onSubmit()">
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
                        <div class="mb-2">
                            <label for="date">Date <span class="text-red-500">*</span></label>
                            <input type="date" id="date" formControlName="date"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                            <div class="text-red-500" *ngIf="form.get('date').invalid && form.get('date').touched">
                                Date is required.
                            </div>
                        </div>
                        <div class="mb-2">
                            <label for="order_id">Order<span class="text-red-500">*</span></label>
                            <select id="order_id" formControlName="order_id" class="custom-select bg-gray-50"
                                (change)="getOrderCropAmounts($event.target.value)">
                                <option value="" disabled>Select a order</option>
                                <option *ngFor="let order of orders" [value]="order.id">{{ order.orderno }}</option>
                            </select>
                            <div class="text-red-500" *ngIf="form.get('order_id').invalid && form.get('order_id').touched">
                                Order
                                is required.</div>
                        </div>
                        <div class="mb-2">
                            <label for="trader_id">Buyer Name<span class="text-red-500">*</span></label>
                            <select id="trader_id" formControlName="trader_id" class="custom-select bg-gray-50">
                                <option value="" disabled>Select a buyer</option>
                                <option *ngFor="let buyer of buyers" [value]="buyer.id">{{ buyer.full_name }}</option>
                            </select>
                            <div class="text-red-500"
                                *ngIf="form.get('trader_id').invalid && form.get('trader_id').touched">Buyer
                                is required.</div>
                        </div>
                        <div class="mb-2">
                            <label for="amount">Amount (Kg)<span class="text-red-500">*</span></label>
                            <input type="number" min="1" max="{{ produce.amount }}" id="amount" formControlName="amount"
                                placeholder="Enter center amount"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                            <div class="text-red-500" *ngIf="form.get('amount').invalid && form.get('amount').touched">
                                Amount is
                                required.</div>
                        </div>
                    </div>
                    <button type="submit" [disabled]="form.invalid"
                        class="px-2.5 py-1.5 rounded-md flex space-x-2 items-center">
                        <i class="fa fa-spinner fa-spin" *ngIf="submitting"></i>
                        <span>Submit</span>
                    </button>
                </form>
    
                <div class="absolute top-1/2 right-1/2 z-50" *ngIf="loading">
                    <div class="w-20 h-20 bg-black/50 flex justify-center items-center rounded-md text-gray-200">
                        <i class="fa fa-spinner fa-spin text-4xl"></i>
                    </div>
                </div>

            </div>
        </div>

        <div class="w-full overflow-x-auto my-0.5">
            <div class="bg-green-100/5 rounded-md min-w-96 p-2">
                <div class="border-b pb-5">
                    <div class="flex space-x-2 py-1">
                        <div class="font-bold">Farmer:</div>
                        <div class="">{{ produce.farmer.full_name }}</div>
                    </div>
                    <div class="flex space-x-2 py-1">
                        <div class="font-bold">Crop:</div>
                        <div class=" md:flex">{{ produce.crop.name }}</div>
                    </div>
                    <div class="flex space-x-2 py-1">
                        <div class="font-bold">Amount Available (Kg):</div>
                        <div class=" md:flex">{{ produce.amount }}</div>
                    </div>
                    <div class="flex space-x-2 py-1">
                        <div class="font-bold">Unit Price (Tsh):</div>
                        <div class=" md:flex">{{ produce.value }}</div>
                    </div>
                </div>
                <div class="py-5" *ngIf="order_details">
                    <div class="text-lg font-bold text-sky-700">Order Details</div>

                    <div class="flex space-x-2 py-1">
                        <div class="font-bold text-gray-500">Crop:</div>
                        <div class=" md:flex">{{ produce.crop.name }}</div>
                    </div>
                    <div class="flex space-x-2 py-1">
                        <div class="font-bold text-gray-500">Order Amount:</div>
                        <div class="text-sky-500 bg-sky-100/50 rounded-md px-1 md:flex">{{ order_details?.order_amount
                            }} Kg</div>
                    </div>
                    <div class="flex space-x-2 py-1">
                        <div class="font-bold text-gray-500">Sold Amount:</div>
                        <div class="text-green-500 bg-green-100/50 rounded-md px-1 md:flex">{{
                            order_details?.sold_amount }} Kg</div>
                    </div>
                    <div class="flex space-x-2 py-1">
                        <div class="font-bold text-gray-500">Remain Amount:</div>
                        <div class="text-red-500 bg-red-100/50 rounded-md px-1 md:flex">{{ order_details?.remain_amount
                            }} Kg</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>