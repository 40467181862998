import { Component, ElementRef, Input, Output, EventEmitter, AfterViewInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import * as $ from 'jquery';
import 'select2';

@Component({
  selector: 'app-custom-select',
  template: `
    <select class="custom-select bg-gray-50 form-control" #select>
      <option value=""></option>
      <ng-content></ng-content>
    </select>
  `,
  styleUrls: ['./custom-select.component.css']
})
export class CustomSelectComponent implements AfterViewInit, OnDestroy, OnChanges {
  private _value: any;
  
  @Input() options: any[] = [];
  @Input() labelField: string = 'label';
  @Input() valueField: string = 'value';
  @Output() valueChange = new EventEmitter<any>();
  @Input() placeholder: string = 'Select an option';

  constructor(private el: ElementRef) {}

  // Setter for value to update Select2 value dynamically
  @Input()
  set value(val: any) {
    this._value = val;
    this.updateSelect2Value();
  }

  ngAfterViewInit() {
    this.initializeSelect2();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['options'] && !changes['options'].isFirstChange()) {
      // Reinitialize Select2 if options change
      this.initializeSelect2();
    }
  }

  private initializeSelect2() {
    const selectElem = $(this.el.nativeElement).find('select') as any;

    // Destroy any existing instance before re-initializing
    if (selectElem.hasClass("select2-hidden-accessible")) {
      selectElem.select2('destroy');
    }

    // Initialize Select2 with updated options and settings
    selectElem.select2({
      placeholder: this.placeholder,
      data: this.options.map(option => ({
        id: option[this.valueField],
        text: option[this.labelField]
      }))
    }).on('change', (event: any) => {
      this.valueChange.emit(event.target.value);
    });

    // Set initial value after initialization
    this.updateSelect2Value();
  }

  private updateSelect2Value() {
    const selectElem = $(this.el.nativeElement).find('select') as any;

    // Ensure Select2 is initialized before setting the value
    if (selectElem.hasClass("select2-hidden-accessible") && this._value !== undefined) {
      selectElem.val(this._value).trigger('change');
    }
  }

  ngOnDestroy() {
    // Destroy Select2 instance to avoid memory leaks
    ($(this.el.nativeElement).find('select') as any).select2('destroy');
  }
}
