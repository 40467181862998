<div class="w-100">
  <div class="grid grid-cols-1 md:grid-cols-4 gap-2 items-end">
    <div>
      <label for="season_id">Seasons <span class="text-red-500">*</span></label>
      <app-custom-select
        [options]="seasons"
        [(value)]="selectedSeasonId"
        labelField="name"
        valueField="id"
        placeholder="Select..."
      >
      </app-custom-select>
    </div>
    <div class="">
        <button class="px-4 py-2 rounded-md" (click)="filterBySeason()">Filter</button>
    </div>
  </div>
  <!-- <div class="row p-3 condition mt-1">
        <div class="col-md-3">
            <label class="switch">
            <input type="checkbox" value="one" [checked]="isChecked" (change)="item1Changed($event.target.value)">
            <span class="slider round"></span>

          </label><span class="desc"> Warehouse Registration</span>
        </div>
        <div class="col-md-4">
            <label class="switch">
              <input type="checkbox" value="two" [checked]="!isChecked" (change)="item2Changed($event.target.value)">
              <span class="slider round"></span>
            </label>
            <span class="desc"> Receive Commodities in a warehouse</span>
        </div>
    </div> -->
  <div [ngClass]="!isChecked ? 'hidden' : ''">
    <div [ngClass]="!isLoading ? 'show' : 'hide'" class="view card">
      <div class="table-responsive">
        <div class="card-header d-flex justify-content-start">
          <button class="button" mat-button (click)="onAddWarehouse()">
            <span class="material-icons notranslate">add_circle_outline</span
            >Add warehouse
          </button>
        </div>
        <div class="card-body">
          <!-- i have removed [dtTrigger]="dtTrigger" below -->
          <table
            datatable
            [dtOptions]="dtOptions"
            [dtTrigger]="dtTrigger"
            id="dtbs"
            class="table p-3"
          >
            <thead>
              <tr>
                <th>#</th>
                <th>Warehouse ID</th>
                <th>Warehouse Name</th>
                <th>Capacity (Kg)</th>
                <th>Owner</th>
                <th>Ownership</th>
                <!-- <th>Registered</th> -->
                <!-- <th>Grade</th> -->
                <th>Village</th>
                <th>Ward</th>
                <th>District</th>
                <th>Region</th>
                <th>Latitude</th>
                <th>Longitude</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let warehouse of warehouses; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ warehouse.identifier }}</td>
                <td>{{ warehouse.name }}</td>
                <td class="text-right">{{ warehouse.capacity }}</td>
                <td>{{ warehouse.owner }}</td>
                <td>{{ warehouse.ownership }}</td>
                <!-- <td>{{ warehouse.registered }}</td> -->
                <!-- <td>{{ warehouse.grade }}</td> -->
                <td>{{ warehouse.village?.name }}</td>
                <td>{{ warehouse.ward?.name }}</td>
                <td>{{ warehouse.district?.name }}</td>
                <td>{{ warehouse.region?.name }}</td>
                <td>{{ warehouse.lat }}</td>
                <td>{{ warehouse.long }}</td>
                <td style="display: flex; flex-direction: row">
                  <a
                    class="text-success act"
                    (click)="
                      editWarehouse(
                        warehouse.id,
                        warehouse.region_id,
                        warehouse.district_id,
                        warehouse.ward_id
                      )
                    "
                    ><i class="fa fa-edit"></i
                  ></a>
                  <mat-icon
                    *ngIf="authService.isAdmin"
                    class="text-danger act"
                    (click)="deleteWarehouse(warehouse.id)"
                    >delete_forever</mat-icon
                  >
                  <a
                    class="text-primary act"
                    title="View Balance"
                    (click)="viewBalance(warehouse.id)"
                    ><i class="fa fa-eye"></i
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div *ngIf="isLoading" class="spinner-holder">
      <mat-spinner class="spinner"></mat-spinner>
    </div>
  </div>
  <!-- <div [ngClass]="isChecked ? 'hidden': ''">
        <app-inwarehouse></app-inwarehouse>
    </div> -->
</div>
