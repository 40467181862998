import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';


const baseUrl = environment.baseUrl;
const url1 = baseUrl + '/login-web';
const url2 = baseUrl + '/user-role/';
const url3 = baseUrl + '/check-login/';
const url4 = baseUrl + '/clear-login/';
const url5 = baseUrl + '/trader_approval_status/';
const url6 = baseUrl + '/my_role/';
const url7 = baseUrl + '/trader_category/';
const url8 = baseUrl + '/reset_password';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  checked = false
  updating = false

  headers = new HttpHeaders({ 'Content-Type': 'application/json', 'X-Requested-Width': 'XMLHttpRequeest' });

  constructor(private http: HttpClient) {
  }

  login(data) {
    try {
      return this.http.post(url1, data, { headers: this.headers });
    } catch (e) {
      // alert(e);
    }
  }
  checkLogin() {
    var token = localStorage.getItem('api_token')
    return this.http.get(url3 + token, { headers: this.headers });
  }
  clearLogin() {
    var token = localStorage.getItem('api_token')
    return this.http.get(url4 + token, { headers: this.headers });
  }

  fetchTraderApprovalStatus() {
    var token = localStorage.getItem('asdsUserId')
    return this.http.get(url5 + token, { headers: this.headers });
  }

  fetchMyRole() {
    var token = localStorage.getItem('asdsUserId')
    return this.http.get(url6 + token, { headers: this.headers });
  }
  fetchTraderCategory() {
    var token = localStorage.getItem('asdsUserId')
    return this.http.get(url7 + token, { headers: this.headers });
  }
  resetPassword(data) {
    return this.http.post(url8, data, { headers: this.headers });
  }

  setCredentials(id, username, role, permissions, api_token, approval_status, trader_category) {
    localStorage.setItem('username', username);
    localStorage.setItem('asdsUserId', id);
    localStorage.setItem('role', role);
    localStorage.setItem('permissions', permissions);
    localStorage.setItem('api_token', api_token);
    localStorage.setItem('approval_status', approval_status);
    localStorage.setItem('trader_category', trader_category);

    window.location.href = 'manage-ac/summaries';
  }

  get isAuth() {
    if (this.getId() === null) {
      return false;
    } else {
      return true;
    }
  }

  get isLogedin() {
    if (this.isAuth) {
      return true
    } else {
      if (!this.checked) {
        this.checked = true;
        this.checkLogin().subscribe(
          resp => {
            // console.log(resp)
            if (resp != null) {
              var user = resp[0]
              if(user) {
                this.setCredentials(user.user_id, user.username, user.role, resp[1], user.api_token, user.approval_status, user.trader_category)
              }

              // console.log(resp[1])
              if (this.getId() === null) {
                return false;
              } else {
                return true;
              }
            }

          }
        );
      }
      return false
    }
  }

  get isAdmin() {
    const role = localStorage.getItem('role');    
    // const id = localStorage.getItem('asdsUserId');
    // this.http.get(url2 + id, {headers: this.headers}).subscribe(
    //   data => {

    //   }
    // )
    if (role.toLowerCase() === 'admin') {
      return true;
    } else {
      return false;
    }
  }

  isAllowed(slug) {
    if(this.isAdmin) {
      return true;
    } else {
      const permissions = localStorage.getItem('permissions');
      if (permissions === null) {
        localStorage.removeItem('id');
      } else {
        if (permissions.includes(slug)) {
          return true;
        } else {
          return false;
        }
      }
    }
  }
  getId() {
    // return 1;
    return localStorage.getItem('asdsUserId');
  }

  getUsername() {
    // return 'Eliya';
    return localStorage.getItem('username');
  }

  removeCredentials() {
    localStorage.removeItem('username');
    localStorage.removeItem('asdsUserId');
    localStorage.removeItem('role');
    localStorage.removeItem('permissions');
    localStorage.removeItem('approval_status');
    localStorage.removeItem('trader_category');
    
    console.log(localStorage.getItem('role'));
    
    this.clearLogin().subscribe(
      resp => { 
        localStorage.removeItem('api_token');
        localStorage.clear()
      }
    )
  }

  get isApproved() {
    if (this.isBuyer) {
      var status = localStorage.getItem('approval_status');
      if (status == 'approved') {        
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }


  get isBuyer() {
    var role = localStorage.getItem('role');
    if (role.toLowerCase() == 'trader') {
      var trader_category = localStorage.getItem('trader_category');
      if(trader_category) {
        if(trader_category.toLowerCase().includes('buyer')) {
          return true;
        }
      }
      return false;
    } else {
      return false;
    }
  }

  get isTemp() {
    var role = localStorage.getItem('role');
    if (role.toLowerCase() == 'temp') {
      return true;
    } else {
      return false;
    }
  }

  has_role(role) {
    
    if(this.isAdmin) {
      return true;
    } else {
      if(localStorage.getItem('role').toLowerCase() == role) {
        return true;
      } else {
        return false;
      }
    }
  }

  get isAcManager() {
    const role = localStorage.getItem('role');
    if (role.toLowerCase() === 'ac-manager') {
      return true;
    } else {
      return false;
    }
  }


  setLanguage(value) {
    // localStorage.removeItem('language');
    localStorage.setItem('language', value);
    window.location.reload()
  }


  getLanguage() {
    return localStorage.getItem('language');
  }
}
