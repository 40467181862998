import { Component, OnInit } from '@angular/core';
import { ManagementService } from '../../../management.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { RegistrationService } from '../registration.service';

@Component({
  selector: 'app-ac-form',
  templateUrl: './ac-form.component.html',
  styleUrls: ['./ac-form.component.css']
})
export class AcFormComponent implements OnInit {
  form: FormGroup;

  id;
  action;

  regions;
  districts;
  wards;
  villages;
  loading = false;
  submitting = false;

  menu = 'Add aggregation centers'

  constructor(
    public active: ActivatedRoute,
    public acRegServ: RegistrationService,
    public manServ: ManagementService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private route: Router,
  ) {
    active.url.subscribe(
      data => {
        // console.log(data[2].path)
        this.id = data[2].path;
        if (this.id) {
          this.action = 'update';
        }
      }
    );
  }

  ngOnInit(): void {
    this.loading = true
    this.form = this.fb.group({
      name: ['', Validators.required],
      region_id: ['', Validators.required],
      district_id: ['', Validators.required],
      ward_id: [''],
      village_id: [''],
      services: this.fb.array([
        this.fb.control('')  // Start with one empty service input
      ])
    });
    this.getAllRegions();
    if (this.id) {
      this.acRegServ.getAggregationCenterSingleData(this.id).subscribe(
        (data: any) => {
          this.getDistricts(data.region_id);
          this.getWards(data.district_id);
          this.getVillages(data.ward_id);
          this.form.patchValue({
            name: data.name,
            region_id: data.region_id,
            district_id: data.district_id,
            ward_id: data.ward_id,
            village_id: data.village_id,
          });

          this.services.clear();  // Clear existing services
          // Add new services to the FormArray
          data.services_list.forEach(serviceName => {
            this.services.push(this.fb.control(serviceName));
          });
        }
      );
    }
  }

  getAllRegions() {
    this.manServ.getAllRegions().subscribe(
      data => {
        this.regions = data;
        this.loading = false;
      }
    );
  }

  getDistricts(regionId) {
    this.loading = true;

    this.manServ.getAllDistricts(regionId).subscribe(
      data => {
        this.districts = data;
        this.loading = false;
      }
    );
  }

  getWards(districtId) {
    this.loading = true;
    this.manServ.getWards(districtId).subscribe(
      data => {
        this.wards = data;
        this.loading = false;
      }
    );
  }

  getVillages(wardId) {
    this.loading = true;
    this.manServ.getVillages(wardId).subscribe(
      data => {
        this.villages = data;
        this.loading = false;
      }
    );
  }

  get services(): FormArray {
    return this.form.get('services') as FormArray;
  }

  addService() {
    this.services.push(this.fb.control(''));
  }

  removeService(index: number) {
    this.services.removeAt(index);
  }

  onSubmit() {
    this.submitting = true;
    if (this.form.valid) {
      if (this.action !== 'update') {
        this.form.value['user_id'] = localStorage.getItem('asdsUserId');
        // console.log('valid');
        this.acRegServ.addAggregationCenter(this.form.value).subscribe(
          (resp: any) => {
            this.snackBar.open('message', resp['success'], {
              duration: 5000
            });
            this.submitting = false;
            this.route.navigate(['manage-ac/ac-registration'])
          }
        );
      } else {
        this.acRegServ.updateAggregationCenterData(this.form.value, this.id).subscribe(
          (resp: any) => {
            this.snackBar.open('message', resp['success'], {
              duration: 5000
            });
            this.submitting = false;
            this.route.navigate(['manage-ac/ac-registration'])
          }
        );
      }
    }
  }


  goBack() {
    this.route.navigate(['manage-ac/ac-registration']);
  }
}
