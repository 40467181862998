import { MatDialog } from '@angular/material/dialog';
import { ProfileComponent } from './../profile/profile.component';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  username = this.authService.getUsername();
  usernameInitial;
  language: string;
  constructor(
    public route: Router,
    public authService: AuthService,
    public dialog: MatDialog,
    private translateService: TranslateService
  ) {
    this.translateService.setDefaultLang('en');
    this.translateService.use(localStorage.getItem('language'));
    if (this.username !== null) {
      this.usernameInitial = this.username.charAt(0).toUpperCase();
    }
  }

  ngOnInit(): void {
    this.language = this.authService.getLanguage() || 'en'
  }

  backToHome() {
    this.route.navigate(['']);
  }

  ToManagement() {
    this.route.navigate(['managements']);
  }

  toLogin() {
    // window.location.href = environment.loginUrl
    this.route.navigate(['/login']);
  }


  onLogout() {
    this.authService.removeCredentials();
    // window.location.href = environment.loginUrl
    this.route.navigate(['/login']);
    // this.route.navigate(['']);
  }
  
  manageProfile() {
    const dialogRef = this.dialog.open(ProfileComponent, {
      width: '550px',
      height: '590px',
      data: {action: 'profile'}
    });

    dialogRef.afterClosed().subscribe(result => {
     
    });
  }

  changePassword() {
    const dialogRef = this.dialog.open(ProfileComponent, {
      width: '500px',
      height: '350px',
      data: {action: 'passwords'}
    });

    dialogRef.afterClosed().subscribe(result => {
     
    });
  }


  setLanguage(value) {
    this.language = value;
    this.authService.setLanguage(value);
  }

}
