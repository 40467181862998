<div class="relative">
    <div class="w-full min-h-64">
        <div class="title-holder">
            <h2 class="title">{{ data.action != 'update' ? 'Add Aggregation Center' : 'Update Aggregation Center' }}</h2>
        </div>
    <div class="">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
                <div>
                    <!-- <label for="name">Name <span class="text-red-500">*</span></label>
                    <input id="name" formControlName="name" placeholder="Enter center name"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                    <div *ngIf="form.get('name').invalid && form.get('name').touched">Name is required.</div> -->
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Center Name</mat-label>
                        <input matInput placeholder="Center Name" formControlName="name" required>
                        <mat-error *ngIf="form.get('name').invalid">{{getErrorMessage()}}</mat-error>
                    </mat-form-field>
                </div>

                <div class="">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Center capacity (MT)</mat-label>
                        <input matInput type="number" placeholder="Center capacity" formControlName="capacity" required>
                        <mat-error *ngIf="form.get('capacity').invalid">{{getErrorMessage()}}</mat-error>
                    </mat-form-field>
                </div>
                <div class="">
                    <mat-form-field class="w-100 px-1" appearance="outline">
                        <mat-label>{{ 'crops'|translate }}</mat-label>
                        <mat-select placeholder="{{ 'select'|translate }} {{ 'crops'|translate }}" formControlName="crops" multiple required>
                            <mat-option *ngFor="let crop of crops" [value]="crop.id">{{crop.name}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="form.get('crops').invalid">{{getErrorMessage()}}</mat-error>
                    </mat-form-field>
                </div>
    
                <div class="">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Crop Source</mat-label>
                        <input matInput type="text" placeholder="Crop source" formControlName="crop_source" required>
                        <mat-error *ngIf="form.get('crop_source').invalid">{{getErrorMessage()}}</mat-error>
                    </mat-form-field>
                </div>

                <div class="col-span-full">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Available Equipments</mat-label>
                        <input matInput type="text" placeholder="e.g moisturementer, weighing scale, e.t.c" formControlName="equipments" required>
                        <mat-error *ngIf="form.get('equipments').invalid">{{getErrorMessage()}}</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-span-full mb-2">
                    <!-- Label for the file input -->
                    <label class="file-input-label">Attachment (PDF)</label>
                  
                    <!-- Custom file input -->
                    <div class="file-input-container w-full">
                      <input class="w-full"
                        type="file"
                        accept=".pdf"
                        placeholder="Select a PDF file"
                        formControlName="attachment"
                        (change)="onFileSelected($event)"
                        #fileInput
                      />
                      <button mat-stroked-button type="button" (click)="fileInput.click()">
                        <mat-icon>attach_file</mat-icon>
                        <span>{{ fileName || 'Choose File' }}</span>
                      </button>
                    </div>
                  
                    <!-- Display validation error -->
                    <mat-error *ngIf="form.get('attachment').invalid">{{ getErrorMessage() }}</mat-error>
                  </div>
                <div>
                    <!-- <label for="region_id">Region <span class="text-red-500">*</span></label>
                    <select id="region_id" formControlName="region_id" (change)="getDistricts($event.target.value)"
                        class="custom-select bg-gray-50">
                        <option value="" disabled>Select a region</option>
                        <option *ngFor="let region of regions" [value]="region.id">{{ region.name }}</option>
                    </select>
                    <div *ngIf="form.get('region_id').invalid && form.get('region_id').touched">Region is required.</div> -->

                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Regions</mat-label>
                        <mat-select placeholder="Select Region" formControlName="region_id" required (selectionChange)="getDistricts($event.value)">
                            <mat-option *ngFor="let region of regions" [value]="region.id">{{region.name}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="form.get('region_id').invalid">{{getErrorMessage()}}</mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <!-- <label for="district_id">District <span class="text-red-500">*</span></label>
                    <select id="district_id" formControlName="district_id" (change)="getWards($event.target.value)"
                        class="custom-select bg-gray-50">
                        <option value="" disabled>Select a district</option>
                        <option *ngFor="let district of districts" [value]="district.id">{{ district.name }}</option>
                    </select>
                    <div *ngIf="form.get('district_id').invalid && form.get('district_id').touched">District is required.
                    </div> -->

                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Districts</mat-label>
                        <mat-select placeholder="Select District" formControlName="district_id" required (selectionChange)="getWards($event.value)">
                            <mat-option *ngFor="let district of districts" [value]="district.id">{{district.name}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="form.get('district_id').invalid">{{getErrorMessage()}}</mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <!-- <label for="ward_id">Ward:</label>
                    <select id="ward_id" formControlName="ward_id" (change)="getVillages($event.target.value)"
                        class="custom-select bg-gray-50">
                        <option value="" disabled>Select a ward</option>
                        <option *ngFor="let ward of wards" [value]="ward.id">{{ ward.name }}</option>
                    </select>
                    <div *ngIf="form.get('ward_id').invalid && form.get('ward_id').touched">Ward is required.</div> -->

                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Wards</mat-label>
                        <mat-select placeholder="Select Ward" formControlName="ward_id" (selectionChange)="getVillages($event.value)">
                            <mat-option *ngFor="let ward of wards" [value]="ward.id">{{ward.name}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="form.get('ward_id').invalid">{{getErrorMessage()}}</mat-error>
                    </mat-form-field>
                </div>
                <div class="">
                    <!-- <label for="village_id" class="block text-gray-700 text-sm font-medium mb-1">Village/Street:</label>
                    <select id="village_id" formControlName="village_id" class="custom-select bg-gray-50">
                        <option value="" disabled>Select a village/street</option>
                        <option *ngFor="let village of villages" [value]="village.id">{{ village.name }}</option>
                    </select>
                    <div *ngIf="form.get('village_id').invalid && form.get('village_id').touched" class="error-message">
                        Village/Street is required.</div> -->

                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Villages/Streets</mat-label>
                        <mat-select placeholder="Select Village" formControlName="village_id">
                            <mat-option *ngFor="let village of villages" [value]="village.id">{{village.name}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="form.get('village_id').invalid">{{getErrorMessage()}}</mat-error>
                    </mat-form-field>
                </div>

                <div class="">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>latitude</mat-label>
                        <input matInput placeholder="latitude" formControlName="latitude">
                    </mat-form-field>
                </div>
                <div class="">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>longitudes</mat-label>
                        <input matInput placeholder="longitudes" formControlName="longitude">
                    </mat-form-field>
                </div>
    
            </div>
    
    
            <div class="my-2 text-lg">Services <span class="text-red-500">*</span></div>
            <div formArrayName="services">
                <div *ngFor="let service of services.controls; let i = index" class="flex items-center space-x-2 my-0.5">
                    <label for="service-{{i}}">{{i + 1}}:</label>
                    <input [formControlName]="i" id="service-{{i}}"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2">
                    <a type="button" (click)="removeService(i)" *ngIf="i > 0"
                        class="px-2 py-1 bg-gray-100 rounded-md text-gray-700 hover:text-red-500">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="size-6">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                        </svg>
                    </a>
                </div>
                <a type="button" (click)="addService()"
                    class="my-2 hover:no-underline hover:text-green-900 text-green-700 bg-green-100/50 font-bold px-2 py-0.5">
                    <div class="flex space-x-1 items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="size-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                        <span>Add Service</span>
                    </div>
                </a>
            </div>
    
            <div class="mt-5 flex justify-end space-x-4">
                <button (click)="onNoClick()" class="cancelbtn" mat-button>Cancel</button>
                <button type="submit" [disabled]="form.invalid" class="px-5 py-1.5 rounded-md flex space-x-2 items-center {{ form.invalid ? 'cursor-not-allowed' : '' }}">
                    <i class="fa fa-spinner fa-spin" *ngIf="submitting"></i>
                    <span *ngIf="action !== 'update'">Submit</span>
                    <span *ngIf="action === 'update'">Update</span>
                </button>
            </div>
        </form>
    
        <div class="absolute top-1/2 right-1/2 z-50" *ngIf="loading">
            <div class="w-20 h-20 bg-black/50 flex justify-center items-center rounded-md text-gray-200">
                <i class="fa fa-spinner fa-spin text-4xl"></i>
            </div>
        </div>
    </div>
</div>