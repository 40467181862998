<ng-container>
  <app-header></app-header>


  <button data-drawer-target="sidebar-multi-level-sidebar" data-drawer-toggle="sidebar-multi-level-sidebar"
    aria-controls="sidebar-multi-level-sidebar" type="button"
    class="inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
    <span class="sr-only">Open sidebar</span>
    <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path clip-rule="evenodd" fill-rule="evenodd"
        d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z">
      </path>
    </svg>
  </button>

  <aside id="sidebar-multi-level-sidebar" *ngIf="authService.isAuth"
    class="absolute top-0 left-0 z-40 w-72 mt-40 h-full transition-transform -translate-x-full sm:translate-x-0"
    aria-label="Sidebar">
    <div class="h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800">
      <div *ngIf="!authService.isBuyer && !authService.isTemp">
        <ul class="space-y-2 font-medium" *ngIf="!authService.isAcManager">
          <li>
            <a href="https://iks.kilimo.go.tz/mkilimo/switchboard"
              class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
              <i
                class="fa fa-th flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"></i>
              <span class="ml-3">Switchboard</span>
            </a>
          </li>
          <li>
            <a href="stock-in-warehouse"
              class="flex items-center p-2 {{ (urlContains('/stock-in-warehouse')) ? 'text-sky-600 bg-sky-100/50 font-bold' : 'text-gray-900 font-normal' }} rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
              <i
                class="fa fa-home flex-shrink-0 w-5 h-auto text-inherit transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"></i>
              <span class="ml-3">Storage</span>
            </a>
          </li>

          <li>
            <a href="stock-in-market"
              class="flex items-center p-2 {{ (urlContains('/stock-in-market')) ? 'text-sky-600 bg-sky-100/50 font-bold' : 'text-gray-900 font-normal' }} rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
              <i
                class="fa fa-store flex-shrink-0 w-5 h-auto text-inherit transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"></i>
              <span class="ml-3">Trading</span>
            </a>
          </li>

          <li>
            <a href="stock-movement"
              class="flex items-center p-2 {{ (urlContains('/stock-movement')) ? 'text-sky-600 bg-sky-100/50 font-bold' : 'text-gray-900 font-normal' }} rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
              <i
                class="fa fa-inventory w-5 h-auto text-inherit transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"></i>
              <span class="ml-3">Movement</span>
            </a>
          </li>
        </ul>
        <ul class="pt-4 mt-4 space-y-2 font-medium border-t border-gray-200 dark:border-gray-700">
          <li *ngIf="authService.isAllowed('manage-ac')"
            class=" {{ (urlContains('/manage-ac/ac-registration') || urlContains('/manage-ac/produces') || urlContains('/manage-ac/order-produces') || urlContains('/manage-ac/summaries') || urlContains('/manage-ac/aggregated-produces')) ? 'rounded-lg bg-gray-100 hover:bg-gray-200/90 dark:bg-gray-700 dark:hover:bg-gray-700/90 font-bold' : '' }}">
            <a type="button"
              class="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700 dark:bg-gray-800"
              aria-controls="dropdown-aggregation" data-collapse-toggle="dropdown-aggregation">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 21v-7.5a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349M3.75 21V9.349m0 0a3.001 3.001 0 0 0 3.75-.615A2.993 2.993 0 0 0 9.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 0 0 2.25 1.016c.896 0 1.7-.393 2.25-1.015a3.001 3.001 0 0 0 3.75.614m-16.5 0a3.004 3.004 0 0 1-.621-4.72l1.189-1.19A1.5 1.5 0 0 1 5.378 3h13.243a1.5 1.5 0 0 1 1.06.44l1.19 1.189a3 3 0 0 1-.621 4.72M6.75 18h3.75a.75.75 0 0 0 .75-.75V13.5a.75.75 0 0 0-.75-.75H6.75a.75.75 0 0 0-.75.75v3.75c0 .414.336.75.75.75Z" />
              </svg>              
              <span class="flex-1 ml-3 text-left whitespace-nowrap">AC Management</span>
              <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="m1 1 4 4 4-4" />
              </svg>
            </a>
            <ul id="dropdown-aggregation" 
            class="{{ (urlContains('/manage-ac/ac-registration') || urlContains('/manage-ac/produces') || urlContains('/manage-ac/order-produces') || 
            urlContains('/manage-ac/summaries') || urlContains('/manage-ac/aggregated-produces') || urlContains('/manage-ac/reports')) ? '' : 'hidden' }} 
            py-2 space-y-2 pl-4 dark:bg-inherit">
              <li>
                <a mat-list-item
                  class="flex items-center w-full p-2 {{ (urlContains('/manage-ac/summaries')) ? 'text-sky-600 bg-sky-100/50 font-bold' : 'text-gray-900 font-normal' }} transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  href="manage-ac/summaries">
                  Summaries
                </a>
              </li>
              <li>
                <a mat-list-item
                  class="flex items-center w-full p-2 {{ (urlContains('/manage-ac/ac-registration')) ? 'text-sky-600 bg-sky-100/50 font-bold' : 'text-gray-900 font-normal' }} transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  href="manage-ac/ac-registration">
                  AC Registration
                </a>
              </li>
              <li>
                <a mat-list-item
                  class="flex items-center w-full p-2 {{ (urlContains('/manage-ac/produces')) ? 'text-sky-600 bg-sky-100/50 font-bold' : 'text-gray-900 font-normal' }} transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  href="manage-ac/produces">
                  Produce
                </a>
              </li>
              <li>
                <a mat-list-item
                  class="flex items-center w-full p-2 {{ (urlContains('/manage-ac/order-produces')) ? 'text-sky-600 bg-sky-100/50 font-bold' : 'text-gray-900 font-normal' }} transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  href="manage-ac/order-produces">
                  Orders
                </a>
              </li>
              <li class="border-t-2 border-gray-100 dark:border-gray-600">
                <a mat-list-item
                  class="flex items-center w-full p-2 {{ (urlContains('/manage-ac/aggregated-produces')) ? 'text-sky-600 bg-sky-100/50 font-bold' : 'text-gray-900 font-normal' }} transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  href="manage-ac/aggregated-produces">
                  Aggregated Amount
                </a>
              </li>
              
              <li class="border-t-2 border-gray-100 dark:border-gray-600">
                <a mat-list-item
                  class="flex items-center w-full p-2 {{ (urlContains('/manage-ac/reports')) ? 'text-sky-600 bg-sky-100/50 font-bold' : 'text-gray-900 font-normal' }} transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  href="manage-ac/reports">
                  Reports
                </a>
              </li>
            </ul>
          </li>
          <li *ngIf="authService.isAllowed('verify-permit') || authService.isAllowed('manage-traders')"
            class=" {{ (urlContains('/manage-permits/pending') || urlContains('/manage-permits/verified-permits') || urlContains('/manage-permits/returned-permits') || urlContains('/manage-permits/rejected-permits') || urlContains('/managements/traders') || urlContains('/trader-summaries')) ? 'rounded-lg bg-gray-100 dark:bg-gray-700 hover:bg-gray-200/90 dark:hover:bg-gray-700/50 font-bold' : '' }}">
            <a type="button"
              class="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
              aria-controls="dropdown-permits" data-collapse-toggle="dropdown-permits">
              <svg
                class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                <path
                  d="M17 5.923A1 1 0 0 0 16 5h-3V4a4 4 0 1 0-8 0v1H2a1 1 0 0 0-1 .923L.086 17.846A2 2 0 0 0 2.08 20h13.84a2 2 0 0 0 1.994-2.153L17 5.923ZM7 9a1 1 0 0 1-2 0V7h2v2Zm0-5a2 2 0 1 1 4 0v1H7V4Zm6 5a1 1 0 1 1-2 0V7h2v2Z" />
              </svg>
              <span class="flex-1 ml-3 text-left whitespace-nowrap">Traders Management</span>
              <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="m1 1 4 4 4-4" />
              </svg>
            </a>
            <ul id="dropdown-permits" class="{{ (urlContains('/manage-permits/pending') || urlContains('/manage-permits/verified-permits') || urlContains('/manage-permits/returned-permits') || urlContains('/manage-permits/rejected-permits') || urlContains('/managements/traders') || urlContains('/trader-summaries')) ? '' : 'hidden' }} py-2 space-y-2 pl-4">
              <li *ngIf="authService.isAllowed('verify-permit')">
                <a mat-list-item
                  class="flex items-center w-full p-2  {{ (urlContains('/trader-summaries')) ? 'text-sky-600 bg-sky-100/50 font-bold' : 'text-gray-900 font-normal' }} transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  href="trader-summaries">
                  Summaries
                </a>
              </li>
              <li *ngIf="authService.isAllowed('verify-permit')">
                <a mat-list-item
                  class="flex items-center w-full p-2  {{ (urlContains('/manage-permits/pending')) ? 'text-sky-600 bg-sky-100/50 font-bold' : 'text-gray-900 font-normal' }} transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  href="manage-permits/pending">
                  Pending
                </a>
              </li>
              <li *ngIf="authService.isAllowed('verify-permit')">
                <a mat-list-item href="manage-permits/returned-permits"
                  class="flex items-center w-full p-2  {{ (urlContains('/manage-permits/returned-permits')) ? 'text-sky-600 bg-sky-100/50 font-bold' : 'text-gray-900 font-normal' }} transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">
                  Returned
                </a>
              </li>
              <li *ngIf="authService.isAllowed('verify-permit')">
                <a mat-list-item href="manage-permits/verified-permits"
                  class="flex items-center w-full p-2  {{ (urlContains('/manage-permits//verified-permits')) ? 'text-sky-600 bg-sky-100/50 font-bold' : 'text-gray-900 font-normal' }} transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">
                  Verified
                </a>
              </li>
              <li *ngIf="authService.isAllowed('verify-permit')">
                <a mat-list-item href="manage-permits/rejected-permits"
                  class="flex items-center w-full p-2  {{ (urlContains('/manage-permits/rejected-permits')) ? 'text-sky-600 bg-sky-100/50 font-bold' : 'text-gray-900 font-normal' }} transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">
                  Rejected
                </a>
              </li>
              <li *ngIf="authService.isAllowed('manage-traders')" class="hidden">
                <a mat-list-item href="managements/traders/buyer"
                  class="flex items-center w-full p-2  {{ (urlContains('/managements/traders/buyer')) ? 'text-sky-600 bg-sky-100/50 font-bold' : 'text-gray-900 font-normal' }} transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">
                  Buyers
                </a>
              </li>
              <li *ngIf="authService.isAllowed('manage-traders')" class="hidden">
                <a mat-list-item href="managements/traders/seller"
                  class="flex items-center w-full p-2  {{ (urlContains('/managements/traders/seller')) ? 'text-sky-600 bg-sky-100/50 font-bold' : 'text-gray-900 font-normal' }} transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">
                  Sellers
                </a>
              </li>
              <li *ngIf="authService.isAllowed('manage-traders')" class="hidden">
                <a mat-list-item href="managements/traders/transporter"
                  class="flex items-center w-full p-2  {{ (urlContains('/managements/traders/transporter')) ? 'text-sky-600 bg-sky-100/50 font-bold' : 'text-gray-900 font-normal' }} transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">
                  Transporters
                </a>
              </li>
              <li *ngIf="authService.isAllowed('manage-traders')">
                <a mat-list-item href="managements/traders/all"
                  class="flex items-center w-full p-2  {{ (urlContains('/managements/traders/all')) ? 'text-sky-600 bg-sky-100/50 font-bold' : 'text-gray-900 font-normal' }} transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">
                  Traders List
                </a>
              </li>
            </ul>
          </li>
          <!-- <li *ngIf="authService.isAllowed('manage-traders')">
            <a href="managements/traders"
              class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
              <svg
                class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                <path
                  d="M17 5.923A1 1 0 0 0 16 5h-3V4a4 4 0 1 0-8 0v1H2a1 1 0 0 0-1 .923L.086 17.846A2 2 0 0 0 2.08 20h13.84a2 2 0 0 0 1.994-2.153L17 5.923ZM7 9a1 1 0 0 1-2 0V7h2v2Zm0-5a2 2 0 1 1 4 0v1H7V4Zm6 5a1 1 0 1 1-2 0V7h2v2Z" />
              </svg>
              <span class="flex-1 ml-3 whitespace-nowrap">Traders</span>
            </a>
          </li> -->
          <li *ngIf="authService.isAllowed('receive-in-warehouse')"
          class="{{ (urlContains('/warehouse-summaries') || urlContains('/managements/warehouses') || urlContains('/managements/warehouses/receive-commodities') || urlContains('/managements/warehouses/released-commodities')) ? 'rounded-lg bg-gray-100 dark:bg-gray-700 hover:bg-gray-200/90 dark:hover:bg-gray-700/50 font-bold' : '' }}">
            <a type="button"
              class="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
              aria-controls="dropdown-warehouse" data-collapse-toggle="dropdown-warehouse">
              <i class="fa fa-house-flood flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
                aria-hidden="true" fill="currentColor">
              </i>
              <span class="flex-1 ml-3 text-left whitespace-nowrap">Warehouses</span>
              <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="m1 1 4 4 4-4" />
              </svg>
            </a>
            <ul id="dropdown-warehouse" class="{{ (urlContains('/warehouse-summaries') || urlContains('/managements/warehouses') || urlContains('/managements/warehouses/receive-commodities') || urlContains('/managements/warehouses/released-commodities')) ? '' : 'hidden' }} py-2 space-y-2 pl-4">
              <li *ngIf="authService.isAllowed('create-warehouse')">
                <a href="warehouse-summaries"
                  class="flex items-center w-full p-2 {{ (urlContains('/warehouse-summaries')) ? 'text-sky-600 bg-sky-100/50 font-bold' : 'text-gray-900 font-normal' }} transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">Summaries</a>
              </li>
              <li *ngIf="authService.isAllowed('create-warehouse')">
                <a href="managements/warehouses"
                  class="flex items-center w-full p-2 {{ (urlContains('/managements/warehouses')) ? 'text-sky-600 bg-sky-100/50 font-bold' : 'text-gray-900 font-normal' }} transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">Register</a>
              </li>
              <li>
                <a *ngIf="authService.isAllowed('receive-in-warehouse')" mat-list-item
                  class="flex items-center w-full p-2 {{ (urlContains('/managements/warehouses/receive-commodities')) ? 'text-sky-600 bg-sky-100/50 font-bold' : 'text-gray-900 font-normal' }} transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  href="managements/warehouses/receive-commodities">
                  Receive Stock
                </a>
              </li>
              <li>
                <a mat-list-item href="managements/warehouses/released-commodities"
                  class="flex items-center w-full p-2 {{ (urlContains('/managements/warehouses/released-commodities')) ? 'text-sky-600 bg-sky-100/50 font-bold' : 'text-gray-900 font-normal' }} transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">
                  Release Stock
                </a>
              </li>
            </ul>
          </li>
          <li *ngIf="authService.isAllowed('receive-in-market')"
          class="{{ (urlContains('/market-summaries') || urlContains('/managements/markets') || urlContains('/managements/markets/receive-commodities') || urlContains('/managements/markets/released-commodities') || urlContains('/managements/markets/market-prices')) ? 'rounded-lg bg-gray-100 dark:bg-gray-700 hover:bg-gray-200/90 dark:hover:bg-gray-700/50 font-bold' : '' }}">
            <a type="button"
              class="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
              aria-controls="dropdown-market" data-collapse-toggle="market-dropdown">
              <svg
                class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
                aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 21">
                <path
                  d="M15 12a1 1 0 0 0 .962-.726l2-7A1 1 0 0 0 17 3H3.77L3.175.745A1 1 0 0 0 2.208 0H1a1 1 0 0 0 0 2h.438l.6 2.255v.019l2 7 .746 2.986A3 3 0 1 0 9 17a2.966 2.966 0 0 0-.184-1h2.368c-.118.32-.18.659-.184 1a3 3 0 1 0 3-3H6.78l-.5-2H15Z" />
              </svg>
              <span class="flex-1 ml-3 text-left whitespace-nowrap">Markets</span>
              <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="m1 1 4 4 4-4" />
              </svg>
            </a>
            <ul id="market-dropdown" class="{{ (urlContains('/market-summaries') || urlContains('/managements/markets') || urlContains('/managements/markets/receive-commodities') || urlContains('/managements/markets/released-commodities') || urlContains('/managements/markets/market-prices')) ? '' : 'hidden' }} py-2 space-y-2 pl-4">
              <li *ngIf="authService.isAllowed('create-market')">
                <a href="market-summaries"
                  class="flex items-center w-full p-2 {{ (urlContains('/market-summaries')) ? 'text-sky-600 bg-sky-100/50 font-bold' : 'text-gray-900 font-normal' }} transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">Summaries</a>
              </li>
              <li *ngIf="authService.isAllowed('create-market')">
                <a href="managements/markets"
                  class="flex items-center w-full p-2 {{ (urlContains('/managements/markets')) ? 'text-sky-600 bg-sky-100/50 font-bold' : 'text-gray-900 font-normal' }} transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">Register</a>
              </li>
              <li>
                <a *ngIf="authService.isAllowed('receive-in-market')" mat-list-item
                  class="flex items-center w-full p-2 {{ (urlContains('/managements/markets/receive-commodities')) ? 'text-sky-600 bg-sky-100/50 font-bold' : 'text-gray-900 font-normal' }} transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  href="managements/markets/receive-commodities">
                  Receive Stock
                </a>
              </li>
              <li>
                <a mat-list-item href="managements/markets/released-commodities"
                  class="flex items-center w-full p-2 {{ (urlContains('/managements/markets/released-commodities')) ? 'text-sky-600 bg-sky-100/50 font-bold' : 'text-gray-900 font-normal' }} transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">
                  Release Stock
                </a>
              </li>
              <li>
                <a mat-list-item href="managements/markets/market-prices"
                  class="flex items-center w-full p-2 {{ (urlContains('/managements/markets/market-prices')) ? 'text-sky-600 bg-sky-100/50 font-bold' : 'text-gray-900 font-normal' }} transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">
                  Market Prices
                </a>
              </li>
            </ul>
          </li>
          <li *ngIf="authService.isAllowed('manage-checkpoints')"
          class="{{ (urlContains('/managements/cross-points') || urlContains('/managements/cross-points/data-cross-points')) ? 'rounded-lg bg-gray-100 dark:bg-gray-700 hover:bg-gray-200/90 dark:hover:bg-gray-700/50 font-bold' : '' }}">
            <a type="button"
              class="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
              aria-controls="dropdown-cross-points" data-collapse-toggle="cross-points">
              <i
                class="fa fa-car flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white">
              </i>
              <span class="flex-1 ml-3 text-left whitespace-nowrap">Cross Points</span>
              <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="m1 1 4 4 4-4" />
              </svg>
            </a>
            <ul id="cross-points" class="{{ (urlContains('/managements/cross-points') || urlContains('/managements/cross-points/data-cross-points')) ? '' : 'hidden' }} py-2 space-y-2 pl-4">
              <li *ngIf="authService.isAllowed('manage-checkpoints')">
                <a href="managements/cross-points"
                  class="flex items-center w-full p-2 {{ (urlContains('/managements/cross-points')) ? 'text-sky-600 bg-sky-100/50 font-bold' : 'text-gray-900 font-normal' }} transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">Register</a>
              </li>
              <li>
                <a *ngIf="authService.isAllowed('manage-checkpoints')" mat-list-item
                  class="flex items-center w-full p-2 {{ (urlContains('/managements/cross-points/data-cross-points')) ? 'text-sky-600 bg-sky-100/50 font-bold' : 'text-gray-900 font-normal' }} transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  href="managements/cross-points/data-cross-points">
                  Record Stock
                </a>
              </li>
            </ul>
          </li>
          <li *ngIf="authService.isAllowed('full-access')"
          class="{{ (urlContains('/managements/crops') || urlContains('/managements/unit-of-measure')) ? 'rounded-lg bg-gray-100 dark:bg-gray-700 hover:bg-gray-200/90 dark:hover:bg-gray-700/50 font-bold' : '' }}">
            <a type="button"
              class="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
              aria-controls="dropdown-settings" data-collapse-toggle="settings">
              <i
                class="fa fa-cogs flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white">

              </i>
              <span class="flex-1 ml-3 text-left whitespace-nowrap">Settings</span>
              <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="m1 1 4 4 4-4" />
              </svg>
            </a>
            <ul id="settings" class="{{ (urlContains('/managements/crops') || urlContains('/managements/unit-of-measure')) ? '' : 'hidden' }} py-2 space-y-2 pl-4">
              <li *ngIf="authService.isAllowed('full-access')">
                <a href="managements/crops"
                  class="flex items-center w-full p-2 {{ (urlContains('/managements/crops')) ? 'text-sky-600 bg-sky-100/50 font-bold' : 'text-gray-900 font-normal' }} transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">Crops</a>
              </li>
              <li>
                <a *ngIf="authService.isAllowed('full-access')" mat-list-item
                  class="flex items-center w-full p-2 {{ (urlContains('/managements/unit-of-measure')) ? 'text-sky-600 bg-sky-100/50 font-bold' : 'text-gray-900 font-normal' }} transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  href="managements/unit-of-measure">
                  Unit Measure
                </a>
              </li>
            </ul>
          </li>
          <li *ngIf="authService.isAllowed('manage-users')"
          class="{{ (urlContains('/managements/users') || urlContains('/managements/user-roles')) ? 'rounded-lg bg-gray-100 dark:bg-gray-700 hover:bg-gray-200/90 dark:hover:bg-gray-700/50 font-bold' : '' }}">
            <a type="button"
              class="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
              aria-controls="dropdown-example" data-collapse-toggle="users">
              <svg
                class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                <path
                  d="M14 2a3.963 3.963 0 0 0-1.4.267 6.439 6.439 0 0 1-1.331 6.638A4 4 0 1 0 14 2Zm1 9h-1.264A6.957 6.957 0 0 1 15 15v2a2.97 2.97 0 0 1-.184 1H19a1 1 0 0 0 1-1v-1a5.006 5.006 0 0 0-5-5ZM6.5 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM8 10H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Z" />
              </svg>
              <span class="flex-1 ml-3 text-left whitespace-nowrap">Users</span>
              <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="m1 1 4 4 4-4" />
              </svg>
            </a>
            <ul id="users" class="{{ (urlContains('/managements/users') || urlContains('/managements/user-roles')) ? '' : 'hidden' }} py-2 space-y-2 pl-4">
              <li *ngIf="authService.isAllowed('full-access')">
                <a href="managements/users"
                  class="flex items-center w-full p-2 {{ (urlContains('/managements/users')) ? 'text-sky-600 bg-sky-100/50 font-bold' : 'text-gray-900 font-normal' }} transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">Manage
                  Users</a>
              </li>
              <li>
                <a *ngIf="authService.isAllowed('full-access')" mat-list-item
                  class="flex items-center w-full p-2 {{ (urlContains('/managements/user-roles')) ? 'text-sky-600 bg-sky-100/50 font-bold' : 'text-gray-900 font-normal' }} transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  href="managements/user-roles">
                  Roles
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div *ngIf="authService.isBuyer ||  authService.isTemp">
        <ul class="space-y-2 font-medium">
          <li *ngIf="authService.isBuyer" class="hidden">
            <a href="buyers-portal/profile"
              class="{{ urlContains('/buyers-portal/profile') ? 'bg-gray-200 hover:bg-gray-200/90' : '' }} flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
              <i
                class="fa fa-user flex-shrink-0 w-5 h-3 text-inherit transition duration-75 dark:text-gray-400 dark:group-hover:text-white"></i>
              <span class="ml-3">{{ 'my_profile'|translate }}</span>
            </a>
          </li>
          <li>
            <a href="buyers-portal"
              class="{{ ((urlContains('/buyers-portal') || urlContains('/buyers-portal/application-info')) && !urlContains('/buyers-portal/my-permits') && !urlContains('/buyers-portal/profile'))? 'bg-gray-200 hover:bg-gray-200/90' : '' }} flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
              <i
                class="fa fa-home flex-shrink-0 w-5 h-3 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"></i>
              <span class="ml-3">{{ 'applications'|translate }}</span>
            </a>
          </li>
          <li class="items-center" *ngIf="authService.isBuyer && authService.isApproved">
            <a href="buyers-portal/my-permits"
              class="{{ urlContains('/buyers-portal/my-permits') ? 'bg-gray-200 hover:bg-gray-200/90' : '' }} flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
              <i
                class="fa fa-file-pdf flex-shrink-0 w-5 h-3 text-inherit transition duration-75 dark:text-gray-400 dark:group-hover:text-white"></i>
              <span class="ml-3">{{ 'my_registration'|translate }}</span>
            </a>
          </li>
          <li class="items-center hidden" *ngIf="authService.isBuyer && authService.isApproved">
            <a href="buyers-portal/permits"
              class="{{ urlContains('/buyers-portal/permits') ? 'bg-gray-200 hover:bg-gray-200/90' : '' }} flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
              <i
                class="fa fa-exchange flex-shrink-0 w-5 h-3 text-inherit transition duration-75 dark:text-gray-400 dark:group-hover:text-white"></i>
              <span class="ml-3">{{ 'transactions'|translate }}</span>
            </a>
          </li>
        </ul>
      </div>

      <ul class="pt-4 mt-4 space-y-2 font-medium border-t border-gray-200 dark:border-gray-700">
        <li *ngIf="authService.isAllowed('verify-permit')"
          class=" {{ (urlContains('/buyer-reports/registration') || urlContains('/buyer-reports/buyer-crop')) ? 'rounded-lg bg-gray-100 hover:bg-gray-200/90' : '' }}">
          <a type="button"
            class="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
            aria-controls="dropdown-buyer-reports" data-collapse-toggle="dropdown-buyer-reports">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="size-6" class="w-5 h-5">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 0 1 3 19.875v-6.75ZM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V8.625ZM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V4.125Z" />
            </svg>

            <span class="flex-1 ml-3 text-left whitespace-nowrap">Buyers Reports</span>
            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="m1 1 4 4 4-4" />
            </svg>
          </a>
          <ul id="dropdown-buyer-reports" class="hidden py-2 space-y-2 pl-4">
            <li>
              <a mat-list-item
                class="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                href="buyer-reports/registration">
                Applications
              </a>
            </li>
            <li>
              <a mat-list-item href="buyer-reports/buyer-crop"
                class="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">
                Crops
              </a>
            </li>
          </ul>
        </li>
      </ul>

    </div>
  </aside>

  <div class="md:p-8 sm:ml-64">
    <div class="p-2 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700">


      <div id="content" *ngIf="authService.isAuth">

        <div id="content-header">
          <h1 class="text-uppercase">{{ title }}</h1>
        </div>

        <div id="content-container">
          <div class="row">
            <div class="col-lg-12">
              <ol class="breadcrumb">
                <li><a href=""><i class="fa fa-home"></i> {{ 'dashboard'|translate }}</a></li>
                <li class="active" *ngIf="menu"> / {{ menu }}</li>
              </ol>
              <router-outlet></router-outlet>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!authService.isAuth">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>

  <app-footer *ngIf="authService.isAuth"></app-footer>

</ng-container>