import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


const baseUrl = environment.baseUrl;
const url1 = baseUrl + '/cross-point';
const url2 = baseUrl + '/cross-point-data';
const url3 = baseUrl + '/cross-point-filter-by-season';

@Injectable({
  providedIn: 'root'
})
export class CrossPointsService {

  headers = new HttpHeaders({
    'Content-Type': 'application/json',
     'X-Requested-Width': 'XMLHttpRequeest',
    'Access-Control-Allow-Origin': '*'
   });
  constructor(private http: HttpClient) { }

  getCollectionPoints() {
    return this.http.get(url1, {headers: this.headers});
  }

  getCrossPointSingleData(id) {
    return this.http.get(url1 + '/' + id, {headers: this.headers});
  }

  addCrossPoint(data) {
    return this.http.post(url1, data, {headers: this.headers});
  }
  updateCrossPoint(data, id) {
    return this.http.put(url1 + '/' + id, data,  {headers: this.headers});
  }

  deleteCrossPoint(id) {
    return this.http.delete(url1 + '/' + id, {headers: this.headers});
  }

  getCrossPointsdata() {
    return this.http.get(url2, {headers: this.headers});
  }

  getCrossPointSingleDataRecord(id) {
    return this.http.get(url2 + '/' + id, {headers: this.headers});
  }

  addCrossPointData(data) {
    return this.http.post(url2, data, {headers: this.headers});
  }
  updateCrossPointData(data, id) {
    return this.http.put(url2 + '/' + id, data,  {headers: this.headers});
  }

  deleteCrossPointData(id) {
    return this.http.delete(url2 + '/' + id, {headers: this.headers});
  }

  filterCrossPointsBySeason(data) {
    return this.http.post(url3, data, {headers: this.headers});
  }
}
