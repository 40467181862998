<form *ngIf="data.action == 'add'" [formGroup]="withdrawForm" (ngSubmit)="onSubmit()">
    <div class="title-holder">
        <h2 *ngIf="data.origin == 'warehouse'" class="title">Release Commodities From Warehouse</h2>
        <h2 *ngIf="data.origin == 'market'" class="title">Release Commodities from Primary Markets</h2>
    </div>
    <!--<div class="row">
        <div [ngClass]="decreaseSize1 ? 'col-md-6 space1' : 'col-md-12 space'">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>From</mat-label>
                <mat-select placeholder="Select Crop" (selectionChange)="selectOrigin($event.value)" required>
                    <mat-option value="market">Market</mat-option>
                    <mat-option value="warehouse">Warehouse</mat-option>
                </mat-select>
            </mat-form-field>
        </div> -->
        <div class="col-md-12 column" *ngIf="data.origin == 'warehouse'">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>select origin warehouses</mat-label>
                <mat-select placeholder="Select warehouse" formControlName="origin_warehouse" required (selectionChange)="getVillages($event.value)">
                    <mat-option *ngFor="let warehouse of warehouses" [value]="warehouse.id">{{warehouse.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="withdrawForm.get('origin_warehouse').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-12 column" *ngIf="data.origin == 'market'">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Select origin markets</mat-label>
                <mat-select placeholder="Select market" formControlName="origin_market" required>
                    <mat-option *ngFor="let market of primaryMarkets" [value]="market.id">{{market.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="withdrawForm.get('origin_market').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
    <!--</div> -->
    <div class="row">
        <div class="col-md-6 space1">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Crop</mat-label>
                <mat-select placeholder="Select Crop" formControlName="crop_id" required>
                    <mat-option *ngFor="let crop of crops" [value]="crop.id">{{crop.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="withdrawForm.get('crop_id').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-6 space3">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Quality/Grade</mat-label>
                <mat-select placeholder="Select quality" formControlName="quality">
                    <mat-option value="high">High</mat-option>
                    <mat-option value="moderate">Moderate</mat-option>
                    <mat-option value="low">Low</mat-option>
                    <mat-option value="unknown">Unknown</mat-option>
                </mat-select>
                <mat-error *ngIf="withdrawForm.get('quality').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 space1">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Quantity (Kg)</mat-label>
                <input matInput type="number" placeholder="Quantity (Kg)" formControlName="quantity" required>
                <mat-error *ngIf="withdrawForm.get('quantity').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-6 space3">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Buying Price (TZs)</mat-label>
                <input matInput type="number" placeholder="Buying Price (TZs)" formControlName="buying_price">
                <mat-error *ngIf="withdrawForm.get('buying_price').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="col-md-12 column">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Price Unit</mat-label>
            <mat-select placeholder="Select Unit" formControlName="price_unit">
                <mat-option *ngFor="let unit of units" [value]="unit.id">{{unit.uom}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="row">
        <div [ngClass]="isProcessed ? 'col-md-6 space1' : 'col-md-12 space'">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Crop Processed?</mat-label>
                <mat-select placeholder="Select option" formControlName="processed" required (selectionChange)="cropProcessed($event.value)">
                    <mat-option value="yes">Yes</mat-option>
                    <mat-option value="no">No</mat-option>
                </mat-select>
                <mat-error *ngIf="withdrawForm.get('processed').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-6 space3" *ngIf="isProcessed">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>End product</mat-label>
                <input matInput placeholder="after_processed" formControlName="after_processed">
                <mat-error *ngIf="withdrawForm.get('after_processed').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row column">
        <div class="col-md-6 space1">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Buyer Name</mat-label>
                <mat-select placeholder="Select buyer" formControlName="trader_id" required>
                    <mat-option *ngFor="let buyer of buyers" [value]="buyer.id">{{buyer.user?.full_name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="withdrawForm.get('trader_id').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-6 space3">
          <div class="mb-2">Select destination</div>
          <mat-radio-group aria-label="Select destination" (change)="showDestination($event)">
            <mat-radio-button *ngIf="isDomestic" class="mat-radio-checked" value="1" selected>Domestic</mat-radio-button>
            <mat-radio-button *ngIf="!isDomestic" value="1" selected>Domestic</mat-radio-button>
            <mat-radio-button value="2">International</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="col-md-12 column" *ngIf="!isDomestic">
          <mat-form-field class="w-100" appearance="outline">
              <mat-label>Country</mat-label>
              <mat-select placeholder="Select Region" formControlName="country_id" (selectionChange)="changeValidation($event.value)">
                  <mat-option *ngFor="let country of countries" [value]="country.id">{{country.country_name}}</mat-option>
              </mat-select>
              <mat-error *ngIf="withdrawForm.get('country_id').invalid">{{getErrorMessage()}}</mat-error>
          </mat-form-field>
      </div>
    <div *ngIf="isDomestic">
        <div class="row column">
            <div class="col-md-6 space1">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Regions</mat-label>
                    <mat-select placeholder="Select Region" formControlName="region_id" [required]="isRequired" (selectionChange)="getDistricts($event.value)">
                        <mat-option *ngFor="let region of regions" [value]="region.id">{{region.name}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="withdrawForm.get('region_id').invalid">{{getErrorMessage()}}</mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6 space3">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Districts</mat-label>
                    <mat-select placeholder="Select District" formControlName="district_id" [required]="isRequired">
                        <mat-option *ngFor="let district of districts" [value]="district.id">{{district.name}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="withdrawForm.get('district_id').invalid">{{getErrorMessage()}}</mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="col-md-12 column">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Utilization</mat-label>
            <mat-select placeholder="Select Utilization" formControlName="usage" required (selectionChange)="checkDestination($event.value)">
                <mat-option value="human food">Human food</mat-option>
                <mat-option value="animal food">Animal food</mat-option>
                <mat-option value="raw material">Raw material</mat-option>
                <mat-option value="export">export</mat-option>
                <mat-option value="warehouse">To warehouse</mat-option>
                <mat-option value="market">To market</mat-option>
            </mat-select>
            <mat-error *ngIf="withdrawForm.get('usage').invalid">{{getErrorMessage()}}</mat-error>
        </mat-form-field>
    </div>
    <div class="row">
        <div class="col-md-6 space1">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>CESS Payment (TZs)</mat-label>
                <input matInput type="number" placeholder="CESS Payment (TZs) (Tzs)" formControlName="cess_payment">
            </mat-form-field>
        </div>
        <div class="col-md-6 space3">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>CESS Unit</mat-label>
                <mat-select placeholder="Select Unit" formControlName="cess_unit">
                    <mat-option *ngFor="let unit of units" [value]="unit.id">{{unit.uom}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row column">
        <div class="col-md-12 space1" *ngIf="showWarehouse">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>warehouses</mat-label>
                <mat-select placeholder="Select warehouse" formControlName="warehouse_id" required (selectionChange)="getVillages($event.value)">
                    <mat-option *ngFor="let warehouse of warehouses" [value]="warehouse.id">{{warehouse.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="withdrawForm.get('warehouse_id').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-12 space1" *ngIf="showMarket">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>markets</mat-label>
                <mat-select placeholder="Select market" formControlName="market_id" required>
                    <mat-option *ngFor="let market of secondaryMarkets" [value]="market.id">{{market.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="withdrawForm.get('market_id').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
        <!-- <div [ngClass]="decreaseSize ? 'col-md-6 space3' : 'col-md-12 space'">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>CESS Payment (TZs) (Tzs)</mat-label>
                <input matInput type="number" placeholder="CESS Payment (TZs)" formControlName="cess_payment">
            </mat-form-field>
        </div> -->
    </div>
    <div class="col-md-12 column">
        <mat-form-field class="w-100" appearance="fill">
            <mat-label>Received Date (13/1/2001)</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="date" required>
            <mat-datepicker-toggle matSuffix [for]="picker">
                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="withdrawForm.get('date').invalid">{{getErrorMessage()}}</mat-error>
        </mat-form-field>
    </div>
    <div style="margin-top: 30px; display: flex; flex-direction: row; justify-content: flex-end;">
        <button (click)="onNoClick()" class="cancelbtn" mat-button>Cancel</button>

        <button type="submit" class="createbtn" mat-button>Submit</button>
    </div>
</form>

<!-- Update market -->
<form *ngIf="data.action == 'edit'" [formGroup]="editForm" (ngSubmit)="onUpdate()">
    <div class="title-holder">
        <h2 class="title">Withdraw Commodities</h2>
    </div>
    <div class="row">
        <div class="col-md-6 space1">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Crop</mat-label>
                <mat-select placeholder="Select Crop" formControlName="crop_id" required>
                    <mat-option *ngFor="let crop of crops" [value]="crop.id">{{crop.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="editForm.get('crop_id').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-6 space3">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Quality/Grade</mat-label>
                <mat-select placeholder="Select quality" formControlName="quality" required>
                    <mat-option value="high">High</mat-option>
                    <mat-option value="moderate">Moderate</mat-option>
                    <mat-option value="low">Low</mat-option>
                    <mat-option value="unknown">Unknown</mat-option>
                </mat-select>
                <mat-error *ngIf="editForm.get('quality').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 space1">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Quantity (Kg)</mat-label>
                <input matInput type="number" placeholder="Quantity (Kg)" formControlName="quantity" required>
                <mat-error *ngIf="editForm.get('quantity').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-6 space3">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Buying Price (TZs)</mat-label>
                <input matInput type="number" placeholder="Buying Price (TZs)" formControlName="buying_price">
                <mat-error *ngIf="editForm.get('buying_price').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="col-md-12 column">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Price Unit</mat-label>
            <mat-select placeholder="Select Unit" formControlName="price_unit">
                <mat-option *ngFor="let unit of units" [value]="unit.id">{{unit.uom}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="row">
        <div [ngClass]="isProcessed ? 'col-md-6 space1' : 'col-md-12 space'">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Crop Processed?</mat-label>
                <mat-select placeholder="Select option" formControlName="processed" required (selectionChange)="cropProcessed($event.value)">
                    <mat-option value="yes">Yes</mat-option>
                    <mat-option value="no">No</mat-option>
                </mat-select>
                <mat-error *ngIf="editForm.get('processed').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-6 space3" *ngIf="isProcessed">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>End product</mat-label>
                <input matInput placeholder="after_processed" formControlName="after_processed">
                <mat-error *ngIf="editForm.get('after_processed').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row column">
        <div class="col-md-6 space1">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Buyer Name</mat-label>
                <mat-select placeholder="Select buyer" formControlName="trader_id" required>
                    <mat-option *ngFor="let buyer of buyers" [value]="buyer.id">{{buyer.user?.full_name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="editForm.get('trader_id').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-6 space3">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Country</mat-label>
                <mat-select placeholder="Select Region" formControlName="country_id" required (selectionChange)="changeValidation($event.value)">
                    <mat-option *ngFor="let country of countries" [value]="country.id">{{country.country_name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="editForm.get('country_id').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div *ngIf="isRequired">
        <div class="row column">
            <div class="col-md-6 space1">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Regions</mat-label>
                    <mat-select placeholder="Select Region" formControlName="region_id" [required]="isRequired" (selectionChange)="getDistricts($event.value)">
                        <mat-option *ngFor="let region of regions" [value]="region.id">{{region.name}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="editForm.get('region_id').invalid">{{getErrorMessage()}}</mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6 space3">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Districts</mat-label>
                    <mat-select placeholder="Select District" formControlName="district_id" [required]="isRequired">
                        <mat-option *ngFor="let district of districts" [value]="district.id">{{district.name}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="editForm.get('district_id').invalid">{{getErrorMessage()}}</mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 space1">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>CESS Payment (TZs)</mat-label>
                <input matInput type="number" placeholder="CESS Payment (TZs) (Tzs)" formControlName="cess_payment">
            </mat-form-field>
        </div>
        <div class="col-md-6 space3">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>CESS Unit</mat-label>
                <mat-select placeholder="Select Unit" formControlName="cess_unit">
                    <mat-option *ngFor="let unit of units" [value]="unit.id">{{unit.uom}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="col-md-12 column">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Utilization</mat-label>
            <mat-select placeholder="Select Utilization" formControlName="usage" required (selectionChange)="checkDestination($event.value)">
                <mat-option value="human food">Human food</mat-option>
                <mat-option value="animal food">Animal food</mat-option>
                <mat-option value="raw material">Raw material</mat-option>
                <mat-option value="export">export</mat-option>
                <mat-option value="warehouse">To warehouse</mat-option>
                <mat-option value="market">To market</mat-option>
            </mat-select>
            <mat-error *ngIf="editForm.get('usage').invalid">{{getErrorMessage()}}</mat-error>
        </mat-form-field>
    </div>
    <div class="row column">
        <div class="col-md-12 space1" *ngIf="showWarehouse">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>warehouses</mat-label>
                <mat-select placeholder="Select warehouse" formControlName="warehouse_id" required (selectionChange)="getVillages($event.value)">
                    <mat-option *ngFor="let warehouse of warehouses" [value]="warehouse.id">{{warehouse.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="editForm.get('warehouse_id').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-12 space1" *ngIf="showMarket">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>markets</mat-label>
                <mat-select placeholder="Select market" formControlName="market_id" required>
                    <mat-option *ngFor="let market of markets" [value]="market.id">{{market.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="editForm.get('market_id').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
        <!-- <div [ngClass]="decreaseSize ? 'col-md-6 space3' : 'col-md-12 space'">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>CESS Payment (TZs) (Tzs)</mat-label>
                <input matInput type="number" placeholder="CESS Payment (TZs)" formControlName="cess_payment">
            </mat-form-field>
        </div> -->
    </div>
    <div class="col-md-12 column">
        <mat-form-field class="w-100" appearance="fill">
            <mat-label>Received Date (13/1/2001)</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="date" required>
            <mat-datepicker-toggle matSuffix [for]="picker">
                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="editForm.get('date').invalid">{{getErrorMessage()}}</mat-error>
        </mat-form-field>
    </div>
    <div style="margin-top: 30px; display: flex; flex-direction: row; justify-content: flex-end;">
        <button (click)="onNoClick()" class="cancelbtn" mat-button>Cancel</button>

        <button type="submit" class="createbtn" mat-button>Submit</button>
    </div>
</form>
