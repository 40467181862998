import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ManagementService } from '../../management.service';
import { TradersService } from '../traders.service';

export interface DialogData {
  category;
}

@Component({
  selector: 'app-send-sms',
  templateUrl: './send-sms.component.html',
  styleUrls: ['./send-sms.component.css']
})
export class SendSmsComponent implements OnInit {

  traders;
  isLoading = false;

  constructor(
    public dialogRef: MatDialogRef<SendSmsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private manServ: ManagementService,
    private traderServ: TradersService,
    private snackBar: MatSnackBar,
  ) { }


  form = this.fb.group({
    id : ['', Validators.required],
    trader_id : ['', Validators.required],
    phone : ['', Validators.pattern(/^(255)[0-9]{9}$/)],
    message : ['', Validators.required],
  });

  ngOnInit(): void {
    this.getTraderData();
  }

  getTraderData() {
    this.manServ.getCategoryTraders(this.data.category).subscribe(
      data => {
        this.traders = data;
        this.isLoading = false;
      }
    );
  }

  getErrorMessage() {
    return 'This field is required';
  }

  getPhoneErrorMessage() {
    return 'Wrong phone pattern, please check the placeholder';
  }

  setPhone(trader) {
    
    this.form.patchValue({
      trader_id: trader.id,
      phone: trader.user.phone
    });
  }

  onSubmit() {
    if (this.form.valid) {
      this.isLoading = true;
      // console.log('valid');
      this.traderServ.sendMessage(this.form.value).subscribe(
        (resp: any) => {
          if(resp['success']) {
            this.snackBar.open('message', resp['success'], {
              duration: 5000
            });
            this.onNoClick();
          } else {
            this.snackBar.open('Error', resp['error'], {
              duration: 5000
            });
          }
        }, 
        error => {
          console.log(error);
          this.snackBar.open('', 'Error', {
            duration: 5000
          });
          this.isLoading = false;
        }
      );
    }
  }

  onNoClick() {
    this.dialogRef.close();
  }
}
