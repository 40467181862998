import { Component, Inject, OnInit } from '@angular/core';
import { ManagementService } from '../../../management.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { RegistrationService } from '../registration.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  id;
  action;
}

@Component({
  selector: 'app-ac-form',
  templateUrl: './ac-form.component.html',
  styleUrls: ['./ac-form.component.css'],
})
export class AcFormComponent implements OnInit {
  form: FormGroup;
  fileName: string | null = null;

  id;
  action;

  regions;
  districts;
  wards;
  villages;
  crops;
  loading = false;
  submitting = false;

  menu = 'Add aggregation centers';

  constructor(
    public dialogRef: MatDialogRef<AcFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    // public active: ActivatedRoute,
    public acRegServ: RegistrationService,
    public manServ: ManagementService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private route: Router
  ) {
    // active.url.subscribe((data) => {
    //   // console.log(data[2].path)
    //   this.id = data[2].path;
    //   if (this.id) {
    //     this.action = 'update';
    //   }
    // });
  }

  ngOnInit(): void {
    this.loading = true;
    this.form = this.fb.group({
      name: ['', Validators.required],
      crops: ['', Validators.required],
      crop_source: ['', Validators.required],
      capacity: ['', Validators.required],
      equipments: ['', Validators.required],
      attachment: [''],
      region_id: ['', Validators.required],
      district_id: ['', Validators.required],
      ward_id: [''],
      village_id: [''],
      latitude: [''],
      longitude: [''],
      services: this.fb.array([
        this.fb.control(''), // Start with one empty service input
      ]),
    });
    this.getAllRegions();
    this.getCrops();
    if (this.data.action == 'update') {
      this.acRegServ
        .getAggregationCenterSingleData(this.data.id)
        .subscribe((data: any) => {
          this.getDistricts(data.region_id);
          this.getWards(data.district_id);
          this.getVillages(data.ward_id);
          this.form.patchValue({
            name: data.name,
            crops: data.crop_ids,
            crop_source: data.crop_source,
            capacity: data.capacity,
            equipments: data.equipments,
            latitude: data.latitude,
            longitude: data.longitude,
            region_id: data.region_id,
            district_id: data.district_id,
            ward_id: data.ward_id,
            village_id: data.village_id,
            attachment: null, // Set attachment to null initially
          });

          // If there's an attachment, set the file name
          if (data.attachment) {
            this.fileName = data.attachment.replace('/storage/acenter/attachments/', '');
          }

          this.services.clear(); // Clear existing services
          // Add new services to the FormArray
          data.services_list.forEach((serviceName) => {
            this.services.push(this.fb.control(serviceName));
          });

          this.form.get('attachment').clearValidators();
        });
    } else {
      this.form.get('attachment').setValidators(Validators.required);
    }
  }

  getAllRegions() {
    this.manServ.getAllRegions().subscribe((data) => {
      this.regions = data;
      this.loading = false;
    });
  }

  getDistricts(regionId) {
    this.loading = true;

    this.manServ.getAllDistricts(regionId).subscribe((data) => {
      this.districts = data;
      this.loading = false;
    });
  }

  getWards(districtId) {
    this.loading = true;
    this.manServ.getWards(districtId).subscribe((data) => {
      this.wards = data;
      this.loading = false;
    });
  }

  getVillages(wardId) {
    this.loading = true;
    this.manServ.getVillages(wardId).subscribe((data) => {
      this.villages = data;
      this.loading = false;
    });
  }

  getCrops() {
    this.manServ.getCrops().subscribe((data) => {
      this.crops = data;
    });
  }

  get services(): FormArray {
    return this.form.get('services') as FormArray;
  }

  addService() {
    this.services.push(this.fb.control(''));
  }

  removeService(index: number) {
    this.services.removeAt(index);
  }

  onNoClick() {
    this.dialogRef.close();
  }

  onSubmit() {
    this.submitting = true;
    if (this.form.valid) {
      const formData: any = new FormData();
      Object.keys(this.form.controls).forEach((key) => {
        const controlValue = this.form.get(key).value;
        if (key === 'attachment' && controlValue instanceof File) {
          formData.append(key, controlValue);
        } else {
          formData.append(key, controlValue);
        }
      });

      // Log formData values
      formData.forEach((value, key) => {
        console.log(key + ': ' + value);
      });

      if (this.data.action !== 'update') {
        formData.append('user_id', localStorage.getItem('asdsUserId'));
        this.acRegServ.addAggregationCenter(formData).subscribe((resp: any) => {
          this.snackBar.open('message', resp['success'], {
            duration: 5000,
          });
          this.submitting = false;
          this.onNoClick();
        });
      } else {
        formData.append('_method', 'PUT');
        this.acRegServ
          .updateAggregationCenterData(formData, this.data.id)
          .subscribe((resp: any) => {
            this.snackBar.open('message', resp['success'], {
              duration: 5000,
            });
            this.submitting = false;
            // this.route.navigate(['manage-ac/ac-registration']);
            this.onNoClick();
          });
      }
    }
  }

  goBack() {
    this.route.navigate(['manage-ac/ac-registration']);
  }

  getErrorMessage() {
    return 'This field is required';
  }

  // Handle file selection
  onFileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.fileName = file.name;
      console.log('Selected file:', file);
      // You can also update the form control value if needed
      this.form.get('attachment').setValue(file);
    }
  }
}
