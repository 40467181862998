<form *ngIf="data.action == 'profile' || data.action == 'trader_category'" [formGroup]="userForm" (ngSubmit)="onSubmit()">
    <div class="title-holder">
        <h3 *ngIf="data.action == 'profile'" class="title" style="margin: 0px;color: #116B38;">Update profile</h3>
        <h3 *ngIf="data.action == 'trader_category'" class="title" style="margin: 0px;color: #116B38;">{{ 'update'|translate }} {{ 'category'|translate }}</h3>
    </div>
    <br>
    <mat-form-field class="w-100" appearance="outline" *ngIf="data.action == 'profile'">
        <mat-label>Full Name</mat-label>
        <input matInput placeholder="Full Name" formControlName="fullname" required>
        <mat-error *ngIf="userForm.get('fullname').invalid">{{getErrorMessage()}}</mat-error>
    </mat-form-field>
    <mat-form-field class="w-100" appearance="outline" *ngIf="data.action == 'profile'">
        <mat-label>email</mat-label>
        <input matInput placeholder="User Email" formControlName="email" required>
        <mat-error *ngIf="userForm.get('email').invalid">{{getErrorMessage()}}</mat-error>
    </mat-form-field>
    <mat-form-field class="w-100" appearance="outline" *ngIf="data.action == 'profile'">
        <mat-label>Phone</mat-label>
        <input matInput placeholder="Phone number" type="tel" formControlName="phone" required>
        <mat-error *ngIf="userForm.get('phone').invalid">{{getErrorMessage()}}</mat-error>
    </mat-form-field>
    <mat-form-field class="w-100" appearance="outline" *ngIf="data.action == 'profile'">
        <mat-label>username</mat-label>
        <input matInput placeholder="Username" formControlName="username" required>
        <mat-error *ngIf="userForm.get('username').invalid">{{getErrorMessage()}}</mat-error>
    </mat-form-field>
    <div class="col-md-12 column" *ngIf="authService.isBuyer || authService.isTemp">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>{{ 'category'|translate }}</mat-label>
            <mat-select placeholder="Select Category" formControlName="category" required multiple>
                <mat-option value="Buyer">Buyer</mat-option>
                <mat-option value="Seller">Seller</mat-option>
                <!-- <mat-option value="Broker">Broker</mat-option> -->
                <mat-option value="Transporter">Transporter</mat-option>
                <!-- <mat-option value="Warehouse Owner">Warehouse Owner</mat-option> -->
                <!-- <mat-option value="Warehouse Operator">Warehouse Operator</mat-option> -->
                <!-- <mat-option value="Market Manager">Market Manager</mat-option> -->
                <!-- <mat-option value="Checkpoint Manager">Checkpoint Manager</mat-option> -->
                <mat-option value="Processor">Processor</mat-option>
                <mat-option value="Exporter">Exporter</mat-option>
                <mat-option value="Importer">Importer</mat-option>
                <mat-option value="Aggregators">Aggregators</mat-option>
                <mat-option value="Produce Cleaning Centre">Produce Cleaning Centre</mat-option>
                <mat-option value="Aggregation Centre Manager">Aggregation Centre Manager</mat-option>
            </mat-select>
            <mat-error *ngIf="userForm.get('category').invalid">{{getErrorMessage()}}</mat-error>
        </mat-form-field>
    </div>

    <div style="margin-top: 50px;display: flex;flex-direction: row;justify-content: flex-end;">
        <button (click)="onNoClick()" type="button" class="cancelbtn" style="width: 48%" mat-button>cancel</button>

        <button class="createbtn" mat-button>submit</button>
    </div>
</form>

<form *ngIf="data.action == 'passwords'" [formGroup]="passwordForm" (ngSubmit)="onSubmitPasswords()">
    <div class="title-holder">
        <h3 class="title" style="margin: 0px;color: #116B38;">Change passwords</h3>
    </div>
    <br>
    <mat-form-field class="w-100" appearance="outline">
        <mat-label>Current passwords</mat-label>
        <input matInput placeholder="Current passwords" type="password" formControlName="current" required>
        <mat-error *ngIf="passwordForm.get('current').invalid">{{getErrorMessage()}}</mat-error>
    </mat-form-field>
    <mat-form-field class="w-100" appearance="outline">
        <mat-label>New passwords</mat-label>
        <input matInput placeholder="New passwords" type="password" formControlName="new" required>
        <mat-error *ngIf="passwordForm.get('new').invalid">{{getErrorMessage()}}</mat-error>
    </mat-form-field>

    <div style="margin-top: 50px;display: flex;flex-direction: row;justify-content: flex-end;">
        <button (click)="onNoClick()" type="button" class="cancelbtn" style="width: 48%" mat-button>cancel</button>

        <button class="createbtn" mat-button>submit</button>
    </div>
</form>