import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ManagementService } from '../../../management.service';
import { RegistrationService } from '../registration.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ac-view',
  templateUrl: './ac-view.component.html',
  styleUrls: ['./ac-view.component.css']
})
export class AcViewComponent implements OnInit {

  id;
  action;
  data;
  loading = true;
  attachmentUrl = environment.imageUrl;
  
  constructor(
    public active: ActivatedRoute,
    public acRegServ: RegistrationService,
    public manServ: ManagementService,
    private route: Router,
  ) {
    active.url.subscribe(
      data => {
        // console.log(data[2].path)
        this.id = data[2].path;
        if (this.id) {
          this.action = 'update';
        }
      }
    );
  }

  ngOnInit(): void {
    this.loading = true
    this.acRegServ.getAggregationCenterSingleData(this.id).subscribe(
      (data: any) => {
        this.data = data
        // console.log(data);
        this.loading = false;
      }
    );
  }

  goBack() {
    this.route.navigate(['manage-ac/ac-registration']);
  }

}
