import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CrossPointsService } from '../cross-points/cross-points.service';
import { ManagementService } from './../management.service';
import { MarketService } from '../markets/market.service';
import { RegistrationService } from '../aggregation-centers/registration/registration.service';
import { ProducesService } from '../aggregation-centers/produces/produces.service';
import { OrderService } from '../aggregation-centers/orders/order.service';

export interface DialogData {
  id;
  type;
}

@Component({
  selector: 'app-delete-data',
  templateUrl: './delete-data.component.html',
  styleUrls: ['./delete-data.component.css']
})
export class DeleteDataComponent implements OnInit {

  isLoading = false;

  constructor(
    public dialogRef: MatDialogRef<DeleteDataComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private manServ: ManagementService,
    private crossP: CrossPointsService,
    private marketServ: MarketService,
    private acRegServ: RegistrationService,
    private produceServ: ProducesService,
    private orderServ: OrderService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    this.isLoading = true;
    if (this.data.type === 'warehouse'){
      this.manServ.deleteWarehouseData(this.data.id).subscribe(
        (resp: string) => {
          this.snackBar.open('message', resp, {
            duration: 5000
          });
          this.onNoClick();
        }
      );
    }
    if (this.data.type === 'inwarehouse'){
      this.manServ.deleteInWarehouseData(this.data.id).subscribe(
        (resp: string) => {
          this.snackBar.open('message', resp, {
            duration: 5000
          });
          this.onNoClick();
        }
      );
    }
    if (this.data.type === 'market') {
      this.manServ.deleteMarket(this.data.id).subscribe(
        (resp: string) => {
          this.snackBar.open('message', resp, {
            duration: 5000
          });
          this.onNoClick();
        }
      );
    }
    if (this.data.type === 'inmarket') {
      this.manServ.deleteReceivedInMarket(this.data.id).subscribe(
        (resp: string) => {
          this.snackBar.open('message', resp, {
            duration: 5000
          });
          this.onNoClick();
        }
      );
    }
    if (this.data.type === 'crops') {
      this.manServ.deleteCrop(this.data.id).subscribe(
        (resp: string) => {
          this.snackBar.open('message', resp, {
            duration: 5000
          });
          this.onNoClick();
        }
      );
    }
    if (this.data.type === 'uoms') {
      this.manServ.deleteUom(this.data.id).subscribe(
        (resp: string) => {
          this.snackBar.open('message', resp, {
            duration: 5000
          });
          this.onNoClick();
        }
      );
    }
    if (this.data.type === 'trader') {
      this.manServ.deleteTrader(this.data.id).subscribe(
        (resp: string) => {
          this.snackBar.open('message', resp, {
            duration: 5000
          });
          this.onNoClick();
        }
      );
    }
    if (this.data.type === 'withdraw') {
      this.manServ.deleteWithdrawedGood(this.data.id).subscribe(
        (resp: string) => {
          this.snackBar.open('message', resp, {
            duration: 5000
          });
          this.onNoClick();
        }
      );
    }
    if (this.data.type === 'user') {
      this.manServ.deleteUser(this.data.id).subscribe(
        (resp: string) => {
          this.snackBar.open('message', resp, {
            duration: 5000
          });
          this.onNoClick();
        }
      );
    }
    if (this.data.type === 'role') {
      this.manServ.deleteRole(this.data.id).subscribe(
        (resp: string) => {
          this.snackBar.open('message', resp, {
            duration: 5000
          });
          this.onNoClick();
        }
      );
    }

    if (this.data.type === 'cross-point') {
      this.crossP.deleteCrossPoint(this.data.id).subscribe(
        (resp: string) => {
          this.snackBar.open('message', resp, {
            duration: 5000
          });
          this.onNoClick();
        }
      );
    }

    if (this.data.type === 'cross-point-data') {
      this.crossP.deleteCrossPointData(this.data.id).subscribe(
        (resp: string) => {
          this.snackBar.open('message', resp, {
            duration: 5000
          });
          this.onNoClick();
        }
      );
    }
    if (this.data.type === 'marketprice') {
      this.marketServ.deleteMarketPrice(this.data.id).subscribe(
        (resp: string) => {
          this.snackBar.open('message', resp, {
            duration: 5000
          });
          this.onNoClick();
        }
      );
    }
    if (this.data.type === 'ac') {
      this.acRegServ.deleteAggregationCenterData(this.data.id).subscribe(
        (resp: string) => {
          this.snackBar.open('message', resp, {
            duration: 5000
          });
          this.onNoClick();
        }
      );
    }
    if (this.data.type === 'produce') {
      this.produceServ.deleteProduceData(this.data.id).subscribe(
        (resp: string) => {
          this.snackBar.open('message', resp, {
            duration: 5000
          });
          this.onNoClick();
        }
      );
    }
    if (this.data.type === 'order') {
      this.orderServ.deleteOrderData(this.data.id).subscribe(
        (resp: string) => {
          this.snackBar.open('message', resp, {
            duration: 5000
          });
          this.onNoClick();
        }
      );
    }

  }

}
