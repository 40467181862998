import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { DeleteDataComponent } from '../../../delete-data/delete-data.component';
import { ManagementService } from '../../../management.service';
import { RegistrationService } from '../registration.service';
import { Route, Router } from '@angular/router';
import { AcFormComponent } from '../ac-form/ac-form.component';

@Component({
  selector: 'app-ac-list',
  templateUrl: './ac-list.component.html',
  styleUrls: ['./ac-list.component.css'],
})
export class AcListComponent implements OnInit {
  centers;
  menu = 'Aggregation centers';
  isLoading = true;
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  dtCheck = false;
  constructor(
    public dialog: MatDialog,
    private acRegServ: RegistrationService,
    private manServ: ManagementService,
    public authService: AuthService,
    public route: Router
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.getAggregationCenters();
    this.dtTrigger.next();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 100,
      processing: true,
      dom: 'Bfrtip',
      // buttons: [
      //     'copy', 'csv', 'excel', 'print'
      // ]
    };
  }
  onAddAggregationCenter() {
    // this.route.navigate(['manage-ac/ac-registration/add'])

    const dialogRef = this.dialog.open(AcFormComponent, {
      width: '710px',
      height: '810px',
      data: { action: 'add' },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('closed');
      // this.dtCheck = false;
      this.getAggregationCenters();
    });
  }

  viewAggregationCenter(id) {
    this.route.navigate(['manage-ac/ac-registration/view/' + id])
  }

  editAggregationCenter(id) {
    // this.route.navigate(['manage-ac/ac-registration/update/' + id]);

    const dialogRef = this.dialog.open(AcFormComponent, {
      width: '710px',
      height: '810px',
      data: { action: 'update', id: id },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('closed');
      // this.dtCheck = false;
      this.getAggregationCenters();
    });
  }

  deleteAggregationCenter(id) {
    const dialogRef = this.dialog.open(DeleteDataComponent, {
      width: '500px',
      height: '300px',
      // tslint:disable-next-line: object-literal-shorthand
      data: { id: id, type: 'ac' },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getAggregationCenters();
    });
  }

  getAggregationCenters() {
    this.acRegServ.getAggregationCenters().subscribe((data) => {
      this.centers = data;
      this.isLoading = false;
      this.rerender();
    });
  }

  rerender(): void {
    if (this.dtCheck) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.dtCheck = true;
      this.dtTrigger.next();
    }
  }
}
