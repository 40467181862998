import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as Highcharts from 'highcharts/highstock';
import HC_exporting from 'highcharts/modules/export-data';
import { AcReportService } from '../../ac-report.service';
HC_exporting(Highcharts);

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css']
})
export class SummaryComponent implements OnInit {

  loading: boolean = true;
  summary;

  Highcharts = Highcharts;
  chartOptions: {};
  produceSummaryChartOptions: {};
  chartConstructor = 'chart';
  constructor(
    public acReportServ: AcReportService,
    public route: Router
  ) { }

  ngOnInit(): void {

    this.acReportServ.getProduceSummaryReport().subscribe(
      (data: any) => {
        console.log(data);

        // this.orders = data.data;
        this.summary = data.summary;
        this.produceSummaryChart(data);
        this.loading = false;
        // this.rerender();
      }
    );
  }

  produceSummaryChart(data) {
    this.produceSummaryChartOptions = {
      chart: {
        type: 'column',
        // marginLeft: 60, // Adjust margins if necessary
        // marginRight: 60,
        events: {
          render: function () {
            this.reflow();
          }
        },
      },
      title: {
        text: 'Produce Summary',
        align: 'left'
      },
      subtitle: {
        text:
          'Available vs Sold',
        align: 'left'
      },
      xAxis: {
        categories: data.categories,
        crosshair: true,
        accessibility: {
          description: 'Crops'
        }
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Quantity (Kg)'
        }
      },
      tooltip: {
        valueSuffix: ' Kg'
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series: data.series,
      legend: {
        enabled: true
      },
      // legend: {
      //   layout: 'vertical',
      //   align: 'right',
      //   verticalAlign: 'top',
      //   x: -40,
      //   y: 50,
      //   floating: true,
      //   borderWidth: 1,
      //   backgroundColor:
      //     Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
      //   shadow: true
      // },
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              layout: 'vertical',
              align: 'bottom',
              verticalAlign: 'right'
            },
            xAxis: {
              labels: {
                // rotation: -45, // Rotate labels for better fit
                align: 'center',
                style: {
                  fontSize: '13px',
                  fontFamily: 'Verdana, sans-serif'
                }
              }
            }
          }
        }]
      }
    };

  }

  goBack() {
    this.route.navigate(['manage-ac/reports']);
  }
}
