import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { AcReportService } from '../../ac-report.service';

@Component({
  selector: 'app-buyer-report',
  templateUrl: './buyer-report.component.html',
  styleUrls: ['./buyer-report.component.css']
})
export class BuyerReportComponent implements OnInit {

  loading = false;
  buyers;
  owed_amount;

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  dtCheck = false;

  constructor(
    public route: Router,
    public acReportServ: AcReportService
  ) { }

  ngOnInit(): void {
    this.getData();
    this.dtTrigger.next();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 100,
      processing: true,
      dom: 'Bfrtip',
        // buttons: [
        //     'copy', 'csv', 'excel', 'print'
        // ]
    };
  }

  getData() {
    this.loading = true;
    this.acReportServ.getBuyerOrders().subscribe(
      (data: any) => {
        // console.log(data);

        this.buyers = data.data;
        this.owed_amount = data.owed_amount;
        this.loading = false;
        this.rerender();
      }
    );
  }

  rerender(): void {
    if (this.dtCheck){
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
      });
    }
    else {
      this.dtCheck = true;
      this.dtTrigger.next();
    }

  }

  goBack() {
    this.route.navigate(['manage-ac/reports']);
  }
}
