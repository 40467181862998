<div class="relative">
    <div id="content-header" class="absolute -top-24">
        <h1 class="text-uppercase font-bold">
            View Order
        </h1>
    </div>
    <div class="absolute -top-28 right-2 cursor-pointer text-white bg-red-500 hover:bg-red-600 rounded-lg h-6 w-6 items-center text-center"
        title="Close" (click)="goBack()">x</div>
    <div class="flex flex-col-reverse md:flex-row gap-4 w-full min-h-64 lg:w-1/2">
        <div class="px-4 bg-gray-50 rounded-md p-2 relative w-full">

            <div class="">
                <!-- Grid -->
                <div class="flex justify-between mt-4 border-t border-gray-200">


                    <div class="text-end flex space-x-2 items-center">
                        <div class="text-2xl font-semibold text-gray-800 dark:text-neutral-200">
                            Order #
                        </div>
                        <div class="bg-sky-50 text-sky-900 dark:text-neutral-500">{{ order.orderno }}</div>
                    </div>
                    <!-- Col -->
                </div>
                <!-- End Grid -->

                <!-- Grid -->
                <div class="mt-8 grid sm:grid-cols-2 gap-3">
                    <div>
                        <h3 class="text-lg font-semibold text-gray-800 dark:text-neutral-200">Buyer:</h3>
                        <h3 class="text-md font-semibold text-gray-800 dark:text-neutral-200">
                            {{ order.buyer_name }} <br>
                            {{ order.buyer_phone }}
                        </h3>

                    </div>
                    <!-- Col -->

                    <div class="sm:text-end space-y-2">
                        <!-- Grid -->
                        <div class="grid grid-cols-2 sm:grid-cols-1 gap-3 sm:gap-2">
                            <dl class="grid sm:grid-cols-5 gap-x-3">
                                <dt class="col-span-3 font-semibold text-gray-800 dark:text-neutral-200">
                                    Order date:
                                </dt>
                                <dd class="col-span-2 text-gray-500 dark:text-neutral-500">
                                    {{ order.order_date | date:'d/MM/yyy' }}
                                </dd>
                            </dl>
                            <dl class="grid sm:grid-cols-5 gap-x-3">
                                <dt class="col-span-3 font-semibold text-gray-800 dark:text-neutral-200">
                                    Order Status:
                                </dt>
                                <dd class="col-span-2 text-gray-500 dark:text-neutral-500">
                                    <span *ngIf="order.status == 'sold'" class="bg-green-100/50 rounded-md px-2 text-green-500">{{ order.status }}</span>
                                    <span *ngIf="order.status == 'cancelled'" class="bg-red-100/50 rounded-md px-2 text-red-500">{{ order.status }}</span>
                                    <span *ngIf="order.status == 'new'" class="bg-sky-100/50 rounded-md px-2 text-sky-500">{{ order.status }}</span>
                                </dd>
                            </dl>
                        </div>
                        <!-- End Grid -->
                    </div>
                    <!-- Col -->
                </div>
                <!-- End Grid -->

                <!-- Table -->
                <div class="mt-6">
                    <div class="border border-gray-200 rounded-lg space-y-4 dark:border-neutral-700">
                        <!-- <div class="hidden md:grid md:grid-cols-5">
                            <div
                                class="sm:col-span-2 text-xs font-medium text-gray-500 uppercase dark:text-neutral-500">
                                Item</div>
                            <div class="text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-500">
                                Qty</div>
                            <div class="text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-500">
                                Total Price</div>
                        </div> -->
                        <div class="table-responsive">
                            <table class="table-auto">
                                <thead>
                                    <tr>
                                        <td>Item</td>
                                        <td>Qty (Kg)</td>
                                        <td>Total Price (TSh.)</td>
                                    </tr>
                                </thead>
                                <tbody class="[&>*:nth-child(even)]:!bg-gray-100">
                                    <tr *ngFor="let dt of order.items">
                                        <td>{{ dt.crop?.name }}</td>
                                        <td>{{ dt.quantity }}</td>
                                        <td class="text-right">{{ dt.price | number }}</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>

                        <div class="hidden sm:block border-b border-gray-200 dark:border-neutral-700"></div>

                        <!-- <div class="grid grid-cols-3 sm:grid-cols-5 gap-2 items-center" *ngFor="let dt of order.items">
                            <div class="col-span-full sm:col-span-2">
                                <h5
                                    class="sm:flex md:hidden text-xs font-medium text-gray-500 uppercase dark:text-neutral-500">
                                    Item</h5>
                                <p class="font-medium text-gray-800 dark:text-neutral-200 flex items-center">
                                    {{ dt.crop?.name }}
                                </p>
                            </div>
                            <div>
                                <h5
                                    class="sm:flex md:hidden text-xs font-medium text-gray-500 uppercase dark:text-neutral-500">
                                    Qty</h5>
                                <p class="text-gray-800 dark:text-neutral-200">{{ dt.quantity }}</p>
                            </div>
                            <div>
                                <h5
                                    class="ssm:flex md:hidden text-xs font-medium text-gray-500 uppercase dark:text-neutral-500">
                                    Price</h5>
                                <p class="text-gray-800 dark:text-neutral-200">
                                    {{ dt.price | number }}</p>
                            </div>
                        </div> -->
                        <div class="sm:hidden border-b border-gray-200 dark:border-neutral-700"></div>

                    </div>
                </div>
                <!-- End Table -->

                <!-- Flex -->
                <div class="mt-8 flex sm:justify-end">
                    <div class="w-full max-w-2xl sm:text-end space-y-2">
                        <!-- Grid -->
                        <div class="grid grid-cols-2 sm:grid-cols-1 gap-3 sm:gap-2">


                            <dl class="grid sm:grid-cols-5 gap-x-3">
                                <dt class="col-span-3 font-semibold text-gray-800 dark:text-neutral-200">
                                    Total:</dt>
                                <dd class="col-span-2 text-gray-500 dark:text-neutral-500">Tsh.
                                    {{ order.total_price | number }}</dd>
                            </dl>

                        </div>
                        <!-- End Grid -->
                    </div>
                </div>
                <!-- End Flex -->
            </div>

            <div class="absolute top-1/2 right-1/2 z-50" *ngIf="isLoading">
                <div class="w-20 h-20 bg-black/50 flex justify-center items-center rounded-md text-gray-200">
                    <i class="fa fa-spinner fa-spin text-4xl"></i>
                </div>
            </div>
        </div>
    </div>
</div>