import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const baseUrl = environment.baseUrl;
const url1 = baseUrl + '/trader-summary';
const url2 = baseUrl + '/send-message';

@Injectable({
  providedIn: 'root'
})
export class TradersService {

  headers = new HttpHeaders({
    'Content-Type': 'application/json',
     'X-Requested-Width': 'XMLHttpRequeest',
    'Access-Control-Allow-Origin': '*',
    'Authorization': `Bearer ${localStorage.getItem('api_token')}`
   });

  fileheaders = new HttpHeaders({
    // 'Content-Type': 'multipart/form-data',
    'Accept': 'application/json',
    //  'X-Requested-Width': 'XMLHttpRequeest',
    'Access-Control-Allow-Origin': '*'
   });
  constructor(private http: HttpClient) { }

  getData(data) {
    return this.http.post(url1, data, {headers: this.headers});
  }

  sendMessage(data) {
    return this.http.post(url2, data, {headers: this.headers});
  }
}