import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


const baseUrl = environment.baseUrl;
const url1 = baseUrl + '/orders';
const url2 = baseUrl + '/pending-orders/';
const url3 = baseUrl + '/order-crop-amounts/';
const url4 = baseUrl + '/all-buyers';
const url5 = baseUrl + '/search-buyers';
const url6 = baseUrl + '/cancel-order';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'X-Requested-Width': 'XMLHttpRequeest',
    'Access-Control-Allow-Origin': '*',
    'Authorization': `Bearer ${localStorage.getItem('api_token')}`
  });

  fileheaders = new HttpHeaders({
    // 'Content-Type': 'multipart/form-data',
    'Accept': 'application/json',
    //  'X-Requested-Width': 'XMLHttpRequeest',
    'Access-Control-Allow-Origin': '*',
    'Authorization': `Bearer ${localStorage.getItem('api_token')}`
  });
  constructor(private http: HttpClient) { }

  getOrders() {
    return this.http.get(url1, { headers: this.headers });
  }

  getOrderData() {
    return this.http.get(url1, { headers: this.headers });
  }

  addOrder(data) {
    return this.http.post(url1, data, { headers: this.headers });
  }

  getOrderSingleData(id) {
    return this.http.get(url1 + '/' + id, { headers: this.headers });
  }

  updateOrderData(data, id) {
    return this.http.put(url1 + '/' + id, data, { headers: this.headers });
  }

  deleteOrderData(id) {
    return this.http.delete(url1 + '/' + id, { headers: this.headers });
  }

  getPendingOrders(crop_id) {
    return this.http.get(url2 + crop_id, { headers: this.headers });
  }

  getOrderCropAmount(order_id, crop_id) {
    return this.http.get(url3 + order_id + '/' + crop_id, { headers: this.headers });
  }

  getBuyers() {
    return this.http.get(url4, { headers: this.headers });
  }

  searchBuyers(fullName, phone) {
    return this.http.get<any>(`${url5}?full_name=${fullName}&phone=${phone}`);
  }

  cancelOrder(id) {
    return this.http.get(url6 + '/' + id, {headers: this.headers});
  }
}
