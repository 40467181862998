<div *ngIf="!isLoading">
    <p style="text-align: center;">
        <mat-icon style="font-size: 80px;height:80px;width:80px;font-weight: lighter;text-align: center;color: #a81f1f">help_outline</mat-icon>
    </p>
    <p style="text-align: center;font-size: 25px;color: #383838;">Are you sure you want to cancel this order?</p>
    <div style="display: flex;flex-direction: row;justify-content: space-between;margin-top: 40px;">
        <button mat-button class="delete-btn" style="width: 47%;" (click)="onNoClick()">NO</button>
        <button class="red-delete-btn" style="width: 47%;" (click)="onSubmit()" mat-button>YES, CANCEL</button>
    </div>
</div>

<div *ngIf="isLoading" class="spinner-holder">
    <mat-spinner class="spinner"></mat-spinner>
</div>
