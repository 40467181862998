<form [formGroup]="form" (ngSubmit)="onSubmit()" class="h-full">
    <div class="title-holder">
        <h2 class="title">Send SMS To {{data.category | titlecase}}</h2>
    </div>
    <div class="h-[30vh] overflow-y-auto">

        <div class="">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>{{ data.category | titlecase }}</mat-label>
                <mat-select placeholder="Select {{ data.category }}" formControlName="id" (selectionChange)="setPhone($event.value)">
                    <mat-option *ngFor="let trader of traders" [value]="trader">{{trader.full_name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="form.get('trader_id').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
        <div class="">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>{{data.category | titlecase}} Phone</mat-label>
                <input matInput type="tel" placeholder="255xxxxxxxxx or 0xxxxxxxxx" formControlName="phone" required>
                <mat-error *ngIf="form.get('phone').invalid">{{getPhoneErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>

        <div class="">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Message</mat-label>
                <textarea matInput rows="3" type="text" placeholder="Enter message" formControlName="message" required></textarea>
                <mat-error *ngIf="form.get('message').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
    </div>

    <div class="flex justify-end space-x-2 bg-gray-50 py-2">
        <button (click)="onNoClick()" class="cancelbtn" mat-button>Cancel</button>

        <button type="submit" class="createbtn" mat-button><span class="px-2" *ngIf="isLoading"><i class="fa fa-spinner fa-spin"></i></span>Submit</button>
    </div>
</form>