import { Component, OnInit, ViewChild } from '@angular/core';
import { DeleteDataComponent } from '../../../delete-data/delete-data.component';
import { ProducesService } from '../produces.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { ManagementService } from '../../../management.service';

@Component({
  selector: 'app-produces-list',
  templateUrl: './produces-list.component.html',
  styleUrls: ['./produces-list.component.css']
})
export class ProducesListComponent implements OnInit {

  produces;
  isLoading = true
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  dtCheck = false;
  constructor(
    public dialog: MatDialog,
    private produceServ: ProducesService,
    private manServ: ManagementService,
    public authService: AuthService,
    public route: Router
    ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.getProduces();
    this.dtTrigger.next();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 100,
      processing: true,
      dom: 'Bfrtip',
        // buttons: [
        //     'copy', 'csv', 'excel', 'print'
        // ]
    };
  }
  onAddProduce() {
    this.route.navigate(['manage-ac/produces/add'])
  }


  editProduce(id) {
    this.route.navigate(['manage-ac/produces/update/' + id])
  }

  sellProduce(id) {
    this.route.navigate(['manage-ac/produces/sell/' + id])
  }

  viewProduce(id) {
    this.route.navigate(['manage-ac/produces/view/' + id])
  }

  deleteProduce(id) {
    const dialogRef = this.dialog.open(DeleteDataComponent, {
      width: '500px',
      height: '300px',
      // tslint:disable-next-line: object-literal-shorthand
      data: {id: id, type: 'produce' }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getProduces();
    });
  }

  getProduces() {
    this.produceServ.getProduces().subscribe(
      data => {
        this.produces = data;
        this.isLoading = false
        this.rerender();
      }
    );
  }

  rerender(): void {
    if (this.dtCheck){
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
      });
    }
    else {
      this.dtCheck = true;
      this.dtTrigger.next();
    }

  }
}
