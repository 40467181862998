<div id="content" class="relative">
    <!-- <div class="fixed top-2/4 left-2/4 z-50 {{ loading ? '' : 'hidden' }}">
        <div class=" bg-gray-900/50 text-gray-100 p-3 rounded-md">
            <i class="fa fa-spinner fa-spin fa-2x"></i>
        </div>
    </div> -->

    <div id="content-header" class="absolute -top-24">
        <h1 class="text-uppercase font-bold">AC Reports</h1>
    </div>
    <div class="">
        <div class="grid grid-cols-1 md:grid-cols-4 gap-4">
            <div class="bg-gradient-to-r from-green-200/30 to-green-200/30 rounded-md p-2 h-32 text-center relative">
                <div class="flex space-x-2 items-center h-full">
                    <div class="">
                        <div class="bg-white rounded-full p-2">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="size-5 lg:size-10">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" />
                            </svg>
                        </div>
                    </div>
                    <div class="">
                        <div class="text-green-600 font-bold text-center text-lg lg:text-xl">General Orders</div>
                        <div class="font-bold text-md lg:text-lg">View General Orders Report</div>
                        <div class="mt-3">
                            <a href="manage-ac/reports/general-order-report" class="bg-green-500 hover:bg-green-600 rounded-md text-white font-bold px-10 py-1 text-md lg:text-lg hover:no-underline">View
                                Report</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bg-gradient-to-r from-sky-200/30 to-sky-200/30 rounded-md p-2 h-32 text-center relative">
                <div class="flex space-x-2 items-center h-full">
                    <div class="">
                        <div class="bg-white rounded-full p-2">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="size-5 lg:size-10">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z" />
                            </svg>

                        </div>
                    </div>
                    <div class="">
                        <div class="text-sky-600 font-bold text-center text-lg lg:text-xl">Buyer Orders</div>
                        <div class="font-bold text-md lg:text-lg">View Orders Per Buyer Report</div>
                        <div class="mt-3">
                            <a href="manage-ac/reports/buyer-order-report" class="bg-sky-500 hover:bg-sky-600 rounded-md text-white font-bold px-10 py-1 text-md lg:text-lg hover:no-underline">View
                                Report</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bg-gradient-to-r from-amber-200/30 to-amber-200/30 rounded-md p-2 h-32 text-center relative">
                <div class="flex space-x-2 items-center h-full">
                    <div class="">
                        <div class="bg-white rounded-full p-2">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="size-5 lg:size-10">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z" />
                            </svg>

                        </div>
                    </div>
                    <div class="">
                        <div class="text-amber-600 font-bold text-center text-lg lg:text-xl">Produce Reports</div>
                        <div class="font-bold text-md lg:text-lg">View Produce Summaries</div>
                        <div class="mt-3">
                            <a href="manage-ac/reports/produce/summary-report" class="bg-amber-500 hover:bg-amber-600 rounded-md text-white font-bold px-10 py-1 text-md lg:text-lg hover:no-underline">View
                                Report</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>