import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BuyerService } from '../buyer.service';
import jspdf, { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { environment } from 'src/environments/environment';

export interface DialogData {
  id;
}

@Component({
  selector: 'app-print-permit',
  templateUrl: './print-permit.component.html',
  styleUrls: ['./print-permit.component.css'],
})
export class PrintPermitComponent implements OnInit {
  @ViewChild('pdfTable', { static: false }) pdfTable: ElementRef;
  @ViewChild('content') content!: ElementRef;
  application;
  isLoading = true;
  baseUrl;
  code;
  person = 'MNUNUZI';
  action = 'ununuzi';

  constructor(
    public dialogRef: MatDialogRef<PrintPermitComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public buyerService: BuyerService,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.getDetails();
    this.baseUrl = environment.appUrl;
  }

  getDetails() {
    this.buyerService
      .getPermitApplicationDetails(this.data.id)
      .subscribe((data) => {
        this.application = data;
        this.isLoading = false;
        this.code = this.baseUrl + 'qr-info/' + this.application.application_number;
        
        const persons: string[] = [];
        const actions: string[] = [];
        
        const category = this.application.trader?.category || '';
        
        if (category.includes('Buyer')) {
          persons.push('MNUNUZI');
          actions.push('ununuzi');
        }
        if (category.includes('Seller')) {
          persons.push('MUUJAZI');
          actions.push('uuzaji');
        }
        if (category.includes('Transporter')) {
          persons.push('MSAFIRISHAJI');
          actions.push('usafirishaji');
        }
        if (category.includes('Exporter')) {
          persons.push('MSAFIRISHAJI NJE');
          actions.push('usafirishaji nje');
        }
        if (category.includes('Importer')) {
          persons.push('MUAGIZAJI');
          actions.push('uagizaji');
        }
        if (category.includes('Processor')) {
          persons.push('MCHAKATAJI');
          actions.push('uchakataji');
        }
        if (category.includes('Aggregator')) {
          persons.push('MKUSANYAJI');
          actions.push('ukusanyaji');
        }
        if (!category || persons.length === 0) {
          persons.push('KITUO CHA USAFISHAJI');
          actions.push('usafishaji');
        }

        this.person = persons.join(', ');
        this.action = actions.join(', ');
      });
  }
  // public downloadAsPDF() {
  //   const data: any = document.getElementById('permit');
  //   html2canvas(data, { scale: 2 }).then(canvas => {
  //     // let pdf = new jspdf('l', 'cm', 'a4'); //Generates PDF in landscape mode
  //     let pdf = new jspdf('p', 'mm', 'a4'); // Generates PDF in portrait mode
  //     const imgData = canvas.toDataURL('image/png'); // 'image/jpeg' for lower quality output.

  //     const pageWidth = 210;
  //     const pageHeight = 297;

  //     const paddingX = 10; // Padding in X direction
  //     const paddingY = 10; // Padding in Y direction
  //     const borderWidth = 1; // Border width

  //     const imageWidth = pageWidth - (2 * paddingX);
  //     const imageHeight = canvas.height * imageWidth / canvas.width;

  //     const xPos = paddingX;
  //     const yPos = paddingY;

  //     // Add black border around the document
  //     pdf.setDrawColor(0); // Black color
  //     pdf.setLineWidth(borderWidth);
  //     pdf.rect(paddingX - borderWidth + 1, paddingY - borderWidth, imageWidth + 2 * borderWidth, pageHeight - (20 + borderWidth), 'S');

  //     pdf.addImage(imgData, 'PNG', xPos + 2, yPos, imageWidth - 2, imageHeight - 2);
  //     pdf.save('permit.pdf');
  //   });

  // }
  public downloadAsPDF() {
    const data: any = document.getElementById('permit');
    html2canvas(data, { scale: 2 }).then((canvas) => {
      let pdf = new jsPDF('p', 'mm', 'a4'); // Generates PDF in portrait mode
      const imgData = canvas.toDataURL('image/png'); // 'image/jpeg' for lower quality output.

      const pageWidth = 210;
      const pageHeight = 297;

      const borderWidth = 1; // Border width
      const outerPaddingX = 15; // Padding between page edge and border in X direction
      const outerPaddingY = 15; // Padding between page edge and border in Y direction
      const innerPadding = 3.5; // Padding between border and content

      const contentWidth =
        pageWidth - 2 * (outerPaddingX + innerPadding + borderWidth);
      const contentHeight =
        pageHeight - 2 * (outerPaddingY + innerPadding + borderWidth);

      const canvasPageHeight = contentHeight * (canvas.width / contentWidth);
      const totalPages = Math.ceil(canvas.height / canvasPageHeight);

      for (let i = 0; i < totalPages; i++) {
        const startY = i * canvasPageHeight;
        const endY = startY + canvasPageHeight;

        // Create a temporary canvas to hold the section of the image
        const tempCanvas = document.createElement('canvas');
        const tempContext = tempCanvas.getContext('2d');
        tempCanvas.width = canvas.width;
        tempCanvas.height = Math.min(canvasPageHeight, canvas.height - startY);

        tempContext.drawImage(
          canvas,
          0,
          startY,
          canvas.width,
          tempCanvas.height,
          0,
          0,
          canvas.width,
          tempCanvas.height
        );

        const tempImgData = tempCanvas.toDataURL('image/png');

        // Add black border around the page
        pdf.setDrawColor(0); // Black color
        pdf.setLineWidth(borderWidth);
        pdf.rect(
          outerPaddingX,
          outerPaddingY,
          pageWidth - 2 * outerPaddingX,
          pageHeight - 2 * outerPaddingY
        );

        // Add the image with inner padding
        const xPos = outerPaddingX + innerPadding + borderWidth;
        const yPos = outerPaddingY + innerPadding + borderWidth;
        pdf.addImage(
          tempImgData,
          'PNG',
          xPos,
          yPos,
          contentWidth,
          (tempCanvas.height * contentWidth) / canvas.width
        );

        if (i < totalPages - 1) {
          pdf.addPage();

          // Add separation line
          pdf.setDrawColor(0);
          pdf.setLineWidth(0.5);
          pdf.line(
            outerPaddingX,
            pageHeight - outerPaddingY + 5,
            pageWidth - outerPaddingX,
            pageHeight - outerPaddingY + 5
          );
        }
      }

      pdf.save('permit.pdf');
    });
  }

  generatePdf() {
    // const printContent = `<div class="print-content">${this.content.nativeElement.innerHTML}</div>`;
    // const originalContent = document.body.innerHTML;

    // document.body.innerHTML = printContent;
    // setTimeout(() => {
    //   window.print();
    //   document.body.innerHTML = originalContent;
    // }, 100);
    this.buyerService.generatePdf('permit');
  }
}
