import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ManagementService } from '../../../management.service';
import { RegistrationService } from '../../registration/registration.service';
import { ProducesService } from '../produces.service';
import { OrderService } from '../../orders/order.service';

@Component({
  selector: 'app-sell-produce',
  templateUrl: './sell-produce.component.html',
  styleUrls: ['./sell-produce.component.css']
})
export class SellProduceComponent implements OnInit {
  form: FormGroup;

  id;
  action;

  crops;
  centers;
  loading = false;
  submitting = false;
  farmer

  firstName: string = '';
  lastName: string = '';
  farmers = [];
  showModal: boolean = false;
  searching: boolean = false;

  produce;
  buyers;
  orders;

  crop_id;
  order_id;
  order_details;

  constructor(
    public active: ActivatedRoute,
    public produceServ: ProducesService,
    public acRegServ: RegistrationService,
    public manServ: ManagementService,
    public orderServ: OrderService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private route: Router,
  ) {
    active.url.subscribe(
      data => {
        // console.log(data[2].path)
        this.id = data[2].path;

      }
    );
  }
  ngOnInit(): void {
    this.produceServ.getProduceSingleData(this.id).subscribe(
      (data: any) => {
        this.produce = data
        // console.log(data);
        this.crop_id = data.crop_id
        this.getOrders();
      }
    );

    this.form = this.fb.group({
      trader_id: ['', Validators.required],
      order_id: ['', Validators.required],
      date: [this.getCurrentDate(), Validators.required],
      amount: ['', Validators.required],
      id: [''],
    });

    this.getAllBuyers();
  }


  getAllBuyers() {
    this.orderServ.getBuyers().subscribe(
      data => {
        this.buyers = data;
        this.loading = false;
      }
    );
  }

  getOrders() {
    // alert(this.crop_id)
    this.orderServ.getPendingOrders(this.crop_id).subscribe(
      data => {
        this.orders = data;
        this.loading = false;
        console.log(data);

      }
    );
  }

  getOrderCropAmounts(order_id) {
    this.loading = true;

    this.orderServ.getOrderCropAmount(order_id, this.crop_id).subscribe(
      (data: any) => {
        this.order_details = data;
        this.loading = false;

        this.form.patchValue({
          trader_id: data.trader_id
        })
      }
    );
  }

  goBack() {
    this.route.navigate(['manage-ac/produces']);
  }

  onSubmit() {
    this.submitting = true;
    this.form.get('id').setValue(this.id);
    if (this.form.valid) {
      // console.log(this.form.value);
      this.loading = false
      this.submitting = false
      if (this.form.get('amount').value > this.produce.amount) {
        this.snackBar.open('Error', 'Sell amount exceeds available amount.', {
          duration: 5000
        });
      } else if (this.form.get('amount').value > this.order_details.remain_amount) {
        this.snackBar.open('Error', 'Sell amount exceeds remained order amount.', {
          duration: 5000
        });
      } else {
        this.produceServ.sellProduce(this.form.value).subscribe(
          (resp: any) => {
            this.snackBar.open('success', resp['success'], {
              duration: 5000
            });
            this.submitting = false;
            this.route.navigate(['manage-ac/produces'])
          }
        );

      }

    }
  }

    // Function to get the current date in 'YYYY-MM-DD' format
    getCurrentDate(): string {
      const today = new Date();
      const year = today.getFullYear();
      const month = ('0' + (today.getMonth() + 1)).slice(-2);  // Months are zero-based
      const day = ('0' + today.getDate()).slice(-2);
      return `${year}-${month}-${day}`;
    }
}
