<div class="w-100">
    <div [ngClass]="!isLoading ? 'show' : 'hide'" class="view card">
        <div class="table-responsive">
          <div class="card-header d-flex justify-content-start" *ngIf="authService.isAllowed('input-data')">
              <button class="button" mat-button (click)="withdraw()"><span class="material-icons">ios_share</span>Release from Primary Market</button>
          </div>
            <div class="card-body">
                <!-- i have removed [dtTrigger]="dtTrigger" below because of the above ngif -->
                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" id="dtbs" class="table p-3">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th>Buyer</th>
                            <th>Crop</th>
                            <th>Processed</th>
                            <th>Quality/Grade</th>
                            <th>Quantity (Kg)</th>
                            <th>Buying Price (TZs)</th>
                            <!-- <th>End-product</th> -->
                            <th>Utilization</th>
                            <th>Destination Region</th>
                            <th>Destination District</th>
                            <!-- <th>Country</th> -->
                            <!-- <th>Action</th> -->
                        </tr>

                    </thead>
                    <tbody>
                        <tr *ngFor="let withdraw of withdraws;let i = index">
                            <td>{{i+1}}</td>
                            <td>{{withdraw.date | date: mediumDate}}</td>
                            <td>{{withdraw.buyer}}</td>
                            <td>{{withdraw.crop}}</td>
                            <td>{{withdraw.processed}}</td>
                            <td>{{withdraw.quality}}</td>
                            <td>{{withdraw.quantity}}</td>
                            <td>{{withdraw.buying_price}}</td>
                            <!-- <td>{{withdraw.after_processed}}</td> -->
                            <td>{{withdraw.usage}}</td>
                            <td>{{withdraw.region?.name}}</td>
                            <td>{{withdraw.district?.name}}</td>
                            <!-- <td>{{withdraw.country?.country_name}}</td> -->
                            <!-- <td style="display: flex; flex-direction: row;">
                                <a class="text-success act" (click)="editWithdraw(withdraw.id, withdraw.region_id, withdraw.district_id, withdraw.country_id)"><i class="fa fa-edit"></i></a>
                                <mat-icon class="text-danger act" (click)="deletWithdraw(withdraw.id)">delete_forever</mat-icon>
                            </td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div *ngIf="isLoading" class="spinner-holder">
        <mat-spinner class="spinner"></mat-spinner>
    </div>
</div>
