import { Component, OnInit, ViewChild } from '@angular/core';
import { AcReportService } from '../ac-report.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import * as Highcharts from 'highcharts/highstock';
import HC_exporting from 'highcharts/modules/export-data';
HC_exporting(Highcharts);

@Component({
  selector: 'app-general-order-report',
  templateUrl: './general-order-report.component.html',
  styleUrls: ['./general-order-report.component.css']
})
export class GeneralOrderReportComponent implements OnInit {

  loading = false;
  filtering = false;
  orders;

  statuses = ['new', 'partial fulfilled', 'full fulfilled', 'cancelled'];

  start_date = '';
  end_date = '';
  status = '';

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  dtCheck = false;


  Highcharts = Highcharts;
  chartOptions: {};
  orderCropChartOptions: {};
  chartConstructor = 'chart';
  constructor(
    public acReportServ: AcReportService,
    public route: Router
  ) { }

  ngOnInit(): void {
    this.getData();
    this.dtTrigger.next();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 100,
      processing: true,
      dom: 'Bfrtip',
        // buttons: [
        //     'copy', 'csv', 'excel', 'print'
        // ]
    };
  }

  filterReport() {
    this.start_date = '';
    this.end_date = '';
    this.status = '';

    this.getData();
  }

  getData() {
    this.loading = true;
    var data = {
      'start_date': this.start_date,
      'end_date': this.end_date,
      'status': this.status,
    }

    this.acReportServ.getGeneralOrders(data).subscribe(
      (data: any) => {
        // console.log(data);

        this.orders = data.data;
        this.orderByStatusChart(data.order_status);
        this.mostOrderedCropChart(data.most_ordered);
        this.loading = false;
        this.rerender();
      }
    );
  }
  rerender(): void {
    if (this.dtCheck){
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
      });
    }
    else {
      this.dtCheck = true;
      this.dtTrigger.next();
    }

  }

  orderByStatusChart(data) {
    this.chartOptions = {
      chart: {
        type: 'pie'
      },
      title: {
        text: 'Number of Orders',
        align: 'center'
      },
      tooltip: {
        valueSuffix: ''
      },
      subtitle: {
        text:
          'By statuses',
        align: 'center'
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
            format: '{point.name}: {point.percentage:.1f} %',
            distance: 10,
            style: {
              fontSize: '1.2em',
              textOutline: 'none',
              opacity: 0.7
            }
          },
          showInLegend: true
        }
      }, 
      legend: {
        enabled: true,
        align: 'right',
        verticalAlign: 'bottom',
        layout: 'vertical'
      },
      series: [data]
    };

  }


  mostOrderedCropChart(data) {
    this.orderCropChartOptions = {
      chart: {
        type: 'column',
        // marginLeft: 60, // Adjust margins if necessary
        // marginRight: 60,
        events: {
          render: function () {
            this.reflow();
          }
        },
      },
      title: {
        text: 'Most ordered crops',
        align: 'left'
      },
      subtitle: {
        text:
          'By quantity',
        align: 'left'
      },
      xAxis: {
        categories: data.categories,
        crosshair: true,
        accessibility: {
          description: 'Crops'
        }
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Quantity ordered'
        }
      },
      tooltip: {
        valueSuffix: ' Kg'
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series: [data.series],
      legend: {
        enabled: false
      },
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              layout: 'vertical',
              align: 'bottom',
              verticalAlign: 'right'
            },
            xAxis: {
              labels: {
                // rotation: -45, // Rotate labels for better fit
                align: 'center',
                style: {
                  fontSize: '13px',
                  fontFamily: 'Verdana, sans-serif'
                }
              }
            }
          }
        }]
      }
    };

  }

  goBack() {
    this.route.navigate(['manage-ac/reports']);
  }
}
