<form *ngIf="data.action == 'add'" [formGroup]="warehouseForm" (ngSubmit)="onSubmit()">
    <div class="title-holder">
        <h2 class="title">Add Warehouse</h2>
    </div>
    <div class="row">
        <div class="col-md-6 space1">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Warehouse Name</mat-label>
                <input matInput placeholder="Warehouse Name" formControlName="name" required>
                <mat-error *ngIf="warehouseForm.get('name').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-6 space3">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Warehouse capacity (Kg)</mat-label>
                <input matInput type="number" placeholder="Warehouse capacity" formControlName="capacity" required>
                <mat-error *ngIf="warehouseForm.get('capacity').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="col-md-12 column">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Warehouse Type</mat-label>
            <mat-select placeholder="Select Warehouse type" formControlName="type" required>
                <mat-option value="Regular Warehouse">Regular Warehouse</mat-option>
                <mat-option value="Mill">Mill</mat-option>
                <mat-option value="Store">Store</mat-option>
            </mat-select>
            <mat-error *ngIf="warehouseForm.get('type').invalid">{{getErrorMessage()}}</mat-error>
        </mat-form-field>
    </div>
    <div class="row">
        <div class="col-md-6 space1">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Warehouse Ownership</mat-label>
                <mat-select placeholder="Select Ownership" formControlName="ownership" required (selectionChange)="getOwners($event.value)">
                    <mat-option value="Government">Government</mat-option>
                    <mat-option value="Private">Private</mat-option>
                </mat-select>
                <mat-error *ngIf="warehouseForm.get('ownership').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-6 space3" *ngIf="isPrivate">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Warehouse Owner</mat-label>
                <mat-select placeholder="Select Owner" formControlName="owner">
                    <mat-option *ngFor="let owner of owners" [value]="owner.trader_id">{{owner.full_name}}</mat-option>
                </mat-select>
                <!-- <input matInput type="text" placeholder="Warehouse owner" formControlName="owner"> -->
                <mat-error *ngIf="warehouseForm.get('owner').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 space1">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Warehouse Operator</mat-label>
                <mat-select placeholder="Select Operator" formControlName="operator">
                    <mat-option *ngFor="let operator of operators" [value]="operator.trader_id">{{operator.full_name}}</mat-option>
                </mat-select>
                <!-- <input matInput placeholder="Warehouse Operator" formControlName="operator"> -->
                <mat-error *ngIf="warehouseForm.get('operator').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-6 space3">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Warehouse Registered?</mat-label>
                <mat-select placeholder="Select choice" formControlName="registered">
                    <mat-option value="yes">Yes</mat-option>
                    <mat-option value="no">No</mat-option>
                </mat-select>
                <mat-error *ngIf="warehouseForm.get('registered').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="col-md-12 column">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Licensed By</mat-label>
            <input matInput placeholder="Licensed By" formControlName="licensed_by">
        </mat-form-field>
    </div>
    <div class="col-md-12 column">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Warehouse Grade</mat-label>
            <mat-select placeholder="Select Grade" formControlName="grade">
                <mat-option value="Grade A">Grade A</mat-option>
                <mat-option value="Grade B">Grade B</mat-option>
                <mat-option value="Grade C">Grade C</mat-option>
                <mat-option value="Grade D">Grade D</mat-option>
            </mat-select>
            <mat-error *ngIf="warehouseForm.get('grade').invalid">{{getErrorMessage()}}</mat-error>
        </mat-form-field>
    </div>
    <div class="row column">
        <div class="col-md-4 space1">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Regions</mat-label>
                <mat-select placeholder="Select Region" formControlName="region_id" required (selectionChange)="getDistricts($event.value)">
                    <mat-option *ngFor="let region of regions" [value]="region.id">{{region.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="warehouseForm.get('region_id').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-4 space2">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Districts</mat-label>
                <mat-select placeholder="Select District" formControlName="district_id" required (selectionChange)="getWards($event.value)">
                    <mat-option *ngFor="let district of districts" [value]="district.id">{{district.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="warehouseForm.get('district_id').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-4 space3">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Wards</mat-label>
                <mat-select placeholder="Select Ward" formControlName="ward_id" (selectionChange)="getVillages($event.value)">
                    <mat-option *ngFor="let ward of wards" [value]="ward.id">{{ward.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="warehouseForm.get('ward_id').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row column">
        <div class="col-md-4 space1">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Villages/Streets</mat-label>
                <mat-select placeholder="Select Village" formControlName="village_id">
                    <mat-option *ngFor="let village of villages" [value]="village.id">{{village.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="warehouseForm.get('village_id').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-4 space2">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>latitude</mat-label>
                <input matInput placeholder="latitude" formControlName="latitude">
            </mat-form-field>
        </div>
        <div class="col-md-4 space3">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>longitudes</mat-label>
                <input matInput placeholder="longitudes" formControlName="longitude">
            </mat-form-field>
        </div>
    </div>

    <div style="margin-top: 30px; display: flex; flex-direction: row; justify-content: flex-end;">
        <button (click)="onNoClick()" class="cancelbtn" mat-button>Cancel</button>

        <button type="submit" class="createbtn" mat-button>Submit</button>
    </div>
</form>

<!-- Update Warehouse -->
<form *ngIf="data.action == 'edit'" [formGroup]="editForm" (ngSubmit)="onUpdate()">
    <div class="title-holder">
        <h2 class="title">Add Warehouse</h2>
    </div>
    <div class="row">
        <div class="col-md-6 space1">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Warehouse Name</mat-label>
                <input matInput placeholder="Warehouse Name" formControlName="name" required>
                <mat-error *ngIf="editForm.get('name').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-6 space3">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Warehouse Grade</mat-label>
                <mat-select placeholder="Select Grade" formControlName="grade">
                    <mat-option value="Grade A">Grade A</mat-option>
                    <mat-option value="Grade B">Grade B</mat-option>
                    <mat-option value="Grade C">Grade C</mat-option>
                    <mat-option value="Grade D">Grade D</mat-option>
                </mat-select>
                <mat-error *ngIf="editForm.get('grade').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="col-md-12 column">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Warehouse Type</mat-label>
            <mat-select placeholder="Select Warehouse type" formControlName="type" required>
                <mat-option value="Regular Warehouse">Regular Warehouse</mat-option>
                <mat-option value="Mill">Mill</mat-option>
                <mat-option value="Store">Store</mat-option>
            </mat-select>
            <mat-error *ngIf="editForm.get('type').invalid">{{getErrorMessage()}}</mat-error>
        </mat-form-field>
    </div>
    <div class="col-md-12 column">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Licensed By</mat-label>
            <input matInput placeholder="Licensed By" formControlName="licensed_by">
        </mat-form-field>
    </div>
    <div class="row">
        <div class="col-md-6 space1">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Warehouse capacity (Kg)</mat-label>
                <input matInput type="number" placeholder="Warehouse capacity" formControlName="capacity" required>
                <mat-error *ngIf="editForm.get('capacity').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-6 space3">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Warehouse Ownership</mat-label>
                <mat-select placeholder="Select Ownership" formControlName="ownership" required>
                    <mat-option value="Government">Government</mat-option>
                    <mat-option value="Private">Private</mat-option>
                </mat-select>
                <mat-error *ngIf="editForm.get('ownership').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 space1">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Warehouse Owner</mat-label>
                <mat-select placeholder="Select Owner" formControlName="owner">
                    <mat-option *ngFor="let owner of owners" [value]="owner.id">{{owner.full_name}}</mat-option>
                </mat-select>
                <!-- <input matInput type="text" placeholder="Warehouse owner" formControlName="owner"> -->
                <mat-error *ngIf="editForm.get('owner').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-6 space3">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Warehouse Registered?</mat-label>
                <mat-select placeholder="Select choice" formControlName="registered">
                    <mat-option value="yes">Yes</mat-option>
                    <mat-option value="no">No</mat-option>
                </mat-select>
                <mat-error *ngIf="editForm.get('registered').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="col-md-12 column">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Warehouse Operator</mat-label>
            <mat-select placeholder="Select Operator" formControlName="operator">
                <mat-option *ngFor="let operator of operators" [value]="operator.id">{{operator.full_name}}</mat-option>
            </mat-select>
            <!-- <input matInput placeholder="Warehouse Operator" formControlName="operator"> -->
            <mat-error *ngIf="editForm.get('operator').invalid">{{getErrorMessage()}}</mat-error>
        </mat-form-field>
    </div>
    <div class="row column">
        <div class="col-md-4 space1">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Regions</mat-label>
                <mat-select placeholder="Select Region" formControlName="region_id" required (selectionChange)="getDistricts($event.value)">
                    <mat-option *ngFor="let region of regions" [value]="region.id">{{region.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="editForm.get('region_id').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-4 space2">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Districts</mat-label>
                <mat-select placeholder="Select District" formControlName="district_id" required (selectionChange)="getWards($event.value)">
                    <mat-option *ngFor="let district of districts" [value]="district.id">{{district.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="editForm.get('district_id').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-4 space3">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Wards</mat-label>
                <mat-select placeholder="Select Ward" formControlName="ward_id" (selectionChange)="getVillages($event.value)">
                    <mat-option *ngFor="let ward of wards" [value]="ward.id">{{ward.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="editForm.get('ward_id').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row column">
        <div class="col-md-4 space1">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Villages/Streets</mat-label>
                <mat-select placeholder="Select Village" formControlName="village_id">
                    <mat-option *ngFor="let village of villages" [value]="village.id">{{village.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="editForm.get('village_id').invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-4 space2">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>latitude</mat-label>
                <input matInput placeholder="latitude" formControlName="latitude">
            </mat-form-field>
        </div>
        <div class="col-md-4 space3">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>longitudes</mat-label>
                <input matInput placeholder="longitudes" formControlName="longitude">
            </mat-form-field>
        </div>
    </div>

    <div style="margin-top: 30px; display: flex; flex-direction: row; justify-content: flex-end;">
        <button (click)="onNoClick()" class="cancelbtn" mat-button>Cancel</button>

        <button type="submit" class="createbtn" mat-button>Update</button>
    </div>
</form>
