import { Component, OnInit } from '@angular/core';
import { ManagementService } from './../../management/management.service';
import { DefinitionService } from './../definition.service';
import { Chart } from 'node_modules/chart.js';
import { MatSelectChange } from '@angular/material/select';
import * as Highcharts from 'highcharts/highstock';
import HC_exporting from 'highcharts/modules/export-data';
import { AuthService } from 'src/app/auth/auth.service';
import { Router } from '@angular/router';
HC_exporting(Highcharts);

@Component({
  selector: 'app-warehouse-dashb',
  templateUrl: './warehouse-dashb.component.html',
  styleUrls: ['./warehouse-dashb.component.css']
})
export class WarehouseDashbComponent implements OnInit {
  cropName;
  cropGrades;
  regions;
  districts;
  crops;
  utilization;
  stockTrend;
  stockPerRegions;
  stockGrade;

  years;
  selected = 0;
  cropId;
  pickedRegion = 'Tanzania';
  titledist = "Regions";

  // filter variables
  selectedRegion = null;
  selectedDistrict = null;
  selectedYear = null;


  clearRegion = false;
  clearDistrict = false;
  clearYear = false;

  Highcharts = Highcharts;
  chartOptions: {};
  chartOptionsRegions: {};
  chartOptionsUtilization: {};
  chartOptionsGrade: {};
  chartConstructor = 'chart';
  constructor(
    private manServ: ManagementService,
    private defn: DefinitionService,
    private authService: AuthService,
    private router: Router,
  ) { 
    if(this.authService.isAcManager) {
      this.router.navigate(['manage-ac/summaries'])
    } 
  }

  ngOnInit(): void {
    this.allCrops();
    this.getAllData();
  }

  getAllData() {
    this.allYears();
    this.allRegions();
    this.drawCharts();
  }

  getColor(change) {
    if (change > 0) {
      return '#116B38';
    }
    if (change < 0) {
      return '#C81212';
    }
    if (change === 0) {
      return '#0A3EA6';
    }
  }

  getUpperCase(value: string) {
    return value.toUpperCase();
  }


  onScrollLeft() {
    document.getElementById('list').scrollLeft += 240;
  }

  onScrollRight() {
    document.getElementById('list').scrollLeft -= 240;
  }

  isSScrollable() {
    if (document.getElementById('list') !== null) {
      if (document.getElementById('list').scrollWidth > document.getElementById('list').clientWidth) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  noCrops() {
    if (this.crops.length <= 0) {
      return true;
    } else {
      return false;
    }
  }

  onChangeCrop(cropId, cropName, i) {
    console.log(cropId);
    this.cropId = cropId;
    this.cropName = cropName;
    this.selected = i;
    // this.pickedRegion = 'Tanzania'

    // this.clearRegion = false;
    // this.clearDistrict = false;
    // this.clearYear = false;

    // this.selectedRegion = null;
    // this.selectedDistrict = null;
    // this.selectedYear = null;

    if (this.selectedRegion == null && this.selectedDistrict == null && this.selectedYear == null) {
      this.warehouseCharts(cropId);
    } else {
      this.filter();
    }

  }

  allYears() {
    this.years = this.manServ.getYears();
  }

  allRegions() {
    var id = localStorage.getItem('asdsUserId');
    this.manServ.getRegions(id).subscribe(
      data => {
        this.regions = data;
        // console.log(this.regions)
      }
    );
  }

  getDistricts(regionId) {
    this.titledist = "Districts"
    var id = localStorage.getItem('asdsUserId');
    this.manServ.getDistricts(regionId, id).subscribe(
      data => {
        this.districts = data;
        // console.log(this.districts)
      }
    );
  }

  selectYear(year) {
    this.selectedYear = year;
    this.clearYear = true;
    this.filter();
  }

  selectRegion(event: MatSelectChange) {
    this.selectedRegion = event.value;
    this.pickedRegion = event.source.triggerValue;
    this.clearRegion = true;
    this.getDistricts(event.value);
    this.filter();
  }

  selectDistrict(id) {
    this.selectedDistrict = id;
    this.clearDistrict = true;
    this.filter();
  }

  clearFilter(value) {
    if (value == 'region') {
      this.selectedRegion = null;
      this.clearRegion = false;
      this.titledist = "Regions";
      this.filter();
    }
    if (value == 'district') {
      this.selectedDistrict = null;
      this.clearDistrict = false;
      this.filter();
    }
    if (value == 'year') {
      this.selectedYear = null;
      this.clearYear = false;
      this.filter();
    }
  }


  allCrops() {
    this.manServ.getCrops().subscribe(
      data => {
        this.crops = data;
        this.cropId = this.crops[0].id;
        this.cropName = this.crops[0].name;

        this.getAllData();
      }
    );
  }



  drawCharts() {
    this.warehouseCharts(this.cropId);
  }

  warehouseCharts(crop) {
    this.defn.stockWarehouseCharts(crop).subscribe(
      data => {
        this.stockTrend = data[0];
        this.stockPerRegions = data[1];
        this.utilization = data[2];
        this.stockGrade = data[3];
        this.stockTrendChart();
        this.stockDistributionChart();
        this.stockUtilizationChart();
        this.stockGradeChart();

      }
    );
  }




  // highcharts
  stockTrendChart() {
    this.chartOptions = {
      chart: {
        type: 'column',

      },
      title: {
        text: this.cropName + ' Stock in ' + this.pickedRegion
      },
      subtitle: {
        // text: 'Source: WorldClimate.com'
      },
      xAxis: {
        categories: this.stockTrend[0],
        crosshair: true
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Quantity (Kg)'
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y} MT</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series: this.stockTrend[1]
    };
  }

  stockDistributionChart() {
    this.chartOptionsRegions = {
      chart: {
        type: 'column',

      },
      title: {
        text: this.cropName + ' Stock Distribution in ' + this.pickedRegion
      },
      subtitle: {
        // text: 'Source: WorldClimate.com'
      },
      xAxis: {
        categories: this.stockPerRegions[0],
        crosshair: true
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Quantity (Kg)'
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y} MT</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series: this.stockPerRegions[1]
    };
  }

  stockUtilizationChart() {
    this.chartOptionsUtilization = {
      chart: {
        type: 'column',

      },
      title: {
        text: this.cropName + ' Stock Warehouse Utilization in ' + this.pickedRegion
      },
      subtitle: {
        // text: 'Source: WorldClimate.com'
      },
      xAxis: {
        categories: this.utilization[0],
        crosshair: true
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Quantity (Kg)'
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y} MT</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series: this.utilization[1]
    };
  }

  stockGradeChart() {
    this.chartOptionsGrade = {
      chart: {
        type: 'column',
      },
      title: {
        text: this.cropName + ' Stock Distribution By Grades in ' + this.pickedRegion
      },
      subtitle: {
        // text: 'Source: WorldClimate.com'
      },
      xAxis: {
        categories: this.stockGrade[0],
        crosshair: true
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Quantity (Kg)'
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y} MT</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.3,
          borderWidth: 0
        },
        series: {
          borderWidth: 0,
        },
      },
      series: this.stockGrade[1]
    };
  }





  // drawWarehouseCapacityAndCrops() {
  //   let myChart;
  //   document.getElementById('warehouse_crop_capacity').innerHTML = '';
  //   document.getElementById('warehouse_crop_capacity').innerHTML = '<canvas id="warehouse_crop_capacity_chart" style="height: 100%; width: 100%"></canvas>';
  //   let ctx = document.getElementById('warehouse_crop_capacity_chart');


  //   let cropsData = {
  //     label: 'Stored Crops',
  //     // data: this.warecropcap[1],
  //     backgroundColor: '#EF7726',
  //     borderWidth: 0,
  //     barThickness: 6,
  //   };


  //   let warehouseData = {
  //     label: 'Warehouse Capacity',
  //     // data: this.warecropcap[2],
  //     backgroundColor: '#4A84BA',
  //     borderWidth: 0,
  //     barThickness: 6,
  //   };

  //   let graphData = {
  //     // labels: this.warecropcap[0],
  //     datasets: [cropsData, warehouseData]
  //   };



  //   myChart = new Chart(ctx, {
  //     type: 'horizontalBar',
  //     data: graphData,
  //     options: {

  //       hover: {
  //         animationDuration: 0
  //       },
  //       animation: {
  //         tension: {
  //         duration: 1000,
  //         easing: 'linear',
  //         from: 1,
  //         to: 0,
  //         loop: true
  //       },
  //         onComplete() {
  //           let chartInstance = this.chart,
  //             ctx = chartInstance.ctx;

  //           ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily );
  //           ctx.textAlign = 'center';
  //           ctx.textBaseline = 'bottom';
  //           ctx.fillStyle = '#D6D6D6';

  //           this.data.datasets.forEach(function(dataset, i) {
  //             let meta = chartInstance.controller.getDatasetMeta(i);
  //             meta.data.forEach(function(bar, index) {
  //               let data = numberWithCommas(dataset.data[index]);
  //               ctx.fillText(data, bar._model.x + 18, bar._model.y + 5);
  //             });
  //           });
  //         }
  //       },
  //       legend: {
  //         display: true,
  //         position: 'top',
  //         labels: {
  //           fontColor: '#D6D6D6',
  //           padding: 5,
  //           boxWidth: 10,
  //         }
  //       },
  //       tooltips: {
  //         enabled: false
  //       },
  //       scales: {
  //         yAxes: [{
  //           display: true,
  //           // categoryPercentage: 1.0,
  //           // barPercentage: 1.0,
  //           scaleLabel: {
  //             display: true,
  //           },
  //           gridLines: {
  //             display: false
  //           },
  //           ticks: {
  //             // max: Math.max(...graphData.datasets[0].data) + 10,
  //             display: true,
  //             beginAtZero: true,
  //             fontColor: '#AEAEAE',
  //           }
  //         }],
  //         xAxes: [{
  //           categoryPercentage: 1.0,
  //           barPercentage: 1.0,
  //           gridLines: {
  //             drawBorder: false,
  //             // display: false,
  //             // color: "#000",
  //             color: 'transparent',
  //             zeroLineColor: '#AEAEAE'
  //           },
  //           ticks: {
  //             beginAtZero: true,
  //             display: false,
  //           }
  //         }]
  //       },
  //       responsive: true,
  //       maintainAspectRatio: true,
  //       layout: {
  //         padding: {
  //           left: 0,
  //           right: 40,
  //           top: 0,
  //           bottom: 0
  //         }
  //       }
  //     }
  //   });
  // }


  // filters
  filter() {
    if (this.selectedDistrict == null) {
      // region and year
      if (this.selectedRegion == null && this.selectedYear == null) {
        this.warehouseCharts(this.cropId);
      }
      if (this.selectedRegion != null && this.selectedYear != null) {
        this.defn.getWarehouseStockByRegionAndYear(this.selectedRegion, this.selectedYear, this.cropId).subscribe(
          data => {
            this.updateValues(data);
          }
        );
      }

      // only region
      if (this.selectedRegion != null && this.selectedYear == null) {
        this.defn.getWarehouseStockByRegion(this.selectedRegion, this.cropId).subscribe(
          data => {
            this.updateValues(data);
          }
        );
      }

      // only year
      if (this.selectedRegion == null && this.selectedYear != null) {
        this.defn.getWarehouseStockByYear(this.selectedYear, this.cropId).subscribe(
          data => {
            this.updateValues(data);
          }
        );
      }
    }
  }

  updateValues(data) {
    this.stockTrend = data[0];
    this.stockPerRegions = data[1];
    this.utilization = data[2];
    this.stockGrade = data[3];
    this.stockTrendChart();
    this.stockDistributionChart();
    this.stockUtilizationChart();
    this.stockGradeChart();
    // this.drawStockTrend();
    // this.drawStockPerRegion();
    // this.drawUtilization();
    // this.drawStockGrade();

    // this.drawFilteredGraphs();
  }
}


// function numberWithCommas(x) {
//     const parts = x.toString().split('.');
//     parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
//     return parts.join('.');
// }
