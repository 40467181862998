import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const baseUrl = environment.baseUrl;
const url1 = baseUrl + '/new_in_warehouse/';
const url2 = baseUrl + '/approve_in_warehouse/';
const url3 = baseUrl + '/approved_in_warehouse/';
const url4 = baseUrl + '/filter_in_warehouse/';
const url5 = baseUrl + '/warehouse_owners/';
const url6 = baseUrl + '/warehouse_operators/';
const url7 = baseUrl + '/warehouse-summary';
const url8 = baseUrl + '/warehouse-filter-by-season/';
@Injectable({
  providedIn: 'root'
})
export class WarehousesService {


  headers = new HttpHeaders({
    'Content-Type': 'application/json',
     'X-Requested-Width': 'XMLHttpRequeest',
    'Access-Control-Allow-Origin': '*'
   });
  constructor(private http: HttpClient) { }

  getNewInWarehouses() {
    var id = localStorage.getItem('asdsUserId')
    return this.http.get(url1 + id, {headers: this.headers});
  }

  approveNewInWarehouses(id) {
    return this.http.get(url2 + id, {headers: this.headers});
  }


  getApprovedInWarehouses() {
    var id = localStorage.getItem('asdsUserId')
    return this.http.get(url3 + id, {headers: this.headers});
  }


  filterInWarehouses(data) {
    var id = localStorage.getItem('asdsUserId')
    return this.http.post(url4 + id, data, {headers: this.headers});
  }

  getWarehouseOwners(id) {
    return this.http.get(url5 + id, {headers: this.headers});
  }

  getWarehouseOperators(id) {
    return this.http.get(url6 + id, {headers: this.headers});
  }

  getData(data) {
    return this.http.post(url7, data, {headers: this.headers});
  }

  filterWarehousesBySeason(data) {
    var id = localStorage.getItem('asdsUserId')
    return this.http.post(url8 + id, data, {headers: this.headers});
  }
}
