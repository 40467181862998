import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OrderService } from '../order.service';

export interface DialogData {
  id;
}

@Component({
  selector: 'app-cancel-modal',
  templateUrl: './cancel-modal.component.html',
  styleUrls: ['./cancel-modal.component.css']
})
export class CancelModalComponent implements OnInit {

  isLoading = false;

  constructor(
    public dialogRef: MatDialogRef<CancelModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private orderServ: OrderService,
    private snackBar: MatSnackBar
  ) { }
  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    this.isLoading = true;
      this.orderServ.cancelOrder(this.data.id).subscribe(
        (resp: string) => {
          this.snackBar.open('message', resp, {
            duration: 5000
          });
          this.onNoClick();
        }
      );

  }

}
