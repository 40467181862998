<app-header *ngIf="showHeader"></app-header>
<div class="main-holder mt-xl-2">
    <div class="sm:px-4 md:px-36">
        <div class="flex justify-between items-center mt-10">
            <div class="sm: text-xl md:text-4xl font-bold text-sky-900 notranslate">Crop Stock Dynamics</div>
            <div class="w-28 hidden">
                <img src="assets/copra_logo.png" class="">
            </div>
        </div>
        <div class="py-3 md:grid md:grid-cols-3 bg-gray-50 rounded-lg px-2">
            <div class="border border-sky-900">
                <div class="bg-sky-900 text-lg px-2 py-2.5 text-gray-100 rounded-sm">{{ 'about-title' | translate }}</div>
                <div class="px-2 py-4">
                    {{ 'about' | translate }}
                </div>
            </div>
            <div class="border border-sky-900">
                <div class="bg-sky-900 text-lg px-2 py-2.5 text-gray-100 rounded-sm">{{ 'new-reg-title' | translate }}</div>
                <div class="px-2 py-4">
                    {{ 'new-reg-info' | translate }}

                    <a href="/register"
                    class="block text-center bg-gray-300 hover:no-underline hover:bg-gray-300/80 hover:text-gray-700 py-2.5 px-2.5 mt-3 shadow-md regist er-btn"
                    mat-butto n>{{ 'new-reg-btn' | translate }}</a>
                </div>
            </div>
            <div class="border border-sky-900">
                <div class="bg-sky-900 text-lg px-2 py-2.5 text-gray-100 rounded-sm">{{ 'login-title' | translate }}</div>
                <div class="px-2 py-4">
                    <form [formGroup]="authForm" (ngSubmit)="onSubmit()" class="font">
                        <mat-form-field class="add-form-field" appearance="outline">
                            <mat-label>{{ 'username' | translate }}</mat-label>
                            <input matInput placeholder="{{ 'username' | translate }}" formControlName="username" required>
                            <mat-error *ngIf="authForm.get('username').invalid">{{getErrorMessage()}}</mat-error>
                        </mat-form-field>

                        <mat-form-field class="add-form-field" appearance="outline">
                            <mat-label>{{ 'password' | translate }}</mat-label>
                            <input matInput placeholder="{{ 'password' | translate }}" type="password" formControlName="password" required>
                            <mat-error *ngIf="authForm.get('password').invalid">{{getErrorMessage()}}</mat-error>
                        </mat-form-field>

                        <button class="button login-btn" mat-button>{{ 'login' | translate }}</button>
                        <div class="flex justify-end">
                            <!-- <div class="">Are you a buyer and have no account?</div> -->
                            <a href="/forgot-password" class="text-green-700">{{ 'forgot-password' | translate }}</a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="row form mat-elevation-z0 col-md-6 mt-5">
        <div class="col-md-6">
            <img src="assets/login.jpg" class="login">
        </div>
        <div class="col-md-6">
            <div class="form-header pb-3">
                <img src="assets/tz_logo.png" class="logo">
                <p class="title pt-3">Crop Stock Dynamics</p>
            </div>
            <form [formGroup]="authForm" (ngSubmit)="onSubmit()" class="font">
                <mat-form-field class="add-form-field" appearance="outline">
                    <mat-label>Username</mat-label>
                    <input matInput placeholder="Username" formControlName="username" required>
                    <mat-error *ngIf="authForm.get('username').invalid">{{getErrorMessage()}}</mat-error>
                </mat-form-field>

                <mat-form-field class="add-form-field" appearance="outline">
                    <mat-label>Password</mat-label>
                    <input matInput placeholder="Password" type="password" formControlName="password" required>
                    <mat-error *ngIf="authForm.get('password').invalid">{{getErrorMessage()}}</mat-error>
                </mat-form-field>

                <button class="button login-btn" mat-button>Login</button>
                <div class="flex justify-end">
                    <a href="/register" class="text-green-700">Forgot password</a>
                </div>
                <a href="/register"
                    class="block text-center bg-gray-300 hover:no-underline py-2 px-2.5 shadow-md regist er-btn"
                    mat-butto n>Buyer Registration</a>
            </form>
        </div>
    </div> -->

    <p class="pt-2 notranslate" style="color: grey">{{ currentYear }}&copy;crop-stock dynamics</p>
</div>