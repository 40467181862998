<div class="relative">
    <div id="content-header" class="absolute -top-24">
        <h1 class="text-uppercase font-bold">
            {{ action != 'update' ? 'Add Order' : 'Update Order' }}
        </h1>
    </div>
    <div class="absolute -top-28 right-2 cursor-pointer text-white bg-red-500 hover:bg-red-600 rounded-lg h-6 w-6 items-center text-center"
        title="Close" (click)="goBack()">x</div>
    <div class="flex flex-col-reverse md:flex-row gap-4 w-full min-h-64">
        <div class="px-4 bg-gray-50 rounded-md p-2 relative w-full">

            <!-- <div class="grid grid-cols-1 md:grid-cols-5 gap-3 px-1 py-2 mb-3 border-b-8 border-sky-100/50 hidden">
                <div class="md:col-span-2">
                    <input type="text" id="name" [(ngModel)]="fullName" placeholder="Buyer name"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                </div>
                <div class="md:col-span-2">
                    <input type="text" id="phone" [(ngModel)]="phone" placeholder="Buyer phone number"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                </div>
                <button (click)="searchBuyers()"
                    class="bg-green-700 px-3 py-1 text-white rounded-md flex space-x-2 items-center justify-center">
                    <i class="fa fa-spinner fa-spin" *ngIf="searching"></i>
                    <span>Search</span>
                </button>
            </div> -->

            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-2">
                    <div class="col-span-2">
                        <label for="aggregation_center_id">Aggregation Center <span
                                class="text-red-500">*</span></label>
                        <select id="aggregation_center_id" formControlName="aggregation_center_id"
                            class="custom-select bg-gray-50">
                            <option value="" disabled>Select..</option>
                            <option *ngFor="let center of centers" [value]="center.id">{{ center.name }}</option>
                        </select>
                        <div class="text-red-500"
                            *ngIf="form.get('aggregation_center_id').invalid && form.get('aggregation_center_id').touched">
                            Aggregation center is
                            required.
                        </div>
                    </div>
                    <div class="mb-2">
                        <label for="trader_id">Buyer Name<span class="text-red-500">*</span></label>
                        <app-custom-select [options]="buyers" [(value)]="selectedTraderId" labelField="full_name" valueField="id"
                            placeholder="Select...">
                        </app-custom-select>

                        <!-- <select id="trader_id" formControlName="trader_id" class="custom-select bg-gray-50">
                            <option value="" disabled>Select a buyer</option>
                            <option *ngFor="let buyer of buyers" [value]="buyer.id">{{ buyer.full_name }}</option>
                        </select> -->
                        <div class="text-red-500"
                            *ngIf="form.get('trader_id').invalid && form.get('trader_id').touched">Buyer
                            is required.</div>
                    </div>
                    <!-- <div class="mb-2">
                        <label for="buyer_phone">Buyer Phone<span class="text-red-500">*</span></label>
                        <input type="tel" min="1" id="buyer_phone" formControlName="buyer_phone" placeholder="255123456789"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                        <div class="text-red-500" *ngIf="form.get('buyer_phone').invalid && form.get('buyer_phone').touched">
                            Phone is required.</div>
                    </div> -->
                    <!-- <div class="hidden">
                        <label for="farmer_id">Farmer</label>
                        <select id="farmer_id" formControlName="farmer_id" class="custom-select bg-gray-50">
                            <option value="" disabled>Select a farmer</option>
                            <option *ngFor="let farmer of farmers" [value]="farmer.id">{{ farmer.full_name }}</option>
                        </select>
                        <div class="text-red" *ngIf="form.get('farmer_id').invalid && form.get('farmer_id').touched">Farmer is required.
                        </div>
                    </div> -->
                    <div>
                        <label for="order_date">Order Date <span class="text-red-500">*</span></label>
                        <input type="date" id="order_date" formControlName="order_date"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                        <div class="text-red-500"
                            *ngIf="form.get('order_date').invalid && form.get('order_date').touched">
                            Date is required.
                        </div>
                    </div>
                    <!-- <div>
                        <label for="crop_id">Produce Type <span class="text-red-500">*</span></label>
                        <select id="crop_id" formControlName="crop_id" class="custom-select bg-gray-50">
                            <option value="" disabled>Select a type</option>
                            <option *ngFor="let crop of crops" [value]="crop.id">{{ crop.name }}</option>
                        </select>
                        <div class="text-red-500" *ngIf="form.get('crop_id').invalid && form.get('crop_id').touched">Produce type is
                            required.
                        </div>
                    </div>

                    <div>
                        <label for="amount">Amount (Kg)<span class="text-red-500">*</span></label>
                        <input type="number" min="1" id="amount" formControlName="amount"
                            placeholder="Enter center amount"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                        <div class="text-red-500" *ngIf="form.get('amount').invalid && form.get('amount').touched">Amount is required.</div>
                    </div>
                    <div>
                        <label for="value">Value (TSh.) <span class="text-red-500">*</span></label>
                        <input type="number" min="1" id="value" formControlName="value" placeholder="Enter value"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                        <div class="text-red-500" *ngIf="form.get('value').invalid && form.get('value').touched">Value is required.</div>
                    </div> -->

                </div>
                <div class="hidden" *ngIf="action == 'update'">
                    <label for="status">Order Status<span class="text-red-500">*</span></label>
                    <select id="status" formControlName="status" class="custom-select bg-gray-50">
                        <option *ngFor="let status of statuses" [value]="status">{{ status }}</option>
                    </select>
                </div>

                <div class="my-2 text-md font-bold">Order Items <span class="text-red-500">*</span></div>
                <div formArrayName="items">
                    <div *ngFor="let item of items.controls; let i = index" [formGroupName]="i"
                        class="flex items-center space-x-2 my-0.5">
                        <label for="item-{{i}}">{{i + 1}}:</label>
                        <div class="flex space-x-2 mb-3">
                            <div class="grid grid-cols-1 md:grid-cols-3 gap-2">
                                <select formControlName="crop_id" class="custom-select bg-gray-50">
                                    <option value="" disabled>Select crop</option>
                                    <option *ngFor="let crop of crops" [value]="crop.id">{{ crop.name }}</option>
                                </select>
                                <input formControlName="quantity" type="number" step="0.01" min="10"
                                    placeholder="Quantity (Kg)"
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                <input formControlName="price" type="number" step="0.01" min="10"
                                    placeholder="Total Price (TSh.)"
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                            </div>
                            <a type="button" (click)="removeItem(i)" *ngIf="i > 0"
                                class="px-2 py-1 bg-gray-100 rounded-md text-gray-700 hover:text-red-500">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="1.5" stroke="currentColor" class="size-6">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                </svg>
                            </a>
                        </div>
                    </div>
                    <a type="button" (click)="addItem()"
                        class="my-2 hover:no-underline hover:text-green-900 text-green-700 bg-green-100/50 font-bold px-2 py-0.5">
                        <div class="flex space-x-1 items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="size-4">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                            </svg>
                            <span>Add Item</span>
                        </div>
                    </a>
                </div>
                <button type="submit" [disabled]="form.invalid"
                    class="px-2.5 py-1.5 rounded-md flex space-x-2 items-center">
                    <i class="fa fa-spinner fa-spin" *ngIf="submitting"></i>
                    <span *ngIf="action !== 'update'">Submit</span>
                    <span *ngIf="action === 'update'">Update</span>
                </button>
            </form>

            <div class="absolute top-1/2 right-1/2 z-50" *ngIf="loading">
                <div class="w-20 h-20 bg-black/50 flex justify-center items-center rounded-md text-gray-200">
                    <i class="fa fa-spinner fa-spin text-4xl"></i>
                </div>
            </div>
        </div>
        <div class="w-full">
            <div class="hidden">
                <!-- <app-farmer-modal [farmers]="buyers" [isVisible]="showModal"
                    (eventEmitter)="handleEvent($event)"></app-farmer-modal> -->
            </div>
        </div>
    </div>