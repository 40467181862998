import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const baseUrl = environment.baseUrl;
const url1 = baseUrl + '/aggregation-summary';
const url2 = baseUrl + '/general-order-report';
const url3 = baseUrl + '/buyer-orders';
const url4 = baseUrl + '/produces-summary';

@Injectable({
  providedIn: 'root'
})
export class AcReportService {

  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'X-Requested-Width': 'XMLHttpRequeest',
    'Access-Control-Allow-Origin': '*',
    'Authorization': `Bearer ${localStorage.getItem('api_token')}`
  });

  fileheaders = new HttpHeaders({
    // 'Content-Type': 'multipart/form-data',
    'Accept': 'application/json',
    //  'X-Requested-Width': 'XMLHttpRequeest',
    'Access-Control-Allow-Origin': '*',
    'Authorization': `Bearer ${localStorage.getItem('api_token')}`
  });
  constructor(private http: HttpClient) { }

  gatSummary(data) {
    return this.http.post(url1, data, { headers: this.headers });
  }

  getGeneralOrders(data) {
    return this.http.post(url2, data, { headers: this.headers });
  }

  getBuyerOrders() {
    return this.http.get(url3, { headers: this.headers });
  }

  getProduceSummaryReport() {
    return this.http.get(url4, { headers: this.headers });
  }
  
}
